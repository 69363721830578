import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
})
export class PrimaryButtonComponent {
  @Input() public type?: string;
  @Input() public icon?: string = '';
  @Input() public iconImg?: string = '';
  @Input() public label?: string = '';
  @Input() public alt?: string = '';
  @Input() public block?: boolean = false;
  @Input() public loading?: boolean = false;
  @Input() public disabled?: boolean = false;
  @Input() public iconAfterText?: boolean = false;

  constructor() {}
}
