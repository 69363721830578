import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

import { MAIN_SIDENAV } from '../../../utils/side-nav';
import { SidenMenuModel } from '../../../models/side-menu/side-menu.model';

@Component({
  selector: 'app-drawer-side-menu',
  templateUrl: './drawer-side-menu.component.html',
  styleUrls: ['./drawer-side-menu.component.scss'],
})
export class DrawerSideMenuComponent {
  public sideMenu: SidenMenuModel[] = MAIN_SIDENAV;

  constructor(private readonly $drawer: NzDrawerRef, private router: Router) {}

  public onClickMenu(route: string): void {
    this.router.navigate([route]);
    this.$drawer.close();
  }
}
