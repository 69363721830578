import moment from 'moment-timezone';
import { Pipe, PipeTransform } from '@angular/core';

import { getTimeZone } from '../utils/time';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
  transform(value: any, ...args: string[]): string {
    if (value) {
      const format = args[0] || 'DD/MM/YYYY [às] HH:mm';

      if (args[1] === 'WITHOUT_TIMEZONE') {
        return moment(value).locale('pt-br').format(format);
      }

      const timezone = getTimeZone();

      return moment(value).tz(timezone).locale('pt-br').format(format);
    }

    return '';
  }
}
