import { gql } from 'apollo-angular';

const LIST_CONTACTS_QUERY = gql`
  query ListContacts($sellerId: String, $fullName: String, $businessName: String, $isLegalEntity: ContactType, $email: String, $cpf: String, $startDate: DateTime) {
    contacts(sellerId: $sellerId, fullName: $fullName, businessName: $businessName, isLegalEntity: $isLegalEntity, email: $email, cpf: $cpf, startDate: $startDate) {
      id
      fullName
      cpf
      cnpj
      isLegalEntity
      created_at
      businessName
      phoneNumber
      address {
        state
        street
        number
        neighborhood
        city
        country
      }
      seller {
        id
        business_name
      }
      status
    }
  }
`;

const LIST_CONTACT_BY_ID_QUERY = gql`
  query ListContactById($id: String!) {
    contact(id: $id) {
      id
      fullName
      created_at
      cpf
      cnpj
      phoneNumber
      email
      businessName
      customerId
      address {
        state
        street
        number
        neighborhood
        city
        country
        zipCode
        complement
      }
      seller {
        id
        business_name
      }
    }
  }
`;

const LIST_CONTACT_CUSTOMER_QUERY = gql`
  query contactCustomer($sellerId: String!, $customerId: String!) {
    contactCustomer(sellerId: $sellerId, customerId: $customerId) {
      fullName
      cpf
      id
      cnpj
      customer {
        name
      }
    }
  }
`;

const LIST_CONTACTS_BY_CPF_QUERY = gql`
  query ListContacts($sellerId: String, $isLegalEntity: ContactType, $cpf: String) {
    contacts(sellerId: $sellerId, isLegalEntity: $isLegalEntity, cpf: $cpf) {
      id
      fullName
      cpf
      cnpj
      isLegalEntity
      created_at
      businessName
      phoneNumber
      address {
        state
        street
        number
        neighborhood
        city
        country
      }
      seller {
        id
        business_name
      }
      status
    }
  }
`;

export { LIST_CONTACTS_QUERY, LIST_CONTACT_BY_ID_QUERY, LIST_CONTACT_CUSTOMER_QUERY };
