import { gql } from "apollo-angular";

const CAMPAIGNS_QUERY = gql`
  query Campaigns($sellerId: String!, $status: CampaignsStatus, $startDate: DateTime, $endDate: DateTime) {
    campaigns(sellerId: $sellerId, status: $status, startDate: $startDate, endDate: $endDate) {
      id
      created_at
      updated_at
      seller_id
      seller {
        id
        cnpj
        business_name

      }
      has_email
      has_sms
      sms {
        id
        body_message
        campaign_id
      }
      email {
        id
        campaign_id
        subject_email
        title_message
        body_message
        template_sendgrid_id
        link_action
        action_name
      }
      segmentations {
        id
        name
        description
        contacts {
          id
          fullName
          created_at
          updated_at
          cpf
          email
          phoneNumber
          isLegalEntity
          status
        }
      }
      send_date
      send_messages
      start_date
      end_date
      status
    }
  }
`;

const CAMPAIGN_QUERY = gql`
  query Campaign($id: String!) {
    campaign(id: $id) {
      id
      created_at
      updated_at
      seller_id
      seller {
        id
        cnpj
        business_name

      }
      has_email
      has_sms
      sms {
        id
        body_message
        campaign_id
      }
      email {
        id
        campaign_id
        subject_email
        title_message
        body_message
        template_sendgrid_id
        link_action
        action_name
      }
      segmentations {
        id
        name
        description
        contacts {
          id
          fullName
          created_at
          updated_at
          cpf
          email
          phoneNumber
          isLegalEntity
          status
        }
      }
      send_date
      send_messages
      start_date
      end_date
      status
    }
  }
`;

 export { CAMPAIGNS_QUERY, CAMPAIGN_QUERY };
