import { Subscription } from 'rxjs';
import { Component } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadChangeParam, NzUploadXHRArgs } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-modal-upload-chargeback',
  templateUrl: './modal-upload-chargeback.component.html',
  styleUrls: ['./modal-upload-chargeback.component.scss'],
})
export class ModalUploadChargebackComponent {
  public previewImage: string | undefined = '';
  public previewVisible: boolean = false;
  public file: File | undefined;

  constructor(private modalRef: NzModalRef) {}

  public closed(value: any): void {
    if (value) {
      this.modalRef.destroy(this.file);
    } else {
      this.modalRef.destroy();
    }
  }

  private getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => {
        resolve(reader.result as string);
      };
      reader.onerror = (error): void => {
        reject(error);
      };
    });
  }

  public async handleChange(event: NzUploadChangeParam): Promise<void> {
    if (event.type === 'start') {
      if (event.type === 'start') {
        event.file.status = 'success';
        this.file = event.file.originFileObj;

        const content = await this.getBase64(this.file);

        this.file['preview'] = content;
      }
    }
  }

  public customRequest(file: NzUploadXHRArgs): Subscription {
    return new Subscription();
  }
}
