import { WalletModel } from './wallet.model';

export class WithdrawlsModel {
  public amountCents: number;
  public id: string;
  public insertedAt: string;
  public status: string;
  public updatedAt: string;
  public wallet: WalletModel;
  public walletTransaction: walletTransaction;
}

export type walletTransaction = {
  id: string;
  status: string;
};
