import { gql } from 'apollo-angular';

const DECLINE_WITHDRAW_MUTATION = gql`
  mutation DeclineWithdraw($id: UUID4!, $statusReason: String) {
    declineWithdraw(id: $id, statusReason: $statusReason) {
      id
    }
  }
`;

const APPROVE_WITHDRAW_MUTATION = gql`
  mutation ApproveWithdraw($id: UUID4!, $statusReason: String) {
    approveWithdraw(id: $id, statusReason: $statusReason) {
      id
    }
  }
`;

const CREATE_WITHDRAW_MUTATION = gql`
  mutation createWithdraw($amountCents: Int!, $sellerId: UUID4!) {
    createWithdraw(amountCents: $amountCents, sellerId: $sellerId) {
      id
    }
  }
`;

export { DECLINE_WITHDRAW_MUTATION, APPROVE_WITHDRAW_MUTATION, CREATE_WITHDRAW_MUTATION };
