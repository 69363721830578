import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-confirmation-alter-order',
  templateUrl: './modal-confirmation-alter-order.component.html',
  styleUrls: ['./modal-confirmation-alter-order.component.scss'],
})
export class ModalConfirmationAlterOrderComponent {
  @Input() public newStatus: { title: string; date: Date } = {
    title: 'Pedido disponível para retirada',
    date: new Date(),
  };
  @Input() public lastStatus: { title: string; date: Date } = {
    title: 'Pedido em preparação',
    date: new Date(),
  };

  constructor(public modalRef: NzModalRef) {}
}
