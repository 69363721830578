import { NzModalService } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

import { ErrorService } from 'libs/shared/src/lib/services/error/error.service';
import { WithdrawlsModel } from 'libs/shared/src/lib/models/withdrawls/withdrawl.model';
import { WithdrawlsService } from 'libs/shared/src/lib/services/withdrawals/withdrawls.service';
import { getSmallModalWidth } from 'libs/shared/src/lib/utils/utils';
import { ModalWithdrawComponent } from '../../../modals/modal-withdraw/modal-withdraw.component';

@Component({
  selector: 'app-drawer-withdraw-details',
  templateUrl: './drawer-withdraw-details.component.html',
  styleUrls: ['./drawer-withdraw-details.component.scss'],
})
export class DrawerWithdrawDetailsComponent implements OnInit {
  @Input() value: string;

  public withdrawal: WithdrawlsModel = new WithdrawlsModel();
  public loading: boolean = true;

  constructor(
    private $error: ErrorService,
    public $withdrawal: WithdrawlsService,
    private readonly $modal: NzModalService,
    private readonly $message: NzMessageService
  ) {}

  public ngOnInit(): void {
    this.getWithdrawl();
  }

  public getWithdrawl(): void {
    this.$withdrawal.getWithdrawById(this.value).subscribe({
      next: (res) => {
        if (res?.data?.getWithdraw) {
          this.withdrawal = JSON.parse(JSON.stringify(res.data.getWithdraw));
        }

        setTimeout(() => {
          this.loading = false;
        }, 1500);
      },
      error: (error) => {
        this.loading = false;
        this.$error.errorHandling(error, 'Erro ao buscar o saque.');
      },
    });
  }

  public openConfirmModal(isApprove: boolean): void {
    this.$modal
      .create({
        nzContent: ModalWithdrawComponent,
        nzWidth: getSmallModalWidth(),
        nzClosable: true,
        nzTitle: isApprove ? 'Aprovar saque' : 'Rejeitar saque',
        nzFooter: null,
        nzComponentParams: {
          withdrawal: this.withdrawal,
          textBtn: isApprove ? 'Aprovar' : 'Rejeitar',
        },
      })
      .afterClose.subscribe((res) => {
        if (res) {
          if (isApprove) {
            this.approveWithdraw(res.statusReason);
          } else {
            this.declineWithdrawl(res.statusReason);
          }
        }
      });
  }

  private approveWithdraw(statusReason: string): void {
    this.loading = true;

    this.$withdrawal.approveWithdrawl(this.value, statusReason).subscribe({
      next: (res) => {
        if (res?.data?.approveWithdraw) {
          this.$message.success('Saque aprovado com sucesso!');
          this.getWithdrawl();
        } else {
          this.loading = false;
        }
      },
      error: (error) => {
        this.loading = false;
        this.$error.errorHandling(error, 'Erro ao aprovar o saque.');
      },
    });
  }

  private declineWithdrawl(statusReason: string): void {
    this.loading = true;

    this.$withdrawal.declineWithdrawl(this.value, statusReason).subscribe({
      next: (res) => {
        if (res?.data?.declineWithdraw) {
          this.$message.success('Saque reprovado com sucesso!');
          this.getWithdrawl();
        } else {
          this.loading = false;
        }
      },
      error: (error) => {
        this.loading = false;
        this.$error.errorHandling(error, 'Erro ao reprovar o saque.');
      },
    });
  }
}
