import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { ResponseModel } from '../../models/response/response.model';
import { Apollo, Query } from 'apollo-angular';
import { CAMPAIGNS_QUERY, CAMPAIGN_QUERY } from '../../graphql/queries/campaigns.queries';
import { CREATE_CAMPAIGNS_MUTATION, UPDATE_CAMPAIGNS_MUTATION } from '../../graphql/mutations/campaign.mutation';

@Injectable()
export class CampaignService {
  constructor(
    private $functions: AngularFireFunctions,
    private readonly $apollo: Apollo
  ) {}

  public getCampaignFilter(data: any): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('getMallCampaign')(data);
  }

  public campaigns(sellerId: string, status?: string, startDate?: Date, endDate?: Date): Observable<any> {
    return this.$apollo.watchQuery<Query>({
      query: CAMPAIGNS_QUERY,
      variables: { sellerId, status, startDate, endDate },
      fetchPolicy: 'network-only',
    }).valueChanges
  }

  public campaign(id: string): Observable<any> {
    return this.$apollo.watchQuery<Query>({
      query: CAMPAIGN_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    }).valueChanges
  }

  public createCampaign(
    sellerId: string,
    startDate: string,
    sendDate: string,
    hasSms: boolean,
    hasEmail: boolean,
    segmentationIds: any[],
    smsBodyMessage: string,
    emailBodyMessage: string,
    emailTitle: string,
    emailSubject: string,
    link: string,
    templateId: string,
    actionName: string
  ): Observable<any> {
    return this.$apollo.mutate({
      mutation: CREATE_CAMPAIGNS_MUTATION,
      variables: {
        sellerId,
        startDate,
        sendDate,
        hasSms,
        hasEmail,
        segmentationIds,
        smsBodyMessage,
        emailBodyMessage,
        emailTitle,
        emailSubject,
        link,
        templateId,
        actionName
      },
    });
  }

  public updateCampaign(
    id: string,
    sellerId: string,
    startDate: string,
    sendDate: string,
    hasSms: boolean,
    hasEmail: boolean,
    segmentationIds: any[],
    smsBodyMessage: string,
    emailBodyMessage: string,
    emailTitle: string,
    emailSubject: string,
    link: string,
    templateId: string,
    actionName: string
  ): Observable<any> {
    return this.$apollo.mutate({
      mutation: UPDATE_CAMPAIGNS_MUTATION,
      variables: {
        id,
        sellerId,
        startDate,
        sendDate,
        hasSms,
        hasEmail,
        segmentationIds,
        smsBodyMessage,
        emailBodyMessage,
        emailTitle,
        emailSubject,
        link,
        templateId,
        actionName
      },
    });
  }
}
