import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { IEvent } from '../../models/events/events.model';

@Injectable()
export class EventsService {
  constructor(private firestore: AngularFirestore) {}

  public getAllEvents(): Observable<any> {
    return this.firestore.collection('events').valueChanges();
  }

  public getEventsById(eventsId: string): Observable<IEvent> {
    return this.firestore.doc(`events/${eventsId}`).valueChanges();
  }

  public createEvent(event: IEvent): Promise<void> {
    return this.firestore.doc(`events/${event.id}`).set(event);
  }

  public updateEvent(id: string, event: IEvent): Promise<void> {
    return this.firestore.doc(`events/${event.id}`).update(event);
  }
}
