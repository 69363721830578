import { MallModel } from '../mall/mall.model';
import { SellerModel } from '../sellers/sellers.model';
import { CouponTypeFidelityModel } from './coupon-type-fidelity.model';
import { CouponCategoryFidelityModel } from './coupon-category-fidelity.model';
import { CouponRedeemedFidelityModel } from './coupon-redeemed-fidelity.model';
import { CouponAvaliationsFidelityModel } from './coupon-avaliations-fidelity.model';

export class CouponFidelityModel {
  public id: string = '';
  public code: string = '';
  public name: string = '';
  public description: string = '';
  public start_date: string = '';
  public expiration_date: string = '';
  public value: number = null;
  public created_at: string = '';
  public updated_at: string = '';
  public user_usage_limit: number = null;
  public viewCoupon?: number = null;
  public typeDiscount: string = 'REAL_VALUE';
  public status: string = 'NEW';
  public amount?: number = null;
  public coupon_image?: string = '';
  public buy_value_min?: number = null;
  public buy_value_max?: number = null;
  public mall?: MallModel = new MallModel();
  public app_view?: boolean = false;
  public appView?: boolean = false;
  public coupon_type?: CouponTypeFidelityModel = new CouponTypeFidelityModel();
  public categories?: Array<CouponCategoryFidelityModel> = [];
  public redeemed_coupons?: Array<CouponRedeemedFidelityModel> = [];
  public seller?: Partial<SellerModel> = new SellerModel();
  public sellerId?: string = '';
  public coupons?: Array<any> = [];
  public coupon?: any = {};
  public categoryId?: string = '';
  public couponTypeId?: string = '';
  public userGen?: string = '';
  public mallId?: string | number;
  public isFavorite?: boolean = false;
  public loading?: boolean = false;
  public totalAvaliations?: number = 0;
  public couponRating?: number = 0;
  public coupon_avaliations?: Array<CouponAvaliationsFidelityModel> = [];
}
