import { gql } from 'apollo-angular';

const LIST_SELLERS_QUERY = gql`
  query ListSellers($filter: FilterSellersParams) {
    listSellers(filters: $filter, pagination: { sortingOrder: DESC, orderBy: "inserted_at", perPage: 100 }) {
      id
      cpf
      cnpj
      status
      displayName
      insertedAt
      company {
        businessName
      }
    }
  }
`;

const GET_SELLER = gql`
  query getSeller($id: UUID4!) {
    getSeller(id: $id) {
      company {
        businessName
        tradeName
        cnpj
        address {
          postalCode
          line1
          line2
          line3
          neighborhood
          state
          city
        }
      }
      id
      cpf
      cnpj
      phone
      status
      insertedAt
      occupation
      email
      isBnplActive
      bnplRequestedAt
      emailVerified
      phoneVerified
      isStore
      receivingLimit
      observation
      person {
        name
        birthDate
        nameMother
        address {
          postalCode
          line1
          line2
          line3
          neighborhood
          state
          city
        }
      }
      limits {
        action {
          name
        }
        functionality {
          name
        }
        category {
          name
        }
        perPeriod {
          name
        }
        amount
      }
      externalConsults {
        id
        cpfCnpj
        datasets
      }
      bankAccounts {
        bankNumber
        agencyNumber
        agencyCheckNumber
        type
        accountNumber
        accountCheckNumber
      }
      documents {
        id
        url
        status
        type
        insertedAt
        name
      }
      versions {
        event
        insertedAt
      }
    }
  }
`;

const GET_SELLER_DOCUMENTS = gql`
  query getSeller($id: UUID4!) {
    getSeller(id: $id) {
      documents {
        status
        type
        insertedAt
        updatedAt
      }
    }
  }
`;

const GET_SELLER_BUSINESS = gql`
  query getSeller($id: UUID4!) {
    getSeller(id: $id) {
      company {
        businessName
        tradeName
        cnpj
        address {
          postalCode
          line1
          line2
          line3
          neighborhood
          state
          city
        }
      }
      id
      cpf
      cnpj
      phone
      status
      insertedAt
      occupation
      isBnplActive
      email
      isStore
      receivingLimit
      externalId
      observation
      platformTermAccepted
      person {
        name
        birthDate
        nameMother
        address {
          postalCode
          line1
          line2
          line3
          neighborhood
          state
          city
        }
      }
      celcoinAccount {
        accountNumber
      }
      bankAccounts {
        bankNumber
        agencyNumber
        agencyCheckNumber
        type
        accountNumber
        accountCheckNumber
      }
      marketplace {
        id
      }
      store {
        displayName
      }
    }
  }
`;

const GET_LAST_PLATFORM_TERM_QUERY = gql`
  {
    getLatestPlatformTerm(platform: SAAS) {
      id
      fileUrl
    }
  }
`;

const LIST_PLATFORM_TERMS_QUERY = gql`
  query ListPlatformTerms($filters: FilterPlatformTermsParams) {
    listPlatformTerms(filters: $filters, pagination: { sortingOrder: DESC, orderBy: "inserted_at", perPage: 100 }) {
      id
      version
      platform
      fileUrl
    }
  }
`;

const GET_EXTERNAL_CONSULT_DETAILS_QUERY = gql`
  query getExternalConsultDetails($id: UUID4!) {
    getExternalConsultDetails(id: $id)
  }
`;

export {
  GET_SELLER,
  LIST_SELLERS_QUERY,
  GET_SELLER_BUSINESS,
  GET_SELLER_DOCUMENTS,
  LIST_PLATFORM_TERMS_QUERY,
  GET_LAST_PLATFORM_TERM_QUERY,
  GET_EXTERNAL_CONSULT_DETAILS_QUERY,
};
