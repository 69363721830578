import mixpanel from 'mixpanel-browser';
import { Injectable } from '@angular/core';

import { environment } from '@gen/environments';

@Injectable()
export class MixpanelService {
  constructor() {}

  /**
   * Initialize mixpanel.
   *
   * @memberof MixpanelService
   */
  public init(): void {
    mixpanel.init(environment.mixpanelToken);

    const userId = localStorage.getItem('parkingUser');
    if (userId) {
      mixpanel.identify(userId);
    }
  }

  /**
   * Set data to sync.
   *
   * @param {*} [data={}] Save user data.
   * @memberof MixpanelService
   */
  public setPeopleData(data: any = {}): void {
    mixpanel.people.set(data);
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  public track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }
}
