import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ignoreHotkeys } from '../../../utils/utils';
import { CpfCnpjValidator } from '../../../validators/cpfCnpjValidator';
import { ShowFilterModel } from '../../../models/filters/show-filter.model';
import { StateManagementService } from '../../../state-management/state-management.service';

@Component({
  selector: 'app-modal-filter-synapses',
  templateUrl: './modal-filter-synapses.component.html',
  styleUrls: ['./modal-filter-synapses.component.scss'],
})
export class ModalFilterSynapsesComponent implements OnInit {
  @Input() inputName?: string = 'Nome';
  @Input() filters: ShowFilterModel = new ShowFilterModel();
  @Input() statusList?: any = {};
  @Input() methodList?: string[];

  public filterForm!: FormGroup;
  public dateFormat: string = 'dd/MM/yyyy';
  private pattern: RegExp = /^[A-Za-z0-9_-]*$/;
  private patternUuid4: RegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  private selectedFilters: any = {};

  constructor(
    private readonly fb: FormBuilder,
    private readonly modalRef: NzModalRef,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getFilterData();
  }

  private getFilterData(): void {
    this.$notification.filterForms.subscribe((filterForm) => {
      if (filterForm) {
        this.selectedFilters = filterForm;
        this.updateForm();
      }
    });
  }

  public createForm(): void {
    this.filterForm = this.fb.group({
      sellerId: new FormControl('', [Validators.pattern(this.patternUuid4)]),
      customerId: new FormControl('', [Validators.pattern(this.patternUuid4)]),
      subscriptionId: new FormControl('', [Validators.pattern(this.patternUuid4)]),
      orderId: new FormControl('', [Validators.pattern(this.patternUuid4)]),
      title: new FormControl('', [Validators.pattern(this.pattern)]),
      storeName: new FormControl('', [Validators.pattern(this.pattern)]),
      creationDate: new FormControl(''),
      expirationDate: new FormControl(''),
      status: new FormControl([]),
      email: new FormControl('', [Validators.email]),
      document: new FormControl('', [CpfCnpjValidator.isValid()]),
      name: new FormControl(''),
      installments: new FormControl(''),
      method: new FormControl([]),
      id: new FormControl('', [Validators.pattern(this.patternUuid4)]),
    });
  }

  public clearFields(): void {
    this.selectedFilters = {};
    this.filterForm?.reset();
    this.$notification.setFilterData({});
  }

  public setHandleSubmit(): void {
    if (this.filterForm.valid) {
      this.$notification.setFilterForm(this.filterForm.value);
      this.modalRef.close({
        filterForm: this.filterForm.value,
      });
    } else {
      Object.values(this.filterForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  private updateForm(): void {
    this.filterForm.patchValue({
      sellerId: this.selectedFilters?.sellerId,
      customerId: this.selectedFilters?.customerId,
      subscriptionId: this.selectedFilters?.subscriptionId,
      orderId: this.selectedFilters?.orderId,
      title: this.selectedFilters?.title,
      storeName: this.selectedFilters?.storeName,
      creationDate: this.selectedFilters?.creationDate,
      expirationDate: this.selectedFilters?.expirationDate,
      status: this.selectedFilters?.status,
      email: this.selectedFilters?.email,
      document: this.selectedFilters?.document,
      name: this.selectedFilters?.name,
      installments: this.selectedFilters?.installments,
      method: this.selectedFilters?.method,
      id: this.selectedFilters?.id,
    });
  }

  public onlyNumbers(event: any): void {
    return ignoreHotkeys(event);
  }
}
