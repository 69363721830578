import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { CategoryService } from '../../../services/item/category/category.service';
import { StateManagementService } from '../../../state-management/state-management.service';

@Component({
  selector: 'app-modal-filter-product',
  templateUrl: './modal-filter-product.component.html',
  styleUrls: ['./modal-filter-product.component.scss'],
})
export class ModalFilterProductComponent {
  public formProductFilter: FormGroup;

  public sellerId: string = '';

  public categories: any;

  constructor(
    private fb: FormBuilder,
    private $modal: NzModalRef,
    private $categoryService: CategoryService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getNotification();
    this.getCategories();
  }

  public getNotification(): void {
    this.$notification.sellers.subscribe((seller) => {
      if (seller) {
        this.sellerId = seller.id;
      }
    });
  }

  public createForm(): void {
    this.formProductFilter = this.fb.group({
      name: new FormControl(''),
      category: new FormControl(''),
      rangeValue: new FormControl([0, 500000]),
      date: new FormControl(),
    });
  }

  public getFormValues(): void {
    this.$modal.close(this.formProductFilter.value);
  }

  public getCategories(): void {
    this.$categoryService.getCategoryList(this.sellerId).subscribe((category) => {
      if (category) {
        this.categories = category.data.listItemCategories;
      }
    });
  }

  public clearFields(): void {
    this.formProductFilter.reset();
  }
}
