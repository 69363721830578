import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export function expirationDateValidate(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const expirationDate = control?.value;
    const month = parseInt(expirationDate?.slice(0, 2), 10);
    const year = parseInt(expirationDate?.slice(2, 4), 10);
    const currentMonth = Number(moment().month() + 1);
    const currentYear = parseInt(moment().year().toString().slice(2, 4), 10);

    if (month > 12 || year < currentYear || (month < currentMonth && year === currentYear)) {
      return { expirationDateNotValid: true };
    } else {
      return null;
    }
  };
}
