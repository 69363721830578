import { Component, OnInit } from '@angular/core';
import { Faq } from './faq/faq.interface';

@Component({
  selector: 'gen-monorepo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  public loadValue: number = 5;
  public faq: Faq = new Faq();
  public loadQuentions: any[] = this.faq.questions.slice(0, this.loadValue);
  public btnMore: boolean = true;
  public current: number = 0;

  public ngOnInit(): void {
  }


  public filterFaq(value): void {
    if (value) {
      this.loadQuentions = [];
      this.loadQuentions = this.faq.questions;
      this.loadQuentions = this.loadQuentions.filter(x => x.title.toLowerCase().includes(value.toLowerCase()));
    } else {
      this.loadQuentions = this.faq.questions.slice(0, this.loadValue);
    }
  }

  public loadMore(): void {
    this.loadQuentions = [];
    this.loadValue = this.loadValue + 5;
    this.loadQuentions = this.faq.questions.slice(0, this.loadValue);
    if (this.loadQuentions.length > 8) {
      this.btnMore = false;
    } else {
      this.btnMore = true;
    }
  }

  public scrollTo(el: HTMLElement): void {
    el.scrollIntoView({behavior: 'smooth'});
  }

  public onIndexChange(index: any): void {
    this.current = index;
  }

}
