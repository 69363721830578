import { gql } from 'apollo-angular';

const EDIT_CUSTOMER_MUTATION = gql`
  mutation UpdateCustomer($id: UUID4!, $params: UpdateCustomerParams!) {
    updateCustomer(id: $id, params: $params) {
      id
    }
  }
`;

const BAN_CUSTOMER_MUTATION = gql`
  mutation BanCustomer($id: UUID4!) {
    banCustomer(id: $id) {
      id
    }
  }
`;

const DECLINE_CUSTOMER_MUTATION = gql`
  mutation DeclineCustomer($id: UUID4!) {
    declineCustomer(id: $id) {
      id
    }
  }
`;

const APPROVE_CUSTOMER_MUTATION = gql`
  mutation ApproveCustomer($id: UUID4!) {
    approveCustomer(id: $id) {
      id
    }
  }
`;

const REPROCESS_CUSTOMER_MUTATION = gql`
  mutation ReprocessCustomer($id: UUID4!) {
    reprocessCustomer(id: $id) {
      id
    }
  }
`;

const UPLOAD_CUSTOMER_DOCUMENT_MUTATION = gql`
  mutation UploadCustomerDocument($params: UploadCustomerDocumentParams!) {
    uploadCustomerDocument(params: $params) {
      id
    }
  }
`;

export {
  BAN_CUSTOMER_MUTATION,
  EDIT_CUSTOMER_MUTATION,
  DECLINE_CUSTOMER_MUTATION,
  APPROVE_CUSTOMER_MUTATION,
  REPROCESS_CUSTOMER_MUTATION,
  UPLOAD_CUSTOMER_DOCUMENT_MUTATION,
};
