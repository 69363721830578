import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { CategoryModel } from '../../../models/categories/categories.model';
import { NzMarks } from 'ng-zorro-antd/slider';
import { TagService } from '../../../services/item/tag/tag.service';
import { StateManagementService } from '../../../state-management/state-management.service';
import { TagsModel } from '../../../models/tags/tags.model';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.scss'],
})
export class FilterMenuComponent implements OnInit {
  @Input() public categories: Array<CategoryModel>;

  public form: FormGroup;
  public tags: Array<TagsModel>[] = [];

  public orderByOptions: Array<{ label: string; value: string }> = [
    { label: 'Mais recentes', value: 'inserted_at' },
    { label: 'Ordem alfabética', value: 'name' },
  ];

  public marks: NzMarks = {
    1: {
      style: {
        color: '#666666',
        'font-family': 'Poppins',
        'font-weight': '400',
        'font-size': '12px',
        'line-height': '18px',
      },
      label: 'R$ 1',
    },
    50000: {
      style: {
        color: '#666666',
        'font-family': 'Poppins',
        'font-weight': '400',
        'font-size': '12px',
        'line-height': '18px',
      },
      label: 'R$ 50.000',
    },
  };

  constructor(
    private fb: FormBuilder,
    public $drawerRef: NzDrawerRef,
    public $tagService: TagService,
    private $notification: StateManagementService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getNotification();
  }

  public getNotification(): void {
    this.$notification.routeParams.subscribe((res) => {
      if (res.sellerId) {
        this.getItemTags(res.sellerId);
      }
    });
  }

  private createForm(): void {
    this.form = this.fb.group({
      orderBy: new FormControl(''),
      discounts: new FormControl(''),
      category: new FormControl([]),
      freeFreight: new FormControl(''),
      color: new FormControl(''),
      size: new FormControl(''),
      rangeValue: new FormControl([0, 500000]),
    });
  }

  public getItemTags(sellerId: string): void {
    this.$tagService.getItemTags(sellerId).subscribe((res) => {
      if (res.data.listItemTags) {
        this.tags = res.data.listItemTags;
      }
    });
  }

  public applyFilters(): void {
    this.$drawerRef.close(this.form.value);
  }
}
