import { StoreOpeningHours } from './../../models/store/store-opening-hours';
import { Component, Input, ElementRef, ViewChild, OnInit } from '@angular/core';
import { WeekDays } from '../../enums/week-days.enum';
import { getTranslatedDays, sortDaysOfWeek } from '../../utils/utils';

@Component({
  selector: 'app-preview-page',
  templateUrl: './preview-page.component.html',
  styleUrls: ['./preview-page.component.scss'],
})
export class PreviewPageComponent implements OnInit {
  @ViewChild('previewPage') previewPage: ElementRef<HTMLDivElement>;

  @Input() public data: any;

  public openingHours: StoreOpeningHours[] = [];

  public ngOnInit(): void {
    this.getOpeningHours();
  }

  private getOpeningHours(): void {
    this.openingHours = this.data?.openingHours
      ? sortDaysOfWeek(
          Object.entries(this.data.openingHours).map((hour: any) => ({
            weekDay: hour[0],
            label: getTranslatedDays(hour[0]),
            openAt: hour[1]?.openAt ? hour[1].openAt.replace(/(\d{2})(\d{2})/, '$1:$2') : '',
            closeAt: hour[1]?.closeAt ? hour[1].closeAt.replace(/(\d{2})(\d{2})/, '$1:$2') : '',
            isOpen: hour[1]?.isOpen,
          }))
        )
      : [];
  }
}
