import { NzModalRef } from 'ng-zorro-antd/modal';
import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { LeadService } from '../../../services/lead/lead.service';

@Component({
  selector: 'app-modal-share-coupon',
  templateUrl: './modal-share-coupon.component.html',
  styleUrls: ['./modal-share-coupon.component.scss'],
})
export class ModalShareCouponComponent implements OnInit {
  @Input() public couponId: string = '';

  public loading: boolean = true;
  public formGroup!: FormGroup;
  public methods: Array<{ imageUrl: string; type: string }> = [
    { imageUrl: '/assets/img/circle-whatsapp.svg', type: 'WHATSAPP' },
    { imageUrl: '/assets/img/circle-email.svg', type: 'EMAIL' },
  ];
  public showAlert: boolean = false;
  public typeAlert: string = '';
  public alertMessage: string = '';

  constructor(
    private $lead: LeadService,
    private readonly fb: FormBuilder,
    private readonly clipboard: Clipboard,
    private readonly $modalRef: NzModalRef,
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getUrl();
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      url: new FormControl<string>({ value: '', disabled: true }),
    });
  }

  private getUrl(): void {
    this.$lead.shareCoupon(this.couponId).subscribe({
      next: (res) => {
        if (res?.data?.shareCoupon) {
          this.formGroup.get('url').setValue(res.data.shareCoupon);
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.openAlert('error', 'Erro ao compartilhar o cupom. Por favor, tente novamente mais tarde.', error);
      },
    });
  }

  public sendToPlatform(type: string): void {
    const url = encodeURIComponent(this.formGroup.getRawValue().url);
    const links = {
      WHATSAPP: `https://api.whatsapp.com/send?text=${url}`,
      EMAIL: `mailto:exemplo@email.com?subject=${url}`,
    };

    window.open(links[type], '_blank');
  }

  public copyContent(): void {
    const url = this.formGroup.getRawValue().url;
    this.clipboard.copy(url);
    this.openAlert('success', 'Link de pagamento copiado com sucesso!');
  }

  public closeModal(): void {
    this.$modalRef.close();
  }

  public openAlert(type: string, message: string, error?: any): void {
    if (error) {
      const parseError = JSON.parse(JSON.stringify(error));
      this.alertMessage =
        parseError.message.length > 50 ? parseError.message.substring(0, 50) + '...' : parseError.message;
    } else {
      this.alertMessage = message;
    }
    this.typeAlert = type;
    this.showAlert = true;

    setTimeout(() => {
      this.showAlert = false;
    }, 5000);
  }
}
