export class Faq {
  public questions: any[] = [
    {
      title: `O que é o Pix Parcelado?`,
      description: `<p>
        O Pix Parcelado é uma forma inovadora de parcelamento de compras. Com o <strong>Pix
        Parcelado</strong>, você tem a vantagem de <strong>comprar agora</strong> e <strong>pagar depois</strong>! E o melhor sem
        precisar comprometer o limite do seu cartão de crédito. Tudo de forma simples e
        rápida! 😉
      </p>`,
      active: true
    },
    {
      title: `Como comprar com o Pix Parcelado?`,
      description: `<p>
        Para utilizar o Pix Parcelado como forma de pagamento, abra o aplicativo <strong>Gen</strong> e clique
        em <strong>Pix Parcelado</strong>. Então:

        <ul>
          <li>
            Clique em <strong>Consultar Crédito</strong> para verificar o limite pré-aprovado;
          </li>
          <li>
            Escolha uma das duas opções para prosseguir:
            <ol>
              <li>
                Opção 1: Use o <strong>QR Code</strong> da loja. Clique e aponte a câmera para o QR Code,
                seguindo as instruções para continuar a solicitação.
              </li>
              <li>
                Opção 2: Selecione "<strong>Ver lojas</strong>" e escolha a loja onde deseja fazer a compra.
              </li>
            </ol>
          </li>
          <li>
            Em seguida, selecione a forma de pagamento “<strong>Pix Parcelado</strong>”, digite o valor da sua
            compra, confirme o valor e escolha a forma de parcelamento desejada;
          </li>
          <li>
            Após isso, você será redirecionado para confirmação de seu número de celular e
            envio de uma foto selfie. Caso necessário, o envio de uma documentação pode ser
            solicitada;
          </li>
          <li>
            Ao enviar essas informações, aguarde a notificação sobre a aprovação de sua
            compra. Caso haja alguma divergência, você será informado. E não se preocupe, esse
            processo leva apenas alguns instantes!
          </li>
        </ul>
      </p>`,
    },
    {
      title: `Quais são as vantagens de contratar o Pix Parcelado?`,
      description: `<p>
        Veja aqui os benefícios que o Pix Parcelado oferece a você:
        <ul>
          <li>
            Não compromete o limite do seu cartão de crédito;
          </li>
          <li>
            Contratação fácil e rápida;
          </li>
          <li>
            Isento de anuidade;
          </li>
          <li>
            Taxas de juros baixas.
          </li>
        </ul>
      </p>`,
    },
    {
      title: `Por quanto tempo minha proposta fica ativa após ser pré-aprovada`,
      description: `<p>
        A proposta fica ativa por um prazo de 30 dias após a pré-aprovação.
      </p>`,
    },
    {
      title: `Como pagar a entrada do Pix Parcelado?`,
      description: `<p>
        Para realizar o pagamento da entrada, basta seguir o passo a passo abaixo:

        <ul>
          <li>
            Abra o aplicativo Gen e clique na opção <strong>Pix Parcelado</strong>;
          </li>
          <li>
            Em seguida, clique em "<strong>Acompanhe seu pedido</strong>";
          </li>
          <li>
            Selecione a parcela de entrada para pagamento;
          </li>
          <li>
            Acesse o app do seu banco e realize o pagamento.
          </li>
        </ul>

        Lembre-se de que a única forma de pagamento disponível é o PIX. <strong>Importante</strong>! O
        prazo para pagamento da entrada é de 24 horas após confirmação do plano de
        pagamento.
      </p>`,
    },
    {
      title: `Como pagar minha parcela do Pix Parcelado?`,
      description: `<p>
        Para efetuar o pagamento da parcela, siga o procedimento abaixo:

        <ul>
          <li>
            Abra o aplicativo <strong>Gen</strong> e clique na opção <strong>Pix Parcelado</strong>;
          </li>
          <li>
            Em seguida, clique em "<strong>Acompanhe seu pedido</strong>";
          </li>
          <li>
            Selecione a parcela para pagamento;
          </li>
          <li>
          Acesse o app do seu banco e realize o pagamento.
          </li>
        </ul>

        Lembre-se de que a única forma de pagamento disponível é o PIX.
      </p>`,
    },
    {
      title: `Quem pode usufruir do Pix Parcelado?`,
      description: `<p>
        O Pix Parcelado é uma forma inovadora de parcelamento de compras. Com o <strong>Pix
        Parcelado</strong>, você tem a vantagem de <strong>comprar agora</strong> e <strong>pagar depois</strong>! E o melhor sem
        precisar comprometer o limite do seu cartão de crédito. Tudo de forma simples e
        rápida! 😉
      </p>`,
    },
    {
      title: `O que é o Pix Parcelado?`,
      description: `<p>
        Para usufruir do serviço Pix Parcelado, é necessário atender a certos requisitos, tais
        como:

        <ul>
          <li>
            Possuir nacionalidade brasileira;
          </li>
          <li>
            Ser maior de 18 anos;
          </li>
          <li>
            Ter um número de CPF ativo;
          </li>
          <li>
            Possuir um e-mail válido;.
          </li>
          <li>
            Possuir número de celular válido.
          </li>
        </ul>

        Além disso, é importante destacar que os dados informados durante todas as etapas
        devem pertencer à mesma pessoa que está efetuando a compra, e todas as
        informações serão verificadas durante nossas etapas de segurança.
      </p>`,
    },
    {
      title: `Por que preciso enviar uma selfie para contratação?`,
      description: `<p>
        O envio da foto do rosto é essencial para garantir a segurança dos consumidores e
        parceiros. É durante essa etapa que realizamos a verificação de identidade para
        confirmar se você é realmente você.
      </p>`,
    },
    {
      title: `Por que meu crédito não foi aprovado?`,
      description: `<p>
        A disponibilidade de crédito é determinada com base em uma análise criteriosa de um
        conjunto de regras, da Parcelex (nossa parceira), além de seguir as diretrizes
        estabelecidas pelo Banco Central. Em geral, se a oferta de crédito estiver
        temporariamente indisponível, não se preocupe, você pode tentar novamente em 30 a
        60 dias (conforme indicação em tela do app.)<br/><br/>
        Você pode obter mais informações sobre os possíveis motivos da <a href="https://ajuda.parcelex.com.br/credito-indisponivel" target="_blank">recusa de crédito</a>
      </p>`,
    },
  ]
}
