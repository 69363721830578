import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-create-webhooks-modal',
  templateUrl: './create-webhooks-modal.component.html',
  styleUrls: ['./create-webhooks-modal.component.scss'],
})
export class CreateWebhooksModalComponent implements OnInit {
  @Input() data: any;

  public formDescription: any;

  public eventsList: Array<string> = [
    'ACCOUNT.*',
    'SELLER.*',
    'TRANSFER.*',
    'BANK_ACCOUNT.*',
    'CUSTOMER.*',
    'TRANSACTION.*',
    'ORDER.*',
    'PAYMENT.*',
    'SUBSCRIPTION.*',
    'CREDIT_CARD.*',
    'PLAN.*',
    'CONTACT.*',
    'WALLET.*',
  ];

  public regex: string = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  constructor(private fb: FormBuilder, private $modalRef: NzModalRef) {}

  ngOnInit(): void {
    this.createForm();
    this.getFormValues();
  }

  public createForm(): void {
    this.formDescription = this.fb.group({
      target: new FormControl(null, [Validators.required, Validators.pattern(this.regex)]),
      authorization: new FormControl([], Validators.required),
      events: new FormControl(null, Validators.required),
    });
  }

  public getFormValues(): void {
    if (this.data) {
      this.formDescription.get('target').value = this.data.target;
      this.formDescription.get('authorization').value = this.data.authorization;
      this.formDescription.get('events').value = this.data.events;
    }
  }

  public setHandleSubmit(value): void {
    if (value) {
      this.$modalRef.close(this.formDescription.value);
    }
  }
}
