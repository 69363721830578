import moment from 'moment';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  Query,
} from '@angular/fire/compat/firestore';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { environment } from '@gen/environments';
import { ResponseFilterModel } from '../../models/filters/response-filter.model';
import { SubscriptionModel } from '../../models/subscriptions/subscriptions.model';
import {
  GET_SUBSCRIPTION_CHECKOUT_QUERY,
  GET_SUBSCRIPTION_QUERY,
  GET_SUBSCRIPTION__BUSINESS_QUERY,
  LIST_SUBSCRIPTION_BUSINESS,
  LIST_SUBSCRIPTION_QUERY,
} from '../../graphql/queries/subscription.queries';
import { FilterSubscriptionsParamsModel } from '../../models/subscriptions/filter-subscriptions-params.model';
import {
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_CHECKOUT,
  DELETE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_CHECKOUT,
} from '../../graphql/mutations/subscription.mutations';
import { StateManagementService } from '../../state-management/state-management.service';
import { InternalService } from '../internal/internal.service';
import { PaymentInfo } from '../../models/payments/payment-info.model';
import { v4 as uuidv4 } from 'uuid';
import { ItemModel } from '../../models/items/item.model';

@Injectable()
export class SubscriptionsService {
  constructor(
    private readonly apollo: Apollo,
    private readonly firestore: AngularFirestore,
    private $methods: InternalService,
    private $notification: StateManagementService
  ) {}

  public createSubscription(params: any): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_SUBSCRIPTION,
      variables: { params },
    });
  }

  public createSubscriptionCheckout(params: any): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_SUBSCRIPTION_CHECKOUT,
      variables: { params },
    });
  }

  public updateSubscription(params: any): Observable<any> {
    const id = JSON.parse(JSON.stringify(params.id));
    delete params.id;

    return this.apollo.mutate({
      mutation: UPDATE_SUBSCRIPTION,
      variables: { id: id, params },
    });
  }

  public updateSubscriptionCheckout(params: any): Observable<any> {
    const id = JSON.parse(JSON.stringify(params.id));
    delete params.id;

    return this.apollo.mutate({
      mutation: UPDATE_SUBSCRIPTION_CHECKOUT,
      variables: { id: id, params },
    });
  }

  public getPublicSubscription(id: string): Observable<SubscriptionModel | undefined> {
    return this.firestore
      .doc<SubscriptionModel>(`marketplaces/${environment.marketplaceId}/subscriptions/${id}`)
      .valueChanges();
  }

  public getSubscriptionListWithCustomer(customerId: string, marketplaceId: string, sellerId: string): Observable<any> {
    return this.firestore
      .collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/subscriptions`, (ref) =>
        ref.where('customerId', '==', customerId).orderBy('insertedAt', 'desc')
      )
      .valueChanges() as Observable<any>;
  }

  public deleteSubscription(id: string | string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_SUBSCRIPTION,
      variables: { id },
    });
  }

  public getActiveSubscriptions(marketplaceId: string, sellerId: string): Observable<SubscriptionModel[]> | undefined {
    return this.firestore
      .collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/subscriptions`, (ref) =>
        ref.where('status', '==', 'active')
      )
      .valueChanges() as Observable<SubscriptionModel[]>;
  }

  public getSubscriptionsWithFilters(
    marketplaceId: string,
    sellerId: string,
    filter: ResponseFilterModel
  ): Observable<SubscriptionModel[]> {
    return this.collection(marketplaceId, sellerId, filter).valueChanges();
  }

  private collection(
    marketplaceId: string,
    sellerId: string,
    filter?: ResponseFilterModel
  ): AngularFirestoreCollection<SubscriptionModel> {
    if (filter && !isEmpty(filter)) {
      return this.firestore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/subscriptions`, (ref) => {
        let query: CollectionReference | Query = ref;

        if (filter.creationDateFilter) {
          if (filter.creationDateFilter.date) {
            const startDate = moment(filter.creationDateFilter.date).set({ hour: 0, minute: 0, second: 0 }).format();
            const endDate = moment(filter.creationDateFilter.date).set({ hour: 23, minute: 59, second: 59 }).format();

            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else if (filter.creationDateFilter.startDate) {
            const startDate = moment(filter.creationDateFilter.startDate)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();
            const endDate = moment(filter.creationDateFilter.endDate)
              .set({ hour: 23, minute: 59, second: 59 })
              .format();

            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else {
            const date = moment()
              .subtract(filter.creationDateFilter.dateNumber, filter.creationDateFilter.datePeriod)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();

            query = query.where('insertedAt', '>=', date);
          }
        }

        if (filter.valuesFilter && !filter.creationDateFilter) {
          if (filter.valuesFilter.value) {
            switch (filter.valuesFilter.type) {
              case 0:
                query = query.where('totalCents', '==', filter.valuesFilter.value);
                break;
              case 2:
                query = query.where('totalCents', '>', filter.valuesFilter.value).orderBy('totalCents', 'asc');
                break;
              case 3:
                query = query.where('totalCents', '<', filter.valuesFilter.value).orderBy('totalCents', 'asc');
                break;
              default:
                break;
            }
          } else {
            query = query
              .where('totalCents', '>=', filter.valuesFilter.lowerValue)
              .where('totalCents', '<=', filter.valuesFilter.highestValue)
              .orderBy('totalCents', 'asc');
          }
        }

        if (filter.statusFilter) {
          const data: string[] = [];
          filter.statusFilter.forEach((status: any) => data.push(status.toLowerCase()));
          query = query.where('status', 'in', data);
        }

        if (filter.paymentMethodFilter && !filter.statusFilter) {
          query = query.where('method', 'in', filter.paymentMethodFilter);
        }

        return query.orderBy('insertedAt', 'desc');
      });
    }

    return this.firestore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/subscriptions`, (ref) =>
      ref.orderBy('insertedAt', 'desc')
    );
  }

  public getSubscriptionListApollo(filter?: FilterSubscriptionsParamsModel): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_SUBSCRIPTION_QUERY,
      variables: { filter },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getSubscriptionListBusiness(filter?: FilterSubscriptionsParamsModel): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_SUBSCRIPTION_BUSINESS,
      variables: { filter },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getDetailSubscription(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_SUBSCRIPTION__BUSINESS_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getDetailSubscriptionCheckout(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_SUBSCRIPTION_CHECKOUT_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getSubscriptionFilterList(filter?: {
    beforeOrEqual?: string;
    afterOrEqual?: string;
    sellerId?: string;
    customerId?: string;
  }): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_SUBSCRIPTION_QUERY,
      variables: {
        afterOrEqual: filter.afterOrEqual,
        beforeOrEqual: filter.beforeOrEqual,
        sellerId: filter.sellerId,
        customerId: filter.customerId,
      },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getSubscription(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_SUBSCRIPTION_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public createPayload(info: PaymentInfo): any {
    const items = info.subscription.items.map((i: any) => {
      const item = new ItemModel();
      item.description = i.description;
      item.itemId = i.item?.id;
      item.quantity = i.quantity;
      item.unitPriceCents = i.unitPriceCents;

      if (item.itemId === undefined) {
        delete item.itemId;
      }

      return item;
    });

    let data = {
      id: info?.subscription.id,
      method: info?.method,
      items,
      sessionId: uuidv4(),
      redirectTo: info?.redirectTo,
      externalId: info?.externalId,
      sellerId: info?.subscription.seller?.id,
      schedulePlans: info?.subscription?.schedulePlans,
      startDate: info?.subscription?.startDate,
      endDate: info?.subscription?.endDate,
    };

    if (info.customer.id) {
      delete info.customer.id;
    }

    data = Object.assign(data, { customer: info.customer });

    if (info.method === 'CREDIT_CARD') {
      if (info.creditCard?.id) {
        data = Object.assign(data, {
          creditCardId: info?.creditCard.id,
          securityCode: info?.creditCard?.securityCode,
        });
      } else {
        data = Object.assign(data, {
          creditCard: {
            token: info.token,
            holderName: info.customer?.name,
            expirationMonth: info.creditCard?.expirationMonth,
            expirationYear: info.creditCard?.expirationYear,
            securityCode: info.creditCard?.securityCode,
          },
        });
      }
    }

    return data;
  }

  public onSignSubscription(payload: any): void {
    this.updateSubscriptionCheckout(payload).subscribe({
      next: (response) => {
        const subscription = response.data.updateSubscription;

        if (subscription) {
          if (subscription?.status === 'ACTIVE') {
            this.$notification.setTypeResult('RECEIPT');
            if (payload.redirectTo) {
              window.location.href = payload.redirectTo;
            }
          }

          if (subscription?.status === 'WAITING' || subscription?.status === 'FAILING') {
            this.$notification.setTypeResult('ERROR');
            this.$methods.treatError(null);
          }
        } else {
          this.$methods.treatError(response);
        }
        this.$notification.setLoading(false);
      },
      error: (error) => {
        this.$methods.treatError(error);
        this.$notification.setLoading(false);
        throw new Error(error);
      },
    });
  }
}
