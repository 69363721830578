import { Observable, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Clipboard } from '@angular/cdk/clipboard';
import { NzMessageService } from 'ng-zorro-antd/message';

import { convertCentToCoin } from '../../utils/coins';
import { OrderModel } from '../../models/orders/order.model';
import { PaymentMethods } from '../../types/payment-methods.type';
import { PaymentsModel } from '../../models/payments/payments.model';

@Injectable()
export class UtilsService {
  constructor(
    private readonly http: HttpClient,
    private readonly $clipboard: Clipboard,
    private readonly $message: NzMessageService,
  ) {}

  public methodsList: Array<{ value: string; label: string }> = [
    { label: 'Boleto', value: 'BANK_SLIP' },
    { label: 'Cartão', value: 'CREDIT_CARD' },
    { label: 'PIX', value: 'PIX' },
    { label: 'Dinheiro', value: 'MONEY_EXTERNAL' },
    { label: 'Outros', value: 'DEBIT_EXTERNAL' },
  ];

  public scheduleList: Array<{ value: string; label: string }> = [
    { label: 'Diária', value: 'daily' },
    { label: 'Semanal', value: 'weekly' },
    { label: 'Quinzenal', value: 'biweekly' },
    { label: 'Mensal', value: 'monthly' },
    { label: 'Anual', value: 'annually' },
  ];

  public getMethods(data: Array<PaymentMethods>): string {
    const methods: any = {
      PIX: 'PIX',
      CREDIT_CARD: 'Cartão de crédito',
      BANK_SLIP: 'Boleto',
      MONEY_EXTERNAL: 'Dinheiro',
      DEBIT_EXTERNAL: 'Outros',
    };

    const result = data?.map((method) => methods[method]).filter((item) => item) || ['-'];

    return result.join('  •  ');
  }

  public getMethod(method: string): string {
    const methods: any = {
      PIX: 'PIX',
      CREDIT_CARD: 'Cartão de crédito',
      BANK_SLIP: 'Boleto',
      MONEY_EXTERNAL: 'Dinheiro',
      DEBIT_EXTERNAL: 'Outros',
    };

    return methods[method || ''];
  }

  public getIcon(method: string): string {
    const icon: any = {
      PIX: '../../../../assets/images/icons/pix.svg',
      CREDIT_CARD: '../../../../assets/images/icons/credit-card.svg',
      BANK_SLIP: '../../../../assets/images/icons/money-insert.svg',
      MONEY_EXTERNAL: '../../../../assets/images/icons/money-insert.svg',
      DEBIT_EXTERNAL: '../../../../assets/images/icons/circle-dollar.svg',
      WALLET: '../../../../assets/images/icons/money-insert.svg',
    };

    return icon[method || ''];
  }

  public getSchedule(schedule: string): string {
    const schedules: any = {
      daily: 'Diária',
      weekly: 'Semanal',
      biweekly: 'Quinzenal',
      monthly: 'Mensal',
      annually: 'Anual',
    };

    return schedules[schedule];
  }

  public formatMoney(input: number): string {
    return convertCentToCoin(input.toString());
  }

  public compareFn(o1: any, o2: any): boolean {
    if (o1 && o2) {
      if (o1.id === o2.id) {
        return true;
      }

      if (o1.description === o2.name) {
        return true;
      }

      if (o1.name === o2.name) {
        return true;
      }

      return false;
    }
    return false;
  }

  public percentOrZero(a: number, b: number): number {
    if (b !== 0) {
      return a / b - 1;
    } else {
      return 0;
    }
  }

  public getString(order: OrderModel, payments: Array<PaymentsModel>): string | undefined {
    const lastPaymentIndex = payments?.length - 1 || 0;

    if (
      order?.status != 'PAID' &&
      order?.status != 'PENDING' &&
      order?.status != 'PARTIALLY_PAID' &&
      order?.status != 'IN_ANALYSIS' &&
      order?.status != 'CANCELLED' &&
      order?.status != 'NOT_PAID'
    ) {
      return order.insertedAt;
    }

    if (
      order?.status === 'PENDING' ||
      order?.status === 'PARTIALLY_PAID' ||
      order?.status === 'IN_ANALYSIS' ||
      order?.status === 'CANCELLED' ||
      order?.status === 'NOT_PAID'
    ) {
      return order.updatedAt;
    }

    if (payments && order?.status === 'PAID') {
      return payments[lastPaymentIndex].insertedAt;
    }

    return '';
  }

  public copyContent(content: string): void {
    this.$clipboard.copy(content);
    this.$message.success('Conteúdo copiado com sucesso!');
  }

  public formatDistance(distanceInMeters: number): string {
    const distanceInKm = distanceInMeters / 1000;
    const result = Math.round(distanceInKm * 10) / 10;
    return `${result}KM`;
  }

  public httpRequest(method: string, url: string, options: any): Observable<any> {
    return this.http.request(method, url, options).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
