import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import internal from 'stream';
import { InternalService } from '../../../services/internal/internal.service';
import { SharedMethodsService } from '../../../services/internal/shared-methods.service';
import moment from 'moment';
import { ResponseFilterModel } from '../../../models/filters/response-filter.model';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
})
export class TableHeaderComponent implements OnInit {
  @Input() public title: string = '';
  @Input() public page: string = '';
  @Output() public onClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public searchString: EventEmitter<string> = new EventEmitter<string>();
  @Output() public filters: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup;

  constructor(public fb: FormBuilder, public $methods: SharedMethodsService) {}

  ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.form = this.fb.group({
      searchData: new FormControl(''),
    });

    this.getValuesChange();
  }

  public getValuesChange(): void {
    this.form.get('searchData').valueChanges.subscribe((value) => {
      this.searchString.emit(value);
    });
  }

  public openFilter(): void {
    switch (this.page) {
      case 'categories':
        this.$methods
          .showFilterModal({
            filterTitle: 'Categorias',
            filters: {
              isFilterCreationDate: true,
              isFilterName: true,
            },
          })
          .subscribe((res) => {
            if (res) {
              const filters = {
                name: res.nameFilter ? res.nameFilter : undefined,
                insertedAt: res.creationDateFilter
                  ? {
                      afterOrEqual: moment(res.creationDateFilter.startDate)
                        .set({ hour: 0, minute: 0, second: 0 })
                        .format(),
                      beforeOrEqual: moment(res.creationDateFilter.endDate)
                        .set({ hour: 23, minute: 59, second: 59 })
                        .format(),
                    }
                  : undefined,
              };
              this.filters.emit(filters);
            }
          });

        break;
    }
  }
}
