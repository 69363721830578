import { SidenavModel } from '../models/utils/sidenav.model';

const userRoutes: SidenavModel[] = [
  {
    title: 'Usuários Gen',
    route: '/internal/users/internal-users',
    disable: false,
  },
  {
    title: 'Usuários B2C',
    route: '/internal/users/customers',
    disable: false,
  },
  {
    title: 'Sellers',
    route: '/internal/users/sellers',
    disable: false,
  },
  {
    title: 'Bloqueados',
    route: '/internal/users/blacklist',
    disable: false,
  },
];

const bnplRoutes: SidenavModel[] = [
  {
    title: 'Vendas',
    route: '/internal/bnpl/sales',
    disable: false,
  },
  {
    title: 'Recebimentos',
    route: '/internal/bnpl/receipts',
    disable: false,
  },
  {
    title: 'Pagamentos',
    route: '/internal/bnpl/payments',
    disable: false,
  },
];

const parkingRoutes: SidenavModel[] = [
  {
    title: 'Operacional',
    route: '/internal/parking/operational',
    disable: false,
  },
  {
    title: 'Financeiro',
    route: '/internal/parking/financial',
    disable: false,
  },
  {
    title: 'Empresas pendentes',
    route: '/internal/parking/pending-company',
    disable: false,
  },
];

export const SYNPASES_SIDENAV: SidenavModel[] = [
  {
    title: 'Usuários',
    icon: 'team',
    disable: false,
    isOpen: false,
    childrens: userRoutes,
  },
  {
    title: 'Pedidos',
    icon: 'file-done',
    route: '/internal/orders',
    disable: false,
  },
  {
    title: 'Recorrências',
    icon: 'container',
    route: '/internal/subscriptions',
    disable: false,
  },
  {
    title: 'Pagamentos',
    icon: 'dollar',
    route: '/internal/payments',
    disable: false,
  },
  {
    title: 'Pix Parcelado',
    svg: '/assets/img/black-pix.svg',
    disable: false,
    isOpen: false,
    childrens: bnplRoutes,
  },
  {
    title: 'Cancelamentos',
    icon: 'close-circle',
    route: '/internal/cancellments',
    disable: false,
  },
  {
    title: 'Fidelidade',
    icon: 'tag',
    route: '/internal/fidelity',
    disable: false,
  },
  {
    title: 'Estacionamento',
    icon: 'database',
    route: '/internal/parking',
    disable: false,
    isOpen: false,
    childrens: parkingRoutes,
  },
  {
    title: 'Saques',
    icon: 'vertical-align-bottom',
    route: '/internal/withdrawals',
    disable: false,
  },
  {
    title: 'Taxas',
    icon: 'percentage',
    route: '/internal/fees',
    disable: false,
  },
  {
    title: 'Eventos',
    icon: 'calendar',
    route: '/internal/events',
    disable: false,
  },
  {
    title: '',
    disable: false,
  },
  {
    title: 'Sair',
    icon: 'import',
  },
];
