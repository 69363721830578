import { NzModalService } from 'ng-zorro-antd/modal';
import { Component, OnInit } from '@angular/core';

import { PointModel } from '../../models/points/point-of-sales.model';
import { StateManagementService } from '../../state-management/state-management.service';

@Component({
  selector: 'app-checkout-flow-modal',
  templateUrl: './checkout-flow-modal.component.html',
  styleUrls: ['./checkout-flow-modal.component.scss'],
})
export class CheckoutFlowModalComponent implements OnInit {
  public point: PointModel = new PointModel();

  constructor(private readonly $modal: NzModalService, private $notification: StateManagementService) {}

  public ngOnInit(): void {
    this.getPoint();
  }

  public getPoint(): void {
    this.$notification.points.subscribe((res) => {
      if (res) {
        this.point = res;
      }
    });
  }

  public isAuthenticated(event: boolean): void {
    if (event) {
      this.$modal.closeAll();
    }
  }
}
