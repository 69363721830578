import { Component, Input, OnInit } from '@angular/core';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-error-payment-modal',
  templateUrl: './error-payment-modal.component.html',
  styleUrls: ['./error-payment-modal.component.scss'],
})
export class ErrorPaymentModalComponent implements OnInit {
  public errorMsg: string;

  constructor(
    private $notification: StateManagementService,
    public $modalRef: NzModalRef,
  ) {}

  ngOnInit(): void {
    this.getNotification();
  }

  public getNotification(): void {
    this.$notification.errorMesages.subscribe((errorMessage) => {
      if (errorMessage) {
        this.errorMsg = errorMessage;
      }
    });
  }

  public tryAgain(): void {
    this.$modalRef.close();
    this.$notification.setTypeResult('PAYMENT');
  }
}
