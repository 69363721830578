import { gql } from 'apollo-angular';

const GET_ITEM_VARIANT_QUERY = gql`
  query GetItemVariant($id: UUID4!) {
    getItemVariant(id: $id) {
      id
      insertedAt
      itemVariantOptions {
        id
        active
        name
        orderIndex
      }
      name
      orderIndex
      type
    }
  }
`;

const LIST_ITEM_VARIANT_QUERY = gql`
  query ListItemVariants($filters: FilterItemVariantsParams, $pagination: PaginationParams) {
    listItemVariants(filters: $filters, pagination: $pagination) {
      id
      insertedAt
      itemVariantOptions {
        id
        active
        color
        name
        orderIndex
      }
      name
      orderIndex
      type
    }
  }
`;
const GET_ITEM_VARIANT_OPTION_QUERY = gql`
  query getItemVariantOption($id: UUID4!) {
    getItemVariantOption(id: $id) {
      id
      active
      name
      orderIndex
    }
  }
`;

export { GET_ITEM_VARIANT_QUERY, GET_ITEM_VARIANT_OPTION_QUERY, LIST_ITEM_VARIANT_QUERY };
