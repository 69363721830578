import { gql } from 'apollo-angular';

const SEND_VERIFICATION_CODE_MUTATION = gql`
  mutation SendVerificationCode(
    $phoneNumber: String!
    $platform: PlatformEnum
    $communicationMethod: CommunicationMethodEnum
  ) {
    sendVerificationCode(phoneNumber: $phoneNumber, platform: $platform, communicationMethod: $communicationMethod)
  }
`;

const VALIDATE_VERIFICATION_CODE_MUTATION = gql`
  mutation ValidateVerificationCodeFirebase($phoneNumber: String!, $code: String!) {
    validateVerificationCodeFirebase(phoneNumber: $phoneNumber, code: $code) {
      accessToken
      refreshToken
      customToken
    }
  }
`;

const RENEW_ACCESS_TOKEN_MUTATION = gql`
  mutation RenewAccessToken($refreshToken: String!) {
    renewAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

const LOGIN_BY_PASSWORD_MUTATION = gql`
  mutation LoginByPassword($email: String!, $password: String!, $platform: PlatformEnum) {
    loginByPassword(email: $email, password: $password, platform: $platform) {
      accessToken
      customToken
      refreshToken
    }
  }
`;

export {
  LOGIN_BY_PASSWORD_MUTATION,
  RENEW_ACCESS_TOKEN_MUTATION,
  SEND_VERIFICATION_CODE_MUTATION,
  VALIDATE_VERIFICATION_CODE_MUTATION,
};
