import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-confirmation-delete',
  templateUrl: './confirmation-delete.component.html',
  styleUrls: ['./confirmation-delete.component.scss'],
})
export class ConfirmationDeleteComponent implements OnInit {
  @Input() message: string;
  @Input() isImportant: boolean;
  @Input() buttonName: string;
  @Input() isDeleteAction: boolean = true;

  public icon: string = 'close-circle';

  constructor(public $modalRef: NzModalRef) {}

  public ngOnInit(): void {
    this.icon = this.isDeleteAction ? 'close-circle' : 'check-circle';
  }
}
