import { StateManagementService } from '../../state-management/state-management.service';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { ResponseModel } from '../../models/response/response.model';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PointOfSalesService } from 'libs/shared/src/lib/services/point-of-sales/point-of-sales.service';
import { ContactModel } from '../../models/contact/contact.model';
import { ContactsService } from '../contacts/contacts.service';
import { CreateAccountModel } from '../../models/auth/create-account.model';
import { AlertService } from '../alert/alert.service';

@Injectable({
  providedIn: 'root',
})
export class AuthStoreService {
  constructor(
    private $functions: AngularFireFunctions,
    private $notification: StateManagementService,
    public firebaseAuth: AngularFireAuth,
    private router: Router,
    private $contact: ContactsService,
    private $point: PointOfSalesService,
    private $alert: AlertService
  ) {}

  public sendSmsCode(data: CreateAccountModel): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('sendSmsCode')({ phoneNumber: `+55${data.phoneNumber}`, type: data.type });
  }

  public validatePhoneNumberCode(data: CreateAccountModel): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('validatePhoneNumberCode')({
      phoneNumber: `+55${data.phoneNumber}`,
      type: data.type,
      code: data.code,
    });
  }

  public userWithPhoneNumberExists(phoneNumber: string): Observable<ResponseModel<boolean>> {
    return this.$functions.httpsCallable('userWithPhoneNumberExists')(`+55${phoneNumber}`);
  }

  public customLogin(sellerId: string, pointId: string, token: string): void {
    this.firebaseAuth.signInWithCustomToken(token).then((res) => {
      if (res?.user) {
        this.$notification.setUser(res.user);
        this.$point.setCustomer(pointId, res.user);
        this.$alert.setAlertInfo('SUCCESS', 'Login realizado com sucesso!');
        this.router.navigate(['/internal/' + sellerId + '/' + pointId]);
      }
    });
  }

  public createContact(sellerId: string, pointId: string, data: ContactModel): Promise<void> {
    return this.$contact.addNewContact(sellerId, pointId, data);
  }
}
