import { gql } from 'apollo-angular';

const LIST_BLACKLIST_QUERY = gql`
  query ListBlacklist($filters: FilterBlacklistParams) {
    listBlacklist(filters: $filters, pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }) {
      cnpj
      cpf
      email
      id
      insertedAt
      phone
      updatedAt
    }
  }
`;

const GET_BLACKLIST_QUERY = gql`
  query getBlacklistItem($id: UUID4!) {
    getBlacklistItem(id: $id) {
      cnpj
      cpf
      email
      id
      insertedAt
      phone
      updatedAt
    }
  }
`;

export { LIST_BLACKLIST_QUERY, GET_BLACKLIST_QUERY };
