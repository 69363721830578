import { combineLatest } from 'rxjs';
import moment from 'moment-timezone';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { differenceInCalendarDays } from 'date-fns';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { onlyNumbers } from '../../../utils/utils';
import { MallModel } from '../../../models/mall/mall.model';
import { FidelityService } from '../../../services/fidelity/fidelity.service';
import { CouponTypeFidelityModel } from '../../../models/fidelity/coupon-type-fidelity.model';
import { CouponCategoryFidelityModel } from '../../../models/fidelity/coupon-category-fidelity.model';

@Component({
  selector: 'app-modal-assoc-category-and-type-to-coupon',
  templateUrl: './modal-assoc-category-and-type-to-coupon.component.html',
  styleUrls: ['./modal-assoc-category-and-type-to-coupon.component.scss'],
})
export class ModalAssocCategoryAndTypeToCouponComponent implements OnInit {
  @Input() data: any;

  public formGroup!: FormGroup;
  public loading: boolean = true;
  public types: Array<CouponTypeFidelityModel> = [];
  public categories: Array<CouponCategoryFidelityModel> = [];
  public malls: Array<MallModel> = [];
  public disabledDate = (startDate: Date): boolean => differenceInCalendarDays(new Date(), startDate) > 0;

  constructor(
    private readonly fb: FormBuilder,
    private $fidelity: FidelityService,
    private readonly $modalRef: NzModalRef
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getData();
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      categoryId: new FormControl<string>('', [Validators.required]),
      typeId: new FormControl<string>('', [Validators.required]),
      mallId: new FormControl<number>(null),
      expirationDate: new FormControl<Date>(null, [Validators.required]),
      viewApp: new FormControl<boolean>(true, [Validators.required]),
    });
  }

  private getData(): void {
    combineLatest({
      types: this.$fidelity.getCouponTypesList(),
      categories: this.$fidelity.getCategoriesList(),
      malls: this.$fidelity.getMallList(),
    }).subscribe({
      next: (res) => {
        if (res) {
          if (res.types?.data?.couponsType) {
            this.types = JSON.parse(JSON.stringify(res.types.data.couponsType));
          }

          if (res.categories?.data?.categories) {
            this.categories = JSON.parse(JSON.stringify(res.categories.data.categories));
          }

          if (res.malls?.data?.malls) {
            this.malls = JSON.parse(JSON.stringify(res.malls.data.malls));
          }
        }

        if (this.data.coupon_type?.id) {
          this.updateForm();
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        throw new Error(error);
      },
    });
  }

  private updateForm(): void {
    this.formGroup.patchValue({
      categoryId: this.data.categories?.[0]?.id,
      typeId: this.data.coupon_type?.id,
      mallId: this.data.mall?.id ? Number(this.data.mall.id) : null,
      expirationDate: moment(this.data.expiration_date).toDate(),
      viewApp: this.data.app_view,
    });
  }

  public setHandleSubmit(value: boolean): void {
    if (value) {
      this.$modalRef.close(this.formGroup.value);
    } else {
      this.$modalRef.close();
    }
  }

  public onlyNumbers(event: any): void {
    return onlyNumbers(event);
  }
}
