import { DashboardBankModel } from './internal/dashboard-bank.model';
import { DashboardDocumentModel } from './internal/dashboard-document.model';
import { DashboardPersonalModel } from './internal/dashboard-personal.model';
import { DashboardCompanyDetailsModel } from './internal/dashboard-company-details.mode';
import { DashboardEmailValidationModel } from './internal/dashboard-email-validation.mode';
import { DashboardCompanyDetailsAnonymousModel } from './internal/dashboard-company-details-anonymous.model';

export class DashboardModel {
  public stage: number = 0;
  public phoneNumber: string = '';
  public insertedAt: string = '';
  public sendDocument: boolean = false;
  public termAcceptedAt: string = '';
  public phoneVerifiedAt: string = '';
  public emailVerifiedAt: string = '';
  public bankData: DashboardBankModel = new DashboardBankModel();
  public documents: DashboardDocumentModel = new DashboardDocumentModel();
  public personalData: DashboardPersonalModel = new DashboardPersonalModel();
  public companyDetails: DashboardCompanyDetailsModel = new DashboardCompanyDetailsModel();
  public emailValidation: DashboardEmailValidationModel = new DashboardEmailValidationModel();
  public companyDetailsAnonymous: DashboardCompanyDetailsAnonymousModel = new DashboardCompanyDetailsAnonymousModel();
}
