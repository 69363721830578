import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { RoleEnum } from '../../../enums/roles';
import { trimData } from '../../../utils/utils';
import { CpfValidator } from '../../../validators/cpfValidator';
import { EmailValidator } from '../../../validators/emailValidator';
import { PasswordValidator } from '../../../validators/passwordValidator';
import { UserClaimsModel } from '../../../models/user-claims/user-claims.model';

@Component({
  selector: 'app-drawer-create-user',
  templateUrl: './drawer-create-user.component.html',
  styleUrls: ['./drawer-create-user.component.scss'],
})
export class DrawerCreateUserComponent implements OnInit {
  @Input() value: any;

  public user: UserClaimsModel = new UserClaimsModel();
  public formDescription!: FormGroup;
  public loading: boolean = false;
  public roles: Array<{ value: RoleEnum; key: string }> = [
    { value: RoleEnum.ADMIN, key: 'ADMIN' },
    { value: RoleEnum.MARKETING, key: 'MARKETING' },
    { value: RoleEnum.FINANCE, key: 'FINANCE' },
    { value: RoleEnum.CUSTOMER_EXPERIENCE, key: 'CUSTOMER_EXPERIENCE' },
    { value: RoleEnum.COMPLIANCE, key: 'COMPLIANCE' },
    { value: RoleEnum.USER, key: 'USER' },
  ];
  public title: string = 'Criar usuário';
  public errorTipPassword: {
    hasMinLength?: boolean;
    hasLowerCase?: boolean;
    hasUpperCase?: boolean;
    hasSpecialCharacter?: boolean;
  } = {};

  constructor(private readonly fb: FormBuilder, public readonly drawerRef: NzDrawerRef) {}

  public ngOnInit(): void {
    this.createForm();
    this.setInfo();
  }

  private setInfo(): void {
    if (this.value) {
      this.title = 'Editar usuário';
      this.getSellerUserById();
    }
  }

  private createForm(): void {
    this.formDescription = this.fb.group({
      id: new FormControl(''),
      active: new FormControl(true),
      role: new FormControl('', [Validators.required]),
      cpf: new FormControl('', [Validators.required, CpfValidator.isValid()]),
      name: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, EmailValidator.isValid()]),
      password: new FormControl('', [Validators.required, PasswordValidator.isValid()]),
    });
  }

  public validatePassword(pwd: Event): void {
    const password = (pwd.target as HTMLInputElement).value;
    const patternSpecialCharacter = /^(?=.*[@!#$%^&*()/\\])/;
    const patternLowerCase = /[a-z]+/;
    const patternUpperCase = /[A-Z]+/;

    this.errorTipPassword = {
      hasMinLength: password.length >= 8,
      hasSpecialCharacter: patternSpecialCharacter.test(password),
      hasUpperCase: patternUpperCase.test(password),
      hasLowerCase: patternLowerCase.test(password),
    };
  }

  public getSellerUserById(): void {
    this.formDescription.get('cpf').disable();
    this.formDescription.get('password').clearValidators();
    this.formDescription.get('password').setValidators(PasswordValidator.isValid());

    this.formDescription.patchValue({
      id: this.value?.id,
      active: this.value?.active,
      role: this.value?.role,
      cpf: this.value?.cpf,
      name: this.value?.name,
      phone: this.value?.phone?.substring(3),
      email: this.value?.email,
      password: this.value?.password,
    });
  }

  public createUser(): void {
    this.drawerRef.close(trimData(this.formDescription.value));
  }

  public handleRole(role: string): void {
    this.formDescription.get('role').setValue(role);
  }
}
