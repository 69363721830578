import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StateManagementService } from '../../state-management/state-management.service';
import { CategoryModel } from '../../models/categories/categories.model';
import { StoreModel } from '../../models/store/store.model';

@Component({
  selector: 'app-navigate-and-filter',
  templateUrl: './navigate-and-filter.component.html',
  styleUrls: ['./navigate-and-filter.component.scss'],
})
export class NavigateAndFilterComponent {
  public isShowCategory: boolean;
  @Output() public onClick = new EventEmitter();

  public store: StoreModel;
  public category: CategoryModel;

  constructor(private $notification: StateManagementService) {}

  public ngOnInit(): void {
    this.getNotifications();
  }

  public getNotifications(): void {
    this.$notification.stores.subscribe((store) => {
      if (store) {
        this.store = store;
      }
    });

    this.$notification.changedCategorys.subscribe((category) => {
      if (category) {
        this.category = category;
      }
    });

    this.$notification.isShowCategories.subscribe((res) => {
      this.isShowCategory = res;
    });
  }

  public getOrderByValue(orderBy): void {
    if (orderBy) {
      this.$notification.setOrderBy(orderBy);
      this.category = {};
    }
  }

  public callToAction(type: string): void {
    this.onClick.emit(type);
  }
}
