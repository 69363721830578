import { mergeMap, of } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from '../../services/auth/auth-business/auth.service';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Message } from '../../utils/message';
import { LoginModel } from '../../models/login/login.model';
import { CpfValidator } from '../../validators/cpfValidator';
import { AddressModel } from '../../models/address/address.model';
import { AddressService } from '../../services/address/address.service';
import { CustomerService } from '../../services/customer/customer.service';
import { CreateAccountModel } from '../../models/auth/create-account.model';
import { EmailValidator } from 'libs/shared/src/lib/validators/emailValidator';
import { OnboardingService } from '../../services/onboarding/onboarding.service';
import { FullNameValidator } from 'libs/shared/src/lib/validators/fullNameValidator';
import { InitialDataModel } from '../../models/onboarding/external/initial-data.model';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss'],
})
export class LoginRegisterComponent implements OnInit {
  @Output() public authenticated = new EventEmitter<boolean>();

  public userPhoneSet: boolean = false;
  public newPhoneSet: boolean = false;
  public login: LoginModel = new LoginModel();
  public formCreateAccount!: FormGroup;
  public formPhoneLogin!: FormGroup;
  public newAccount: CreateAccountModel = new CreateAccountModel();
  public newAccountAddress: AddressModel = new AddressModel();
  private isAuth: boolean = false;
  public loading: boolean = false;
  public selectedIndex: number = 0;
  public cpfErrorMsg: string = 'CPF inválido';
  public loadingCep: boolean = false;
  public cepErrorMessage: string = "Campo obrigatório"

  constructor(
    private $auth: AuthService,
    private $address: AddressService,
    private readonly fb: FormBuilder,
    private $onboarding: OnboardingService,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService,
    private $customer: CustomerService
  ) { }

  public ngOnInit(): void {
    this.createForm();
    this.getValuesChange();
  }

  private createForm(): void {
    this.formCreateAccount = this.fb.group({
      name: new FormControl<string>('', [Validators.required, FullNameValidator.isValid()]),
      cpf: new FormControl<string>('', [Validators.required, CpfValidator.isValid()]),
      phoneNumber: new FormControl<string>('', [Validators.required]),
      email: new FormControl<string>('', [Validators.required, EmailValidator.isValid()]),
      postalCode: new FormControl<string>('', [Validators.required]),
      streetNumber: new FormControl<string>('', [Validators.required]),
      complement: new FormControl<string>(''),
      saveInfo: new FormControl<boolean>(false),
    });

    this.formPhoneLogin = this.fb.group({
      userPhone: new FormControl<string>('', [Validators.required]),
    });
  }

  public getPostalCode(value: string): void {
    if (value && value.length === 8) {
      this.$address.getCep(value).subscribe({
        next: (res: any) => {
          if (res.cep) {
            this.newAccountAddress.city = res.localidade;
            this.newAccountAddress.state = res.uf;
            this.newAccountAddress.neighborhood = res.bairro;
            this.newAccountAddress.line1 = res.logradouro;
            this.newAccountAddress.postalCode = value;
          } else {
            this.cepErrorMessage = "CEP inválido"
            this.formCreateAccount.get('postalCode')?.setErrors({ pattern: true });
          }
        },
        error: (error) => {
          this.$message.create('error', Message.CEP_NOT_FOUND);
          this.formCreateAccount.get('postalCode')?.setErrors({ pattern: true });
          throw new Error(error);
        },
      });
    }
  }

  public changeNumber(value: boolean) {
    this.userPhoneSet = value;
    this.newPhoneSet = value;
  }

  public getValuesChange(): void {
    this.formCreateAccount.get('cpf').valueChanges.subscribe((cpf) => {
      if (this.formCreateAccount.get('cpf').status === 'VALID') {
        if (cpf.length === 11) {
          this.$customer.customerAlreadyExistsCpf(cpf).subscribe((res) => {
            if (res.errors) {
              this.cpfErrorMsg = res.errors[0].message;
              this.formCreateAccount.get('cpf').setValue('');
              setTimeout(() => {
                this.formCreateAccount.get('cpf').reset(), (this.cpfErrorMsg = 'CPF inválido');
              }, 2000);
            }
          });
        }
      }
    });

    this.formCreateAccount.get('postalCode').valueChanges.subscribe((cep) => {
      if (cep.length === 8) {
        this.getPostalCode(cep);
      }
    })
  }

  public createAccount(): void {
    this.loading = true;
    this.newAccountAddress.line2 = this.formCreateAccount.get('streetNumber').value;
    this.newAccountAddress.line3 = this.formCreateAccount.get('complement').value;

    this.newAccount = {
      username: this.formCreateAccount.get('name').value,
      email: this.formCreateAccount.get('email').value,
      phoneNumber: this.formCreateAccount.get('phoneNumber').value,
      cpf: this.formCreateAccount.get('cpf').value,
    };

    const initialData: InitialDataModel = {
      name: this.newAccount.username,
      cpf: this.newAccount.cpf,
      phoneNumber: this.newAccount.phoneNumber,
    };

    this.$onboarding
      .startCustomerOnboarding(initialData)
      .pipe(
        mergeMap((res) => {
          if (res?.data?.startCustomerOnboarding) {
            return this.$auth.sendSmsCode({ phoneNumber: initialData.phoneNumber }, 'B2C', 'SMS');
          }

          this.$message.create('error', Message.ERROR_REGISTER_ACCOUNT);
          return of(undefined);
        })
      )
      .subscribe({
        next: (resp) => {
          if (resp?.data?.sendVerificationCode) {
            this.newPhoneSet = true;
          }

          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.$message.create('error', error);
          throw new Error(error);
        },
      });
  }

  public isAuthenticated(event: boolean): void {
    this.isAuth = event;

    if (this.newPhoneSet) {
      this.$onboarding.createCustomer(this.newAccount, this.newAccountAddress).subscribe({
        next: (res) => {
          if (res?.data?.finishCustomerOnboarding) {
            this.authenticated.emit(this.isAuth);
          }
        },
        error: (error) => {
          this.$message.create('error', error);
          throw new Error(error);
        },
      });
    } else {
      this.authenticated.emit(this.isAuth);
    }
  }

  public onSignIn(): void {
    this.loading = true;

    this.login.phoneNumber = this.formPhoneLogin.get('userPhone').value;

    this.$auth
      .userWithPhoneNumberExists(this.login.phoneNumber)
      .pipe(
        mergeMap((res) => {
          if (res.body) {
            return this.$auth.sendSmsCode({ phoneNumber: this.login.phoneNumber }, 'B2C', 'SMS');
          }

          this.$message.create('error', Message.PHONE_NOT_FOUND);
          return of(undefined);
        })
      )
      .subscribe({
        next: (res) => {
          if (res?.data?.sendVerificationCode) {
            this.$notification.setPhoneNumber(this.login.phoneNumber);
            this.newAccount.phoneNumber = this.login.phoneNumber;
            this.userPhoneSet = true;
          }

          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.$message.create('error', error);
          throw new Error(error);
        },
      });
  }

  public onChangeTab(index: number): void {
    this.selectedIndex = index;
  }
}
