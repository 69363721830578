import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Query } from 'apollo-angular';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import {
  CREATE_ITEM_CATEGORY_MUTATION,
  DELETE_ITEM_CATEGORY_MUTATION,
  UPDATE_ITEM_CATEGORY_MUTATION,
} from 'libs/shared/src/lib/graphql/mutations/category.mutations';
import { CategoryModel } from 'libs/shared/src/lib/models/categories/categories.model';
import { LIST_ITEM_CATEGORIES_QUERY } from 'libs/shared/src/lib/graphql/queries/category.queries';
import { LIST_ITEMS_CATEGORYS_QUERY } from '../../../graphql/queries/item.queries';
import { StateManagementService } from '../../../state-management/state-management.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzCascaderOption } from 'ng-zorro-antd/cascader';

@Injectable()
export class CategoryService {
  constructor(
    private readonly apollo: Apollo,
    private readonly afFunc: AngularFireFunctions,
    private $notification: StateManagementService,
    private $message: NzMessageService
  ) {}

  public createCategory(data: CategoryModel): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_ITEM_CATEGORY_MUTATION,
      variables: data,
    });
  }

  public getCategoryList(sellerId: string, filters: any = {}): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_ITEM_CATEGORIES_QUERY,
      variables: { sellerId, filters },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getItemCategoryList(filters: any = {}): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_ITEMS_CATEGORYS_QUERY,
      variables: { filters },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public deleteCategory(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_ITEM_CATEGORY_MUTATION,
      variables: { id },
    });
  }

  public updateCategory(data: Partial<CategoryModel>): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_ITEM_CATEGORY_MUTATION,
      variables: data,
    });
  }

  public batchAssocCategory(data: CategoryModel): Observable<any> {
    return this.afFunc.httpsCallable('batchAssocCategory')(data);
  }

  public getAndSetCategoriesAll(): void {
    const filters = { level: 0 };

    this.getItemCategoryList(filters).subscribe({
      next: (res) => {
        this.$notification.setCategoriesList(res.data.listItemCategories);
      },
      error: (error) => {
        this.$message.error('Falha ao carregar as categorias.', error);
      },
    });
  }

  public setCategoriesCascader(categories: CategoryModel[]): NzCascaderOption[] {
    categories = categories.filter((categorie) => categorie.children.length > 0);

    const nzOptions: NzCascaderOption[] = categories.map((categorie) => {
      const obj = {
        label: categorie.name,
        value: categorie.id,
        isLeaf: categorie.children.length > 0 ? false : true,
        isParent: categorie.children.length > 0 ? true : false,
        children: categorie.children.map((categoriesChildren) => {
          return {
            label: categoriesChildren.name,
            value: categoriesChildren.id,
            isLeaf: categoriesChildren.children.length > 0 ? false : true,
            isParent: categoriesChildren.children.length > 0 ? true : false,
            children: categoriesChildren.children.map((categoriesChildrenChildren) => {
              return {
                label: categoriesChildrenChildren.name,
                value: categoriesChildrenChildren.id,
                isLeaf: true,
              };
            }),
          };
        }),
      };
      return obj;
    });

    return nzOptions;
  }

  public setCascaderCategoriesIds(options: NzCascaderOption[], targetValue: any): any[] {
    for (const option of options) {
      if (option.value === targetValue) {
        return [option.value];
      }

      if (option.children) {
        const childResult = this.setCascaderCategoriesIds(option.children, targetValue);
        if (childResult.length) {
          return [option.value, ...childResult];
        }
      }
    }

    return [];
  }
}
