import { gql } from 'apollo-angular';

const WITHDRAWALS_FIELDS = `
  amountCents
  id
  insertedAt
  status
  updatedAt
  statusReason
  wallet {
    currentBalance
    blockedBalance
    seller {
      id
      person {
        name
        cpf
      }
      company {
        businessName
        cnpj
      }
    }
  }
  walletTransaction {
    id
    status
  }
`;

const WITHDRAWALS_FIELDS_SELLER = `
  amountCents
  id
  insertedAt
  status
  updatedAt
`;


const LIST_WITHDRAWS_QUERY = gql`
  query ListWithdraws($filter: FilterWithdrawsParams) {
    listWithdraws(filters: $filter, pagination: { sortingOrder: DESC, orderBy: "inserted_at", perPage: 100 }) {
      ${WITHDRAWALS_FIELDS}
    }
  }
`;


const LIST_SELLER_WITHDRAWS_QUERY = gql`
  query ListSellerWithdraws($filter: FilterWithdrawsParams, $sellerId: UUID4!) {
    listSellerWithdraws(filters: $filter, pagination: { sortingOrder: DESC, orderBy: "inserted_at", perPage: 100 }, sellerId: $sellerId) {
      ${WITHDRAWALS_FIELDS_SELLER}
    }
  }
`;

const GET_WITHDRAW_QUERY = gql`
  query GetWithdraw($id: UUID4!) {
    getWithdraw(id: $id) {
      ${WITHDRAWALS_FIELDS}
    }
  }
`;

export { LIST_WITHDRAWS_QUERY, GET_WITHDRAW_QUERY, LIST_SELLER_WITHDRAWS_QUERY };
