import { NzMessageService } from 'ng-zorro-antd/message';
import { mergeMap, of, Subject, Subscription } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { trimData } from '../../../utils/utils';
import { Message } from '../../../utils/message';
import { CnpjValidator } from '../../../validators/cnpjValidator';
import { UserClaimsModel } from '../../../models/user-claims/user-claims.model';
import { OnboardingService } from '../../../services/onboarding/onboarding.service';
import { StateManagementService } from '../../../state-management/state-management.service';
import { ActivityModel } from '../../../models/activity/activity.model';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit, OnDestroy {
  @Input() onSubmit: Subject<any>;

  public user!: UserClaimsModel;
  public formGroup!: FormGroup;
  private subscription!: Subscription;
  public activities: ActivityModel[] = [];

  constructor(
    private fb: FormBuilder,
    private $message: NzMessageService,
    private $onboarding: OnboardingService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getUser();
    this.getDashboardActivationCollection();
    this.getActivities();

    this.formGroup.valueChanges.subscribe((data) => {
      this.$notification.setOnboardingBtnDisabled(this.formGroup.invalid);
    });

    this.subscription = this.onSubmit.subscribe((v) => {
      if (v?.value) {
        if (this.formGroup.valid) {
          this.saveData();
        } else {
          Object.values(this.formGroup.controls).forEach((control) => {
            if (control.invalid) {
              control.markAsDirty();
              control.updateValueAndValidity({ onlySelf: true });
            }
          });
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private getActivities(): void {
    this.$onboarding.getActivities().subscribe((activities) => {
      this.activities = activities;
    });
  }

  private async saveData(): Promise<void> {
    this.$notification.setOnboardingBtnLoading(true);
    this.$onboarding
      .createSeller(
        this.formGroup.get('companyType').value === 'AUTONOMOUS' ? 'companyDetailsAnonymous' : 'companyDetails',
        {
          done: true,
          data: trimData(this.formGroup.value),
        }
      )
      .pipe(
        mergeMap((res) => {
          if (res?.data?.finishSellerOnboarding) {
            this.$onboarding.updateDashboardFirebase('stage', 1);
            this.$notification.setSeller(res.data.finishSellerOnboarding);
            return this.$onboarding.createContactOnHubspot(res.data.finishSellerOnboarding);
          } else {
            if (this.formGroup.get('companyType').value === 'AUTONOMOUS') {
              this.$onboarding.updateDashboardFirebase('resetCompanyDetailsAnonymous');
              this.$message.error(res.errors[0].message || Message.ERROR_REGISTER_ACCOUNT);
              return of(undefined);
            } else {
              this.$onboarding.updateDashboardFirebase('resetCompanyDetails');
              this.$message.error(Message.ERROR_REGISTER_ACCOUNT);
              return of(undefined);
            }
          }
        })
      )
      .subscribe({
        next: async (response) => {
          if (response?.status === 200) {
            await this.$onboarding.saveCompanyDetails(this.user);
          }

          this.$notification.setOnboardingBtnLoading(false);
        },
        error: (error) => {
          this.$notification.setOnboardingBtnLoading(false);
          this.$message.error(error?.message || Message.ERROR_REGISTER_ACCOUNT);
          throw new Error(error);
        },
      });
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      companyType: new FormControl('AUTONOMOUS', [Validators.required]),
      cnpj: new FormControl('', []),
      name: new FormControl('', [Validators.required]),
      businessActivity: new FormControl('', [Validators.required]),
    });

    this.getCompanyTypeValueChanges();
  }

  public getCompanyTypeValueChanges(): void {
    this.changeType();

    this.formGroup.get('companyType').valueChanges.subscribe((companyType) => {
      if (companyType === 'AUTONOMOUS') {
        this.formGroup.get('name').setValidators([Validators.required]);
        this.formGroup.get('businessActivity').setValidators([Validators.required]);
        this.formGroup.get('cnpj').clearValidators();
      } else {
        this.formGroup.get('cnpj').setValidators([Validators.required, CnpjValidator.isValid()]);
        this.formGroup.get('name').clearValidators();
        this.formGroup.get('businessActivity').clearValidators();
      }

      this.formGroup.get('name').updateValueAndValidity();
      this.formGroup.get('cnpj').updateValueAndValidity();
      this.formGroup.get('businessActivity').updateValueAndValidity();

      this.changeType();
    });
  }

  public changeType(): void {
    if (this.formGroup.get('companyType').value === 'AUTONOMOUS') {
      this.$onboarding.changeTypeSave(this.user?.user_id, 'autonomous');
    } else {
      this.$onboarding.changeTypeSave(this.user.user_id, 'company');
    }
  }

  private getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res) {
        this.user = res;
      }
    });
  }

  private getDashboardActivationCollection(): void {
    this.$notification.dashboardActivationsCollection.subscribe((collection) => {
      if (collection) {
        const companyDetails = collection.companyDetails?.data;
        const companyDetailsAnonymous = collection.companyDetailsAnonymous?.data;

        // To know if there is data on company collection.
        if (companyDetails?.cnpj) {
          this.formGroup.get('cnpj').setValue(companyDetails.cnpj);
        }

        if (companyDetailsAnonymous?.name) {
          this.formGroup.patchValue({
            name: companyDetailsAnonymous.name,
            businessActivity: companyDetailsAnonymous.businessActivity,
          });
        }
      }

      this.$notification.setOnboardingBtnDisabled(this.formGroup.invalid);
    });
  }
}
