import { OnboardingPersonalModel } from './external/onboarding-personal.model';
import { OnboardingTypeOfTradeModel } from './external/onboarding-type-of-trade.model';
import { OnboardingFunctionalityModel } from './external/onboarding-functionality.model';
import { OnboardingVerifyPhoneNumber } from './external/onboarding-verify-phone-number.model';
import { OnboardingWhereDoYouSellModel } from './external/onboarding-where-do-you-sell.model';
import { OnboardingAboutYourBusinessModel } from './external/onboarding-about-your-business.mode';

export class OnBoardingModel {
  public phoneNumber: string = '';
  public insertedAt: string = '';
  public personalData: OnboardingPersonalModel = new OnboardingPersonalModel();
  public typeOfTrade: OnboardingTypeOfTradeModel = new OnboardingTypeOfTradeModel();
  public verifyPhoneNumber: OnboardingVerifyPhoneNumber = new OnboardingVerifyPhoneNumber();
  public functionalities: OnboardingFunctionalityModel = new OnboardingFunctionalityModel();
  public whereDoYouSell: OnboardingWhereDoYouSellModel = new OnboardingWhereDoYouSellModel();
  public aboutYourBusiness: OnboardingAboutYourBusinessModel = new OnboardingAboutYourBusinessModel();
}
