import { Apollo } from 'apollo-angular';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';

import { AuthService } from '../auth-business/auth.service';
import { RENEW_ACCESS_TOKEN_MUTATION } from '../../../graphql/mutations/auth.mutations';

@Injectable()
export class CustomAuthService {
  private auth: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public auths: Observable<string> = this.auth.asObservable();

  constructor(private readonly injector: Injector) {}

  public setAuth(value: string): void {
    this.auth.next(value);
  }

  public renewToken(): Observable<any> {
    const refreshToken = sessionStorage.getItem('refreshToken');

    if (refreshToken) {
      const apollo = this.injector.get<Apollo>(Apollo);

      return apollo.mutate({
        mutation: RENEW_ACCESS_TOKEN_MUTATION,
        variables: {
          refreshToken,
        },
      });
    } else {
      const auth = this.injector.get<AuthService>(AuthService);
      auth.logout();

      return EMPTY;
    }
  }
}
