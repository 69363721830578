export class ShowFilterModel {
  public isFilterCreationDate?: boolean = false;
  public isFilterAmount?: boolean = false;
  public isFilterStatus?: boolean = false;
  public isFilterPaymentMethod?: boolean = false;
  public isFilterStore?: boolean = false;
  public isFilterCategory?: boolean = false;
  public isFilterName?: boolean = false;
  public isFilterDocument?: boolean = false;
  public isFilterEmail?: boolean = false;
  public isFilterExpirationDate?: boolean = false;
  public isFilterSchedule?: boolean = false;
  public isFilterSubscriptionId?: boolean = false;
  public isFilterOrderId?: boolean = false;
  public isFilterSellerId?: boolean = false;
  public isFilterCustomerId?: boolean = false;
  public isFilterTitle?: boolean = false;
  public isFilterStoreName?: boolean = false;
  public isFilterInstallments?: boolean = false;
  public isFilterMethod?: boolean = false;
  public isFilterId?: boolean = false;
  public isFilterRangeValue?: boolean = false;
}
