import { StateManagementService } from '../../../state-management/state-management.service';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { environment } from '@gen/environments';
import { CouponModel } from '../../../models/coupon/coupon.model';
import { ResponseModel } from '../../../models/response/response.model';
import { Apollo, Query } from 'apollo-angular';
import { LIST_COUPONS_QUERY } from '../../../graphql/queries/coupon.queries';
import {
  CREATE_COUPON_MUTATION,
  UPDATE_COUPON_MUTATION,
  DELETE_COUPON_MUTATION,
} from '../../../graphql/mutations/coupon.mutations';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  constructor(
    private readonly fireStore: AngularFirestore,
    private readonly apollo: Apollo,
    private readonly $notification: StateManagementService,
    private readonly $functions: AngularFireFunctions
  ) {}

  public getUsedCouponsByOrderId(data: { orderId: string; sellerId: string }): Observable<ResponseModel<CouponModel>> {
    return this.$functions.httpsCallable('getUsedCouponsByOrderId')(data);
  }

  public applyCoupon(data: any): Observable<ResponseModel<CouponModel>> {
    return this.$functions.httpsCallable('applyCoupon')(data);
  }

  public getCouponById(couponId: string, sellerId: string): Observable<CouponModel> | undefined {
    return this.fireStore
      .doc(`marketplaces/${environment.marketplaceId}/sellers/${sellerId}/coupons/${couponId}`)
      .valueChanges() as Observable<CouponModel>;
  }

  public getAndSetCoupon(couponId: string, sellerId: string): void {
    this.getCouponById(couponId, sellerId)?.subscribe({
      next: (coupon) => {
        this.$notification.setCoupon(coupon);
      },
      error: (error) => {
        throw new Error(error);
      },
    });
  }

  public getReason(reason: string): string {
    if (reason === '{"coupon_id":["campo obrigatório"]}') {
      return 'Cupon inexistente.';
    }

    return reason;
  }

  public createCoupon(data: CouponModel): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_COUPON_MUTATION,
      variables: data,
    });
  }

  public getCouponList(filters: any): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_COUPONS_QUERY,
      variables: { filters },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public updateCoupon(data: CouponModel): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_COUPON_MUTATION,
      variables: data,
    });
  }

  public deleteCoupon(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_COUPON_MUTATION,
      variables: { id },
    });
  }
}
