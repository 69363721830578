import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-confirmation-delete',
  templateUrl: './modal-confirmation-delete.component.html',
  styleUrls: ['./modal-confirmation-delete.component.scss'],
})
export class ModalConfirmationDeleteComponent {
  @Input() title: string;
  @Input() titleName: string;
  @Input() message: string;
  @Input() buttonMessage: string;
  @Input() isTextCenter: boolean;

  constructor(public $modalRef: NzModalRef) {}
}
