import { gql } from 'apollo-angular';

export const GET_ITEM = `
id
name
maxPriceCents
minPriceCents
type
active
updatedAt
imagesUrls
details
insertedAt
description
amountCents
seller {
  id
}
ableSmartpos
images {
  id
  imageUrl
  main
}
itemPrices {
  active
  amountCents
  id
  quantity
  itemTypes {
    amountCents
    name
    id
    imageUrls
  }
}
itemTags {
  name
  id
}
itemOptions {
  id
  name
  required
  displayName
  maxQuantity
  types {
    id
    active
    name
    amountCents
    imageUrls
  }
}
itemCategory {
  id
  name
}
`;

export const GET_ITEM_PUBLIC_V2 = `
id
name
type
updatedAt
details
insertedAt
description
itemVariants {
  id
  name
  type
  orderIndex
  itemVariantOptions {
    id
    active
    name
    orderIndex
  }
}
seller {
  id
}
images {
  description
  id
  insertedAt
  orderIndex
  updatedAt
  url
}
itemUnits {
 active
 price
 id
 stockCount
 itemVariantOptions {
  id
  orderIndex
  name
  itemVariant {
   id
   name
   orderIndex
   type
  }
 }

}
itemTags {
  name
  id
}
itemCategory {
  id
  name
}
priceRange {
  max
  min
}
`;

export const GET_ITEM_V2 = `
id
name
type
updatedAt
details
insertedAt
description
seller {
  id
}
images {
  description
  id
  insertedAt
  orderIndex
  updatedAt
  url
}
itemVariants {
  id
  name
  type
  orderIndex
  itemVariantOptions {
    id
    active
    name
    orderIndex
    itemUnitVariantOptions {
      id
      active
      images {
        description
        id
        insertedAt
        orderIndex
        updatedAt
        url
      }
      itemVariantOption {
        id
        itemVariant {
          id
        }
      }
     }
  }
}
itemUnits {
 active
 price
 id
 image {
  description
    id
    insertedAt
    orderIndex
    updatedAt
    url
 }
 stockCount
 itemUnitVariantOptions {
  active
  images {
    description
    id
    insertedAt
    orderIndex
    updatedAt
    url
  }
  itemVariantOption {
    id
    itemVariant {
      id
    }
  }
 }
 itemVariantOptions {
  id
  orderIndex
  name
  itemVariant {
   id
   name
   orderIndex
   type
  }
 }

}
itemTags {
  name
  id
}
itemCategory {
  id
  name
}
`;

export const GET_ITEM_WITHOUT_SELLER = `
id
name
maxPriceCents
minPriceCents
type
active
updatedAt
imagesUrls
details
insertedAt
description
amountCents
ableSmartpos
publicSeller {
  id
  cpf
  cnpj
  displayName
  company {
    businessName
  }
  person {
    name
  }
}
images {
  id
  imageUrl
  main
}
itemPrices {
  active
  amountCents
  id
  quantity
  itemTypes {
    amountCents
    name
    id
    imageUrls
  }
}
itemTags {
  name
  id
}
itemOptions {
  id
  name
  required
  displayName
  maxQuantity
  types {
    id
    active
    name
    amountCents
    imageUrls
  }
}
itemCategory {
  id
  name
}
`;

const GET_ITEM_QUERY = gql`
  query getItem($id: UUID4!) {
    getItem(id: $id) {
      ${GET_ITEM}
    }
  }
`;

const GET_ITEM_V2_QUERY = gql`
  query getV2Item($id: UUID4!) {
    getV2Item(id: $id) {
      ${GET_ITEM_V2}
    }
  }
`;

const GET_ITEM_IMG_UPLOAD_URL = gql`
  query getV2ItemImageUploadUrl($extension: String!) {
    getV2ItemImageUploadUrl(extension: $extension) {
      extension
      url
      filename
    }
  }
`;

const GET_ITEM_V2_PUBLIC_QUERY = gql`
  query getV2Item($id: UUID4!) {
    getV2Item(id: $id) {
      ${GET_ITEM_PUBLIC_V2}
    }
  }
`;

const GET_ITEM_WITHOUT_SELLER_QUERY = gql`
  query getItem($id: UUID4!) {
    getItem(id: $id) {
      ${GET_ITEM_WITHOUT_SELLER}
    }
  }
`;

const LIST_ITEMS_V2_QUERY = gql`
  query listV2Items($filters: FilterV2ItemsParams, $pagination: PaginationParams) {
    listV2Items(filters: $filters, pagination: $pagination) {
      ${GET_ITEM_V2}
    }
  }
`;

const LIST_ITEMS_V2_PUBLIC_QUERY = gql`
  query listV2Items($filters: FilterV2ItemsParams, $pagination: PaginationParams) {
    listV2Items(filters: $filters, pagination: $pagination) {
      ${GET_ITEM_PUBLIC_V2}
    }
  }
`;

const LIST_ITEMS_QUERY = gql`
  query ListItems($sellerId: UUID4!, $filters: FilterItemsParams, $pagination: PaginationParams) {
    listItems(sellerId: $sellerId, filters: $filters, pagination: $pagination) {
      id
      name
      maxPriceCents
      minPriceCents
      quantity
      totalQuantity
      type
      active
      details
      updatedAt
      imagesUrls
      insertedAt
      description
      amountCents
      ableSmartpos
      itemTags {
        name
        id
      }
      images {
        id
        imageUrl
        main
      }
      itemPrices {
        amountCents
        id
        quantity
        itemTypes {
          amountCents
          name
          id
          imageUrls
        }
        item {
          name
          details
          description
        }
      }
      itemOptions {
        id
        name
        required
        displayName
        maxQuantity
        types {
          id
          name
          amountCents
          imageUrls
        }
      }
      itemCategory {
        id
        name
      }
    }
  }
`;

const LIST_ITEMS_CATEGORYS_QUERY = gql`
  query listItemCategories($sellerId: UUID4, $filters: FilterItemCategoriesParams) {
    listItemCategories(sellerId: $sellerId, pagination: { perPage: 100 }, filters: $filters) {
      id
      name
      children {
        id
        name
        children {
          id
          name
        }
      }
    }
  }
`;

const LIST_ITEM_TAGS_QUERY = gql`
  query listItemTags($sellerId: UUID4!) {
    listItemTags(sellerId: $sellerId, pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }) {
      id
      name
    }
  }
`;

export {
  LIST_ITEMS_QUERY,
  GET_ITEM_QUERY,
  LIST_ITEMS_CATEGORYS_QUERY,
  LIST_ITEM_TAGS_QUERY,
  GET_ITEM_IMG_UPLOAD_URL,
  LIST_ITEMS_V2_QUERY,
  GET_ITEM_WITHOUT_SELLER_QUERY,
  GET_ITEM_V2_QUERY,
  LIST_ITEMS_V2_PUBLIC_QUERY,
  GET_ITEM_V2_PUBLIC_QUERY,
};
