import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Apollo, Mutation } from 'apollo-angular';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { mergeMap, Observable, of, retry, take, throwError } from 'rxjs';

import { environment } from '@gen/environments';
import { AddressService } from '../address/address.service';
import { AddressModel } from '../../models/address/address.model';
import { CustomerModel } from '../../models/customer/customer.model';
import { ResponseModel } from './../../models/response/response.model';
import { CreditCardModel } from '../../models/credit-card/credit-card.model';
import { CreditCardObjModel } from '../../models/credit-card/credit-card-obj.model';
import { ResponseCreditCardModel } from '../../models/credit-card/response-credit-card.model';
import { TOKENIZE_CARD_NUMBER, CREATE_CREDIT_CARD } from '../../graphql/mutations/credit-card.mutations';

@Injectable()
export class CreditCardService {
  public marketplaceId: string = environment.marketplaceId;

  constructor(
    private readonly apollo: Apollo,
    private $address: AddressService,
    private readonly afStore: AngularFirestore,
    private readonly afFunc: AngularFireFunctions
  ) {}

  public tokenizeCreditCard(cardNumber: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: TOKENIZE_CARD_NUMBER,
      variables: { cardNumber },
    });
  }

  public createNewCreditCard(card: any): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_CREDIT_CARD,
      variables: card,
    });
  }

  public tokenizeFirebase(cardNumber: string): Observable<any> {
    return this.tokenizeCreditCardFirebase({ cardNumber }).pipe(
      mergeMap((val) => {
        if (!val.body?.number_token) {
          return throwError(() => new Error('Erro ao tentar tokenizar o cartão.'));
        }

        return of(val);
      }),
      retry(3)
    );
  }

  public tokenizeCreditCardFirebase(data: any): Observable<any> {
    return this.afFunc.httpsCallable('createCreditCardToken')(data);
  }

  public getPublicCreditCard(creditCardId: string): Observable<CreditCardModel> | undefined {
    return this.afStore.doc(`creditCards/${creditCardId}`).valueChanges() as Observable<CreditCardModel>;
  }

  public getCreditCardObj(cardNumber: string): CreditCardObjModel {
    const cardBrands = [
      {
        brand: 'VISA',
        pattern: /^4/,
        pathImage: '../../../assets/images/logos_visa.svg',
      },
      {
        brand: 'MASTERCARD',
        pattern: /^5[1-5]/,
        pathImage: '../../../assets/images/logos_mastercard.svg',
      },
      {
        brand: 'DINNERS CLUB',
        pattern: /^(36|38|2149|2014|3\d{2}|305)/,
        pathImage: '../../../assets/images/logos_dinners-club.svg',
        creditCardMask: '0000 0000 0000 00',
      },
      {
        brand: 'DISCOVER',
        pattern: /^(6011|65)/,
        pathImage: '../../../assets/images/logos_discover-club.svg',
        creditCardMask: '0000 0000 0000 00',
      },
      {
        brand: 'JCB',
        pattern: /^(35|1800|2131)/,
        pathImage: '../../../assets/images/logos_jcb.svg',
      },
      {
        brand: 'AMERICAN EXPRESS',
        pattern: /^(34|37)/,
        pathImage: '../../../assets/images/logos_amex.svg',
        cvvMask: '0000',
      },
    ];

    let obj: CreditCardObjModel = {
      creditCardMask: '0000 0000 0000 0000',
      cvvMask: '000',
      pathImage: '',
      brand: '',
      last4: cardNumber.substring(0, 4),
    };

    for (const brand of cardBrands) {
      if (brand.pattern.test(cardNumber)) {
        obj.brand = brand.brand;
        obj.pathImage = brand.pathImage;
        obj.creditCardMask = brand.creditCardMask || obj.creditCardMask;
        obj.cvvMask = brand.cvvMask || obj.cvvMask;
        break;
      }
    }

    return obj;
  }

  public getCreditCards(customerId: string): Observable<CreditCardModel[]> | undefined {
    return this.afStore
      .collection(`marketplaces/${this.marketplaceId}/customers/${customerId}/creditCards`)
      .valueChanges() as Observable<CreditCardModel[]>;
  }

  public getCreditCard(customerId: string, creditCardId: string): Observable<CreditCardModel> | undefined {
    return this.afStore
      .collection(`marketplaces/${this.marketplaceId}/customers/${customerId}/creditCards`)
      .doc(creditCardId)
      .valueChanges() as Observable<CreditCardModel>;
  }

  public createCreditCardValidation(params: {
    securityCode: string;
    creditCardId: string;
  }): Observable<ResponseModel<any>> {
    return this.afFunc.httpsCallable('createCreditCardValidation')(params);
  }

  public confirmCreditCardValidation(params: {
    amountCents: number;
    creditCardId: string;
  }): Observable<ResponseModel<any>> {
    return this.afFunc.httpsCallable('confirmCreditCardValidation')(params);
  }

  public createCreditCard(params: any): Observable<ResponseModel<CreditCardModel>> {
    return this.afFunc.httpsCallable('publicCreateCreditCard')(params);
  }

  public deleteCreditCard(creditCardId: string): Observable<ResponseModel<any>> {
    return this.afFunc.httpsCallable('deleteCreditCard')(creditCardId);
  }

  public editCreditCard(
    customerId: string,
    creditCardId: string,
    data: { mainCard: boolean; cardName: string }
  ): Promise<void> {
    return this.afStore
      .collection(`marketplaces/${this.marketplaceId}/customers/${customerId}/creditCards`)
      .doc(creditCardId)
      .set(data, { merge: true });
  }

  public setCreditCardPayload(
    address: AddressModel,
    creditCardForm: FormGroup,
    customer: CustomerModel
  ): Partial<CreditCardModel> {
    return {
      last4: creditCardForm.get('creditCardNumber').value.slice(-4),
      holderName: creditCardForm.get('holderName').value,
      brand: this.getCreditCardObj(creditCardForm.get('creditCardNumber').value).brand,
      cardNumber: creditCardForm.get('creditCardNumber').value,
      expirationMonth: creditCardForm.get('expiration').value.slice(0, 2),
      expirationYear: creditCardForm.get('expiration').value.slice(2, 4),
      securityCode: creditCardForm.get('securityCode').value,
      billingAddress: address,
      customerId: customer.id,
      marketplaceId: environment.marketplaceId,
    };
  }

  public getAllCreditCards(id: string): Observable<CreditCardModel[]> | undefined {
    return this.afStore.collection(`parkingUsers/${id}/creditCards`).valueChanges() as Observable<CreditCardModel[]>;
  }

  public saveCard(creditCard: ResponseCreditCardModel): void {
    const userId = localStorage.getItem('parkingUser');
    if (userId) {
      const payload = this.makeCreditCardPayload(creditCard);
      this.afStore.collection(`parkingUsers/${userId}/creditCards`).doc(payload.id).set(payload, { merge: true });
    }
  }

  public favoriteCard(creditCard: CreditCardModel): void {
    const userId = localStorage.getItem('parkingUser');
    if (userId) {
      this.afStore
        .collection(`parkingUsers/${userId}/creditCards`, (ref) => ref.where('isFavorite', '==', true))
        .valueChanges()
        .pipe(take(1))
        .subscribe((res: any[]) => {
          if (res.length > 0) {
            this.afStore.doc(`parkingUsers/${userId}/creditCards/${res[0].id}`).update({ isFavorite: false });
          }

          this.afStore.doc(`parkingUsers/${userId}/creditCards/${creditCard.id}`).update({ isFavorite: true });
        });
    }
  }

  public deleteCard(id: string): Promise<void> {
    const userId = localStorage.getItem('parkingUser');
    return this.afStore.doc(`parkingUsers/${userId}/creditCards/${id}`).delete();
  }

  public makeCreditCardPayload(creditCard: ResponseCreditCardModel): Partial<CreditCardModel> {
    const creditCardPayload: Partial<CreditCardModel> = {
      id: creditCard.id,
      brand: creditCard.brand,
      cardNumber: creditCard.numero,
      holderName: creditCard.portador,
      last4: creditCard.numero.slice(-4),
      expirationMonth: creditCard.validade.slice(0, 2),
      expirationYear: creditCard.validade.slice(2),
      customer: {
        name: creditCard.consumidor.nome || '',
        cpf: creditCard.consumidor.cpf,
        birthDate: creditCard.consumidor.dataNascimento || '',
        email: creditCard.consumidor.email,
        phone: creditCard.consumidor.telefone,
      },
      billingAddress: {
        line1: creditCard.consumidor.endereco.logradouro,
        line2: creditCard.consumidor.endereco.numero as string,
        line3: creditCard.consumidor.endereco.complemento || '',
        postalCode: creditCard.consumidor.endereco.cep,
        neighborhood: creditCard.consumidor.endereco.bairro,
        city: creditCard.consumidor.endereco.cidade,
        state: creditCard.consumidor.endereco.uf,
      },
    };

    return creditCardPayload;
  }
}
