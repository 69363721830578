import { Subject, Subscription } from 'rxjs';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { SendEmailComponent } from './send-email/send-email.component';
import { ValidateEmailComponent } from './validate-email/validate-email.component';
import { DashboardEmailValidationModel } from '../../../models/onboarding/internal/dashboard-email-validation.mode';
import { StateManagementService } from '../../../state-management/state-management.service';

@Component({
  selector: 'app-email-validation',
  templateUrl: './email-validation.component.html',
  styleUrls: ['./email-validation.component.scss'],
})
export class EmailValidationComponent implements OnInit, OnDestroy {
  @ViewChild('sendEmail') sendEmail: SendEmailComponent;
  @ViewChild('validateEmail') validateEmail: ValidateEmailComponent;
  @Input() onSubmit: Subject<boolean>;

  public emailValidation!: DashboardEmailValidationModel;

  private subscription!: Subscription;

  constructor(private $notification: StateManagementService) {}

  public ngOnInit(): void {
    this.getDashboardActivationCollection();

    this.subscription = this.onSubmit.subscribe((v) => {
      if (v) {
        if (this.emailValidation?.data?.email) {
          this.validateEmail.saveData();
        } else {
          this.sendEmail.saveData();
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private getDashboardActivationCollection(): void {
    this.$notification.dashboardActivationsCollection.subscribe((collection) => {
      if (collection) {
        this.emailValidation = collection.emailValidation;
      }
    });
  }
}
