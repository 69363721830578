import { FooterType } from '../../types/footer-type';

export class TableSettingsModel {
  public pagination: boolean;
  public footer: boolean;
  public expandable: boolean;
  public checkbox: boolean;
  public customHeader?: boolean;
  public removeHeader?: boolean;
  public footerName?: FooterType;
}
