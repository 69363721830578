import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { Message } from '../../utils/message';
import { SellerModel } from '../../models/sellers/sellers.model';
import { HubspotService } from '../../services/hubspot/hubspot.service';
import { UserClaimsModel } from '../../models/user-claims/user-claims.model';
import { AuthService } from '../../services/auth/auth-business/auth.service';
import { NotificationsModel } from '../../models/notifications/notifications.model';
import { ModalLogoutComponent } from '../modals/modal-logout/modal-logout.component';
import { StateManagementService } from '../../state-management/state-management.service';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { environment } from '@gen/environments';

declare const window: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  public user: UserClaimsModel = {} as UserClaimsModel;
  public notifications: NotificationsModel[] = [];
  public read: NotificationsModel[] = [];
  public unRead: NotificationsModel[] = [];
  public readLimited: NotificationsModel[] = [];
  public unReadLimited: any = [];
  public seller: SellerModel = new SellerModel();
  public hideSidenav: boolean = false;
  public isPopoverNotificationVisible: boolean = false;
  public marketplaceId: string = '';
  public sellerId: string = '';

  constructor(
    private $auth: AuthService,
    private readonly route: Router,
    private $hubspot: HubspotService,
    private readonly $modal: NzModalService,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService,
    private $notificationsService: NotificationsService
  ) {}

  public ngOnInit(): void {
    this.getUser();
    this.getSeller();
    this.getSidenavStatus();
  }

  private getSidenavStatus(): void {
    this.$notification.collapsedSidenavs.subscribe((res) => {
      this.hideSidenav = res;
    });
  }

  private getSeller(): void {
    this.$notification.sellers.subscribe((res) => {
      if (res) {
        this.seller = res;
      }
    });
  }

  private configureSentry(): void {
    Sentry.configureScope((scope: any) => {
      scope.setUser({
        id: this.user?.user_id,
        username: this.user?.name,
        email: this.user?.email,
        sellerId: this.user?.sellerId,
      });
      scope.setLevel('error');
    });
  }

  private initChat(): void {
    if (this.user?.email) {
      const name = this.user?.displayName || this.user?.name || '';
      const firstName = name.split(' ').slice(0, 1).join(' ');
      const lastName = name.split(' ').slice(-1).join(' ');

      this.$hubspot.createHubspotToken(this.user.email, firstName, lastName).subscribe({
        next: (res) => {
          if (res.status === 200 && res.body?.token) {
            window.hsConversationsSettings = {
              loadImmediately: false,
              identificationEmail: this.user.email,
              identificationToken: res.body.token,
            };
          }
        },
        error: (error) => {
          this.$message.error(Message.ERROR_CONECTION);
          throw new Error(error);
        },
      });
    }
  }

  public logout(): void {
    this.$modal
      .create({
        nzContent: ModalLogoutComponent,
      })
      .afterClose.subscribe((res: boolean) => {
        if (res) {
          this.$auth.logout();
        }
      });
  }

  private getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res) {
        this.user = res;

        if (res?.marketplaceId && res?.sellerId) {
          this.marketplaceId = res.marketplaceId;
          this.sellerId = res.sellerId;
        }

        if (this.user?.sellerId) {
          if (environment.production) {
            this.initChat();
            this.configureSentry();
          }

          this.getNotifications();
        }
      }
    });
  }

  public getNotifications(): void {
    this.$notificationsService.getNotifications(this.user.marketplaceId, this.user.sellerId).subscribe({
      next: (res) => {
        if (res) {
          this.notifications = res;

          this.unRead = this.notifications.filter((notification) => notification.read === false);
          this.unReadLimited = this.unRead.slice(0, 4);
        }
      },
      error: (error) => {
        this.$message.error(Message.ERROR_SEARCHING_INFORMATION);
        throw new Error(error);
      },
    });
  }

  public async markAllAsRead(): Promise<void> {
    try {
      if (this.user?.sellerId) {
        this.unRead.map(async (res) => {
          if (!res.read) {
            await this.$notificationsService.updateNotification(
              this.user?.marketplaceId,
              this.user?.sellerId,
              res,
              true
            );
          }
        });

        this.isPopoverNotificationVisible = false;
      } else {
        this.$message.error(Message.ERROR_LOAD_DATA);
      }
    } catch (error: any) {
      this.$message.error(Message.ERROR_CONECTION);
      throw new Error(error);
    }
  }

  public redirectToProfile(): void {
    this.isPopoverNotificationVisible = false;
    this.route.navigate(['/internal/settings/profile']);
  }

  public redirectToNotifications(): void {
    this.isPopoverNotificationVisible = false;
    this.route.navigate(['/internal/settings/notifications']);
  }

  public redirectNotifications(notification: any): void {
    switch (notification.action) {
      case 'payment': {
        this.isPopoverNotificationVisible = false;
        this.route.navigate([`/internal/transactions/transaction-details/${notification.actionId}`]);

        if (!notification.read) {
          this.$notificationsService.updateNotification(this.marketplaceId, this.sellerId, notification, true);
        }

        break;
      }

      case 'subscription':
        this.isPopoverNotificationVisible = false;
        this.route.navigate([`/internal/invoices/details/subscription/${notification.actionId}`]);

        if (!notification.read) {
          this.$notificationsService.updateNotification(this.marketplaceId, this.sellerId, notification, true);
        }

        break;

      case 'order':
        this.isPopoverNotificationVisible = false;
        this.route.navigate([`/internal/orders/details/${notification.actionId}`]);

        if (!notification.read) {
          this.$notificationsService.updateNotification(this.marketplaceId, this.sellerId, notification, true);
        }

        break;

      case 'withdraw-executed':
        this.isPopoverNotificationVisible = false;
        this.route.navigate(['/internal/balances']);

        if (!notification.read) {
          this.$notificationsService.updateNotification(this.marketplaceId, this.sellerId, notification, true);
        }

        break;

      case 'withdraw-waiting':
        this.isPopoverNotificationVisible = false;
        this.route.navigate(['/internal/balances']);

        if (!notification.read) {
          this.$notificationsService.updateNotification(this.marketplaceId, this.sellerId, notification, true);
        }

        break;

      case 'withdraw-declined':
        this.isPopoverNotificationVisible = false;
        this.route.navigate(['/internal/balances']);

        if (!notification.read) {
          this.$notificationsService.updateNotification(this.marketplaceId, this.sellerId, notification, true);
        }

        break;

      case 'withdraw-approved':
        this.isPopoverNotificationVisible = false;
        this.route.navigate(['/internal/balances']);

        if (!notification.read) {
          this.$notificationsService.updateNotification(this.marketplaceId, this.sellerId, notification, true);
        }

        break;

      case 'document-approved':
        this.isPopoverNotificationVisible = false;
        this.route.navigate(['/internal/settings/documents']);

        if (!notification.read) {
          this.$notificationsService.updateNotification(this.marketplaceId, this.sellerId, notification, true);
        }

        break;

      case 'document-rejected':
        this.isPopoverNotificationVisible = false;
        this.route.navigate(['/internal/settings/documents']);

        if (!notification.read) {
          this.$notificationsService.updateNotification(this.marketplaceId, this.sellerId, notification, true);
        }

        break;

      case 'account':
      default:
        break;
    }
  }

  public toggleChat(): void {
    this.$hubspot.toggleChat();
  }
}
