import { gql } from 'apollo-angular';

const LIST_ITEM_CATEGORIES_QUERY = gql`
  query ListItemCategories($sellerId: UUID4!) {
    listItemCategories(pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }, sellerId: $sellerId) {
      id
      name
      imageUrl
      updatedAt
      insertedAt
    }
  }
`;

export { LIST_ITEM_CATEGORIES_QUERY };
