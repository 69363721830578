import { gql } from 'apollo-angular';

const CREATE_ITEM_CATEGORY_MUTATION = gql`
  mutation CreateItemCategory($sellerId: UUID4!, $name: String!) {
    createItemCategory(params: { sellerId: $sellerId, name: $name }) {
      id
    }
  }
`;

const UPDATE_ITEM_CATEGORY_MUTATION = gql`
  mutation UpdateItemCategory($id: UUID4!, $sellerId: UUID4!, $name: String, $imageUrl: String) {
    updateItemCategory(id: $id, params: { sellerId: $sellerId, name: $name, imageUrl: $imageUrl }) {
      id
    }
  }
`;

const DELETE_ITEM_CATEGORY_MUTATION = gql`
  mutation DeleteItemCategory($id: UUID4!) {
    deleteItemCategory(id: $id) {
      deletedAt
    }
  }
`;

export { CREATE_ITEM_CATEGORY_MUTATION, UPDATE_ITEM_CATEGORY_MUTATION, DELETE_ITEM_CATEGORY_MUTATION };
