import { Component, ElementRef, Input, OnInit, Output } from '@angular/core';
import { CategoryModel } from '../../../models/categories/categories.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { StateManagementService } from '../../../state-management/state-management.service';
import { Router } from '@angular/router';
import { CategoryService } from '../../../services/item/category/category.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ExpandedFilterCategorysChildrenComponent } from '../expanded-filter-categorys-children/expanded-filter-categorys-children.component';
import { NzCascaderOption } from 'ng-zorro-antd/cascader';
import { take } from 'rxjs';
@Component({
  selector: 'app-filter-categories',
  templateUrl: './filter-categories.component.html',
  styleUrls: ['./filter-categories.scss'],
})
export class FilterCategoriesComponent implements OnInit {
  @Input() public categories: Array<CategoryModel>;
  @Input() public selectedProductCategory: CategoryModel;

  public form: FormGroup;

  public clickedFavorite: boolean = false;
  public isToggle: boolean = false;
  public loading: boolean = true;

  public sellerId: string = '';
  public pointId: string = '';

  public imagesUrl = [
    '../../assets/images/shopping-cart.svg',
    '../../assets/images/clicked-heart.svg',
    '../../assets/images/unclicked-heart.svg',
  ];

  public itemCategorys: any;
  public itemCategorysLimited: NzCascaderOption[];
  public productsCategoryList: Array<CategoryModel> = [];

  constructor(
    private fb: FormBuilder,
    public $modal: NzDrawerRef,
    private $drawer: NzDrawerService,
    public route: Router,
    private $notification: StateManagementService,
    private $category: CategoryService,
    private $message: NzMessageService,
    private elementRef: ElementRef
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getNotification();
    this.getValuesChange();
  }

  private createForm(): void {
    this.form = this.fb.group({
      search: new FormControl(''),
    });
  }

  public onSelectCategory(category: any, type?: string): void {
    if (category.value) {
      this.selectedProductCategory = category;

      if (type === 'selected') {
        this.$notification.setChangedCategory(category);
        this.$modal.close();
      }
    }
  }

  public getNotification(): void {
    this.$notification.routeParams.subscribe((ids) => {
      if (ids) {
        this.pointId = ids.pointId;
        this.sellerId = ids.sellerId;
      }
    });

    this.$notification.changedCategorys.subscribe((res) => {
      if (res) {
        this.selectedProductCategory = res;
      }
    });

    this.$notification.categories.subscribe((categories) => {
      if (categories) {
        this.getCategoryList(categories);
      } else {
        this.$category.getAndSetCategoriesAll();
      }
    });
  }

  public getValuesChange(): void {
    this.form.get('search').valueChanges.subscribe((res) => {
      this.$notification.setSearchValue(res);
    });
  }

  public goToCart(): void {
    this.$modal.close();
    this.route.navigate(['/loggedCart/' + this.pointId + '/cart']);
  }

  public getCategoryList(categories: CategoryModel[]): void {
    this.$category
      .getCategoryList(this.sellerId)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (res?.data?.listItemCategories) {
            this.productsCategoryList = res.data.listItemCategories;

            this.productsCategoryList = this.productsCategoryList.map((categorie) => {
              const obj = {
                label: categorie.name,
                value: categorie.id,
                isLeaf: true,
                children: [],
              };
              return obj;
            });
          }

          let options = this.$category.setCategoriesCascader(categories);
          options = options.concat(this.productsCategoryList);

          this.productsCategoryList = options;

          this.loading = false;
        },
        error: (error) => {
          throw new Error(error);
        },
      });
  }

  public onClick(type: string): void {
    this.isToggle = !this.isToggle;

    if (this.isToggle) {
      const element = this.elementRef.nativeElement.querySelector(type);
      element.style.transform = 'rotate(90deg)';
    } else {
      const element = this.elementRef.nativeElement.querySelector(type);
      element.style.transform = 'rotate(0deg)';
    }
  }

  public openCategoriesFilter(selectedProductCategory?: CategoryModel): void {
    const width = window.innerWidth;
    let nzHeight = '100%';

    if (width > 768) {
      nzHeight = '60%';
    }

    const drawer = this.$drawer.create({
      nzClosable: false,
      nzWidth: '100%',
      nzHeight,
      nzBodyStyle: {
        'max-width': '100vm',
        'overflow-x': 'hidden',
        height: nzHeight,
        padding: '0',
      },
      nzPlacement: 'top',
      nzContentParams: { selectedProductCategory: selectedProductCategory },
      nzContent: ExpandedFilterCategorysChildrenComponent,
    });
    drawer.afterClose.subscribe((category: string) => {
      this.onSelectCategory(category, 'selected');
    });
  }
}
