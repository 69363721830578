import { gql } from 'apollo-angular';

const CREATE_USER_MUTATION = gql`
  mutation CreateUser(
    $active: Boolean
    $cpf: String
    $email: String
    $name: String
    $password: String
    $role: RoleEnum
    $phone: String
  ) {
    createUser(
      params: {
        active: $active
        cpf: $cpf
        email: $email
        name: $name
        password: $password
        role: $role
        phone: $phone
      }
    ) {
      id
    }
  }
`;

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser(
    $id: UUID4!
    $active: Boolean
    $cpf: String
    $email: String
    $name: String
    $password: String
    $role: RoleEnum
    $phone: String
  ) {
    updateUser(
      id: $id
      params: {
        active: $active
        cpf: $cpf
        email: $email
        name: $name
        password: $password
        role: $role
        phone: $phone
      }
    ) {
      id
    }
  }
`;

const CHANGE_USER_ROLE_MUTATION = gql`
  mutation ChangeUserRole($id: UUID4!, $role: RoleEnum!) {
    updateUserRole(id: $id, role: $role) {
      id
    }
  }
`;

const CREATE_SUBSELLER_MUTATION = gql`
  mutation CreateSubseller($sellerId: UUID4!, $params: CreateSubsellerUserParams!) {
    createSubseller(sellerId: $sellerId, params: $params) {
      id
    }
  }
`;

const UPDATE_SUBSELLER_MUTATION = gql`
  mutation UpdateSubseller($userSellerId: Int!, $params: CreateSubsellerUserParams!) {
    updateSubseller(userSellerId: $userSellerId, params: $params) {
      id
    }
  }
`;

const DELETE_SUBSELLER_MUTATION = gql`
  mutation DeleteSubseller($sellerId: UUID4!, $userId: UUID4!) {
    deleteSubseller(sellerId: $sellerId, userId: $userId) {
      id
    }
  }
`;

export {
  CREATE_USER_MUTATION,
  UPDATE_USER_MUTATION,
  CHANGE_USER_ROLE_MUTATION,
  CREATE_SUBSELLER_MUTATION,
  UPDATE_SUBSELLER_MUTATION,
  DELETE_SUBSELLER_MUTATION,
};
