import { AbstractControl, ValidatorFn } from '@angular/forms';

export class PasswordValidator {
  static isValid(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const minLength = 8;
      const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])/;

      if (control.value && control.value.length < minLength) {
        return { minlength: { requiredLength: minLength, actualLength: control.value.length } };
      } else if (control.value && !pattern.test(control.value)) {
        return { pattern: true };
      }
      return null;
    };
  }
}
