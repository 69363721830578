import { Component, OnInit, ViewChildren } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { UserClaimsModel } from 'libs/shared/src/lib/models/user-claims/user-claims.model';
import { DashboardModel } from 'libs/shared/src/lib/models/onboarding/dashboard.model';
import { OnboardingService } from 'libs/shared/src/lib/services/onboarding/onboarding.service';

@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.scss'],
})
export class ValidateEmailComponent implements OnInit {
  @ViewChildren('input') inputsList!: any;

  public email: string = '';
  public formGroup!: FormGroup;

  private user!: UserClaimsModel;
  private collection!: DashboardModel;

  constructor(
    private readonly fb: FormBuilder,
    private $message: NzMessageService,
    private $onboarding: OnboardingService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getUser();
    this.getDashboardActivationCollection();

    this.$notification.setOnboardingBtnDisabled(true);

    this.formGroup.valueChanges.subscribe((data) => {
      this.$notification.setOnboardingBtnDisabled(this.formGroup.invalid);
    });
  }

  public changeEmail(): void {
    const data: DashboardModel = Object.assign({}, this.collection, {
      emailValidation: { data: { email: '' } },
    } as DashboardModel);
    this.$notification.setDashboardActivationCollection(data);
  }

  public saveData(): void {
    this.$notification.setOnboardingBtnLoading(true);
    const code =
      this.formGroup.get('code1').value +
      this.formGroup.get('code2').value +
      this.formGroup.get('code3').value +
      this.formGroup.get('code4').value +
      this.formGroup.get('code5').value +
      this.formGroup.get('code6').value;

    this.$onboarding
      .validateEmailCode({
        email: this.email,
        type: 'B2B_ONBOARDING_EMAIL',
        code,
      })
      .subscribe(async (res) => {
        if (res.status === 200) {
          await this.$onboarding.saveEmailValidation(this.user, {
            done: true,
            data: {
              email: this.email,
              checkCode: code,
            },
          });
        } else {
          this.$message.error('Código inválido.');
        }

        this.$notification.setOnboardingBtnLoading(false);
      });
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      code1: new FormControl('', [Validators.required]),
      code2: new FormControl('', [Validators.required]),
      code3: new FormControl('', [Validators.required]),
      code4: new FormControl('', [Validators.required]),
      code5: new FormControl('', [Validators.required]),
      code6: new FormControl('', [Validators.required]),
    });
  }

  private getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res) {
        this.user = res;
      }
    });
  }

  private getDashboardActivationCollection(): void {
    this.$notification.dashboardActivationsCollection.subscribe((collection) => {
      if (collection) {
        this.collection = collection;
        this.email = this.collection.emailValidation?.data?.email || '';
      }
    });
  }

  public onInput(event: any, index: number): void {
    if (event.data) {
      this.inputsList._results[index].nativeElement.focus();
    }
  }

  public onPressDeleteKey(event: KeyboardEvent, index: number): void {
    if (event.key === 'Backspace') {
      const input = this.inputsList._results[index];
      if (input) {
        if (index === 5) {
          input.nativeElement.value = null;
          input.nativeElement.focus();
        }
        const previousInput = this.inputsList._results[index - 1];
        if (previousInput) {
          previousInput.nativeElement.value = null;
          previousInput.nativeElement.focus();
        }
      }
    }
  }

  public pasteNumeric(event: ClipboardEvent): void {
    if (event.clipboardData) {
      const code = event.clipboardData.getData('text').replace(/[^0-9]/g, '');

      this.formGroup.setValue({
        code1: code[0] || '',
        code2: code[1] || '',
        code3: code[2] || '',
        code4: code[3] || '',
        code5: code[4] || '',
        code6: code[5] || '',
      });

      if (code.length === 6) {
        setTimeout(() => {
          this.saveData();
          event.preventDefault();
        }, 500);
      }
    }
  }
}
