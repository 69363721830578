import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() public primaryButton: boolean = false;
  @Input() public secondaryButton: boolean = false;
  @Input() public paymentButton: boolean = false;
  @Input() public textPrimaryButton: string = '';
  @Input() public textSecondaryButton: string = '';
  @Input() public textPaymentButton: string = 'Pagar';
  @Input() public disabledPrimaryButton: boolean = false;
  @Input() public disabledPaymentButton: boolean = false;
  @Input() public disabledSecondaryButton: boolean = false;
  @Input() public totalValue: number = null;

  @Output() public primaryButtonClick = new EventEmitter();

  constructor() {}

  public callToAction(data?: any): void {
    this.primaryButtonClick.emit(data);
  }
}
