import { AddressModel } from '../address/address.model';
import { DocumentModel } from '../document/document.model';

export class CustomerModel {
  public id?: string;
  public name: string;
  public email?: string;
  public phone?: string;
  public externalId?: string;
  public marketplace_id?: string;
  public ownerId?: string;
  public cpf?: string;
  public showBill?: boolean;
  public cnpj?: string;
  public hasPaid?: boolean;
  public commandId?: string;
  public birthDate?: string;
  public documents?: DocumentModel[] = [];
  public address?: AddressModel;
  public shippingAddress?: AddressModel;
  public insertedAt?: string;
  public nameMother?: string;
  public updatedAt?: string;
  public status?: string;
  public user?: {
    insertedAt?: string;
    role?: string;
  };
  public limits?: CustomerLimits[] = [];
}

export type CustomerLimits = {
  action: {
    name: string;
  };
  functionality: {
    name: string;
  };
  category: {
    name: string;
  };
  amount: number;
  perPeriod: {
    name: string;
  };
};
