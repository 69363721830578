import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { ActivatedRoute, Router } from '@angular/router';

import { PointModel } from '../../models/points/point-of-sales.model';
import { StateManagementService } from '../../state-management/state-management.service';
import { CvvBottomSheetComponent } from '../cvv-bottom-sheet/cvv-bottom-sheet.component';
import { CheckoutFlowModalComponent } from '../checkout-flow-modal/checkout-flow-modal.component';
import { CustomerService } from '../../services/customer/customer.service';
import { CustomerModel } from '../../models/customer/customer.model';
import { CvvBottomSheetModalComponent } from '../cvv-bottom-sheet-modal/cvv-bottom-sheet-modal.component';

@Component({
  selector: 'app-checkout-steps',
  templateUrl: './checkout-steps.component.html',
  styleUrls: ['./checkout-steps.component.scss'],
})
export class CheckoutStepsComponent implements OnInit {
  public point: PointModel = new PointModel();
  public isAuthModalOpen: boolean = false;
  public loading: boolean = false;
  public customer: CustomerModel;
  public finalStep: boolean = false;
  public showCoupon: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly $modal: NzModalService,
    private readonly $drawer: NzDrawerService,
    private readonly activeRoute: ActivatedRoute,
    private $notification: StateManagementService,
    private $customer: CustomerService
  ) { }

  public ngOnInit(): void {
    this.$notification.setTitleCheckout('Checkout');
    this.getPoint();
    this.getCustomer();
    this.getFinalStep();
  }

  public getPoint(): void {
    this.$notification.points.subscribe((res) => {
      if (res) {
        this.point = res;
        if (res.customerId) {
          this.$customer.getAndSetCustomerMinimal(res.customerId);
          this.getCustomer();
        }

        if (!res.auth) {
          this.openCheckoutFlowModal();
        }

        if (res.cart.baskets.length === 1 && !res.cart.baskets[0].coupon) {
          this.showCoupon = true;
        }
      }
    });
  }

  public getCustomer(): void {
    this.$notification.customers.subscribe({
      next: (res) => {
        this.customer = res;
      },
      error: (err) => {
        throw new Error(err);
      },
    });
  }

  public openCheckoutFlowModal(): void {
    if (!this.isAuthModalOpen) {
      this.isAuthModalOpen = true;
      const height = this.getModalHeight();

      const modalRef = this.$modal.create({
        nzContent: CheckoutFlowModalComponent,
        nzComponentParams: { point: this.point },
        nzFooter: null,
        nzCentered: true,
        nzClosable: false,
        nzKeyboard: false,
        nzMaskClosable: false,
        nzWrapClassName: 'checkout-modal',
        nzBodyStyle: { padding: '0 24px 24px 24px', height, 'background-color': '#fff' },
      });

      window.addEventListener('resize', () => {
        modalRef.updateConfig({
          nzCentered: height === '100vh' ? true : false,
          nzBodyStyle: { height: this.getModalHeight() },
        });
      });
    }
  }

  private getModalHeight(): string {
    const screenWidth = window.innerWidth;
    const isMobile = screenWidth < 768; // sets the cutoff point to determine whether the device is mobile or desktop

    return isMobile ? '100vh' : 'auto';
  }

  public goToRoute(route: string): void {
    if (route === 'select-order-type') {
      this.router.navigate([`/loggedCart/${this.point.id}/${route}`]);
    }

    if (route === 'payments') {
      this.router.navigate([route], { relativeTo: this.activeRoute });
    }
  }

  public createPaymentIntent(): void {
    if (this.point.selectPaymentMethod?.method === 'CREDIT_CARD') {
      const param: any = {
        param: {
          point: this.point,
          creditCard: this.point.selectPaymentMethod?.data,
          method: this.point.selectPaymentMethod.method,
          customer: this.customer,
        },
      };

      this.$drawer
        .create({
          nzHeight: '333px',
          nzPlacement: 'bottom',
          nzContentParams: param,
          nzContent: CvvBottomSheetComponent,
        })
        .afterClose.subscribe((res) => {
          if (res) {
            this.loading = true;

            setTimeout(() => {
              this.loading = false;
              this.router.navigate(['receipt'], { relativeTo: this.activeRoute });
            }, 3000);
          }
        });
    } else {
      this.loading = true;

      setTimeout(() => {
        this.loading = false;
        this.router.navigate(['receipt'], { relativeTo: this.activeRoute });
      }, 3000);
    }
  }

  public createPaymentIntentWeb(): void {
    if (this.point.selectPaymentMethod?.method === 'CREDIT_CARD') {
      const param: any = {
        param: {
          point: this.point,
          creditCard: this.point.selectPaymentMethod?.data,
          method: this.point.selectPaymentMethod.method,
          customer: this.customer,
        },
      };

      this.$modal
        .create({
          nzComponentParams: param,
          nzContent: CvvBottomSheetModalComponent,
          nzWidth: 'auto',
          nzCentered: true,
          nzFooter: null,
          nzStyle: { padding: '48px' },
        })
        .afterClose.subscribe((res) => {
          if (res) {
            this.loading = true;

            setTimeout(() => {
              this.loading = false;
              this.router.navigate(['receipt'], { relativeTo: this.activeRoute });
            }, 3000);
          }
        });
    } else {
      this.loading = true;

      setTimeout(() => {
        this.loading = false;
        this.router.navigate(['receipt'], { relativeTo: this.activeRoute });
      }, 3000);
    }
  }

  public getFinalStep(): void {
    if (this.point.selectPaymentMethod?.method && this.point.selectedType) {
      this.finalStep = true;
    }
  }

  public updatePoint(point: PointModel): void {
    this.point = point;
    this.$notification.setPoint(point);
    this.showCoupon = false;
  }
}
