import { Injectable } from "@angular/core";
import { Apollo, Query } from 'apollo-angular';
import { Observable } from "rxjs";
import { LIST_SEGMENTATIONS_QUERY, SEGMENTATION_QUERY } from "../../graphql/queries/segmentations.queries";
import { CREATE_SEGMENTATION_MUTATION, DELETE_SEGMENTATION_MUTATION, UPDATE_SEGMENTATION_MUTATION } from "../../graphql/mutations/segmentations.mutation";

@Injectable()
export class SegmentationService {

  constructor(
    private readonly $apollo: Apollo
  ) { }


  public segmentationsList(sellerId: string, startDate?: Date): Observable<any> {
    return this.$apollo.watchQuery<Query>({
      query: LIST_SEGMENTATIONS_QUERY,
      variables: { sellerId, startDate },
      fetchPolicy: 'network-only',
    }).valueChanges
  }

  public segmentationOne(id: string): Observable<any> {
    return this.$apollo.watchQuery<Query>({
      query: SEGMENTATION_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    }).valueChanges
  }

  public createSegementation(name: string, description: string, contactIds: any, sellerId: string): Observable<any> {
    return this.$apollo.mutate({
      mutation: CREATE_SEGMENTATION_MUTATION,
      variables: { name, description, contactIds, sellerId }
    });
  }

  public updateSegmentation(id: string, name: string, description: string, contactIds: any, sellerId: string): Observable<any> {
    return this.$apollo.mutate({
      mutation: UPDATE_SEGMENTATION_MUTATION,
      variables: { id, name, description, contactIds, sellerId }
    });
  }

  public deleteteSegmentation(id: string): Observable<any> {
    return this.$apollo.mutate({
      mutation: DELETE_SEGMENTATION_MUTATION,
      variables: { id }
    });
  }

}
