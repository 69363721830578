import { gql } from 'apollo-angular';

const PAYMENT_EVENT_SUBSCRIPTION = gql`
  subscription PaymentEvent($id: UUID4!) {
    paymentEvent(id: $id) {
      id
      method
      confirmedAt
      totalCents
      status
      insertedAt
      updatedAt
      credit {
        installments
        publicCreditCard {
          brand
          last4
        }
      }
      pix {
        brcode
        expiration
      }
      bankSlip {
        bank
        barcode
        expirationDate
        printLinkPdf
      }
    }
  }
`;

export { PAYMENT_EVENT_SUBSCRIPTION };
