import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { LocationType } from '../types/location-type';
import { CartModel } from '../models/cart/cart.model';
import { MallModel } from '../models/mall/mall.model';
import { PlansModel } from '../models/plans/plans.model';
import { PriceModel } from '../models/plans/price.model';
import { StoreModel } from '../models/store/store.model';
import { OrderModel } from '../models/orders/order.model';
import { TicketModel } from '../models/ticket/ticket.model';
import { CouponModel } from '../models/coupon/coupon.model';
import { SellerModel } from '../models/sellers/sellers.model';
import { ContactModel } from '../models/contact/contact.model';
import { ItemTagModel } from '../models/itemTags/itemTag.model';
import { PaymentsModel } from '../models/payments/payments.model';
import { CustomerModel } from '../models/customer/customer.model';
import { PointModel } from '../models/points/point-of-sales.model';
import { PaymentInfo } from '../models/payments/payment-info.model';
import { DashboardModel } from '../models/onboarding/dashboard.model';
import { FilterDataModel } from '../models/filters/filter-data.model';
import { CategoryModel } from '../models/categories/categories.model';
import { StatusAlertModel } from '../models/status/status-alert.model';
import { StaticPixDataModel } from '../models/pix/staticPixData.model';
import { OnBoardingModel } from '../models/onboarding/onboarding.model';
import { CreditCardModel } from '../models/credit-card/credit-card.model';
import { RouteParamsModel } from './../models/route-params/route-params.model';
import { ChartDataModel } from '../models/reports/charts-data/charts-data.model';
import { ReturnRevisionDataModel } from '../models/pix/returnRevisionData.model';
import { LeadModel } from '../models/lead/lead.model';
import { ItemsV2Model } from '../models/items/V2/ItemV2.model';
import { ItemVariantModel } from '../models/items/V2/itemVariant.model';

@Injectable({
  providedIn: 'root',
})
export class StateManagementService {
  private isLoggedIn = new BehaviorSubject<boolean>(false);
  public isLoggedIns = this.isLoggedIn.asObservable();

  private user = new BehaviorSubject<any>(null);
  public users = this.user.asObservable();

  private lead = new BehaviorSubject<LeadModel>(null);
  public leads = this.lead.asObservable();

  private orderId = new BehaviorSubject<string>(null);
  public orderIds = this.orderId.asObservable();

  private seller = new BehaviorSubject<SellerModel>({} as SellerModel);
  public sellers = this.seller.asObservable();

  private sellerImageUrl = new BehaviorSubject<string>('');
  public sellerImageUrls = this.sellerImageUrl.asObservable();

  private customer = new BehaviorSubject<CustomerModel>({} as CustomerModel);
  public customers = this.customer.asObservable();

  private banner = new BehaviorSubject<string>(null);
  public banners = this.banner.asObservable();

  private avatar = new BehaviorSubject<string>(null);
  public avatars = this.avatar.asObservable();

  private pointId = new BehaviorSubject<string>(null);
  public pointIds = this.pointId.asObservable();

  private point = new BehaviorSubject<PointModel>(null);
  public points = this.point.asObservable();

  private cleanBasket = new BehaviorSubject<boolean>(false);
  public cleanBaskets = this.cleanBasket.asObservable();

  private coupon = new BehaviorSubject<CouponModel>({} as CouponModel);
  public coupons = this.coupon.asObservable();

  private contactDetail = new BehaviorSubject<ContactModel>({} as ContactModel);
  public contactDetails = this.contactDetail.asObservable();

  private order = new BehaviorSubject<OrderModel>({} as OrderModel);
  public orders = this.order.asObservable();

  private payment = new BehaviorSubject<PaymentsModel>({} as PaymentsModel);
  public payments = this.payment.asObservable();

  private loading = new BehaviorSubject<boolean>(null);
  public loadings = this.loading.asObservable();

  private routeParam = new BehaviorSubject<RouteParamsModel>(null);
  public routeParams = this.routeParam.asObservable();

  private typeResult = new BehaviorSubject<string>(null);
  public typeResults = this.typeResult.asObservable();

  private errorMesage = new BehaviorSubject<string>(null);
  public errorMesages = this.errorMesage.asObservable();

  private location = new BehaviorSubject<LocationType>({} as LocationType);
  public locations = this.location.asObservable();

  private plan = new BehaviorSubject<PlansModel>({} as PlansModel);
  public plans = this.plan.asObservable();

  private planDetail = new BehaviorSubject<any>({});
  public planDetails = this.planDetail.asObservable();

  private item = new BehaviorSubject<ItemsV2Model>({} as ItemsV2Model);
  public items = this.item.asObservable();

  private activeCoupon = new BehaviorSubject<string>(null);
  public activeCoupons = this.activeCoupon.asObservable();

  private selectedPrice = new BehaviorSubject<PriceModel>(null);
  public selectedPrices = this.selectedPrice.asObservable();

  private subscription = new BehaviorSubject<any>(null);
  public subscriptions = this.subscription.asObservable();

  private checkedData = new BehaviorSubject<Array<any>>([]);
  public checkedDatas = this.checkedData.asObservable();

  private changedCategory = new BehaviorSubject<CategoryModel>(null);
  public changedCategorys = this.changedCategory.asObservable();

  private searchValue = new BehaviorSubject<string>(null);
  public searchValues = this.searchValue.asObservable();

  private emailForgot = new BehaviorSubject<string>('');
  public emailsForgot = this.emailForgot.asObservable();

  private title = new BehaviorSubject<string>(null);
  public titles = this.title.asObservable();

  private chartUpdate = new BehaviorSubject<Array<ChartDataModel>>(null);
  public chartsUpdate = this.chartUpdate.asObservable();

  private phoneNumber = new BehaviorSubject<string>('');
  public phoneNumbers = this.phoneNumber.asObservable();

  private orderByItem = new BehaviorSubject<string>('');
  public orderByItems = this.orderByItem.asObservable();

  // Onboarding
  private onboardingProgress = new BehaviorSubject<number>(0);
  private onboardingBtnLoading = new BehaviorSubject<boolean>(false);
  private onboardingBtnDisabled = new BehaviorSubject<boolean>(false);
  private onboardingCollection = new BehaviorSubject<OnBoardingModel>({} as OnBoardingModel);
  private dashboardActivationCollection = new BehaviorSubject<DashboardModel>({} as DashboardModel);

  public onboardingsProgress = this.onboardingProgress.asObservable();
  public onboardingsCollection = this.onboardingCollection.asObservable();
  public onboardingsBtnLoading = this.onboardingBtnLoading.asObservable();
  public onboardingsBtnDisabled = this.onboardingBtnDisabled.asObservable();
  public dashboardActivationsCollection = this.dashboardActivationCollection.asObservable();

  private idDetail = new BehaviorSubject<string>('');
  public idDetails = this.idDetail.asObservable();

  private dashboardDate = new BehaviorSubject<Array<Date>>(null);
  public dashboardDates = this.dashboardDate.asObservable();

  private filterData = new BehaviorSubject<FilterDataModel>(new FilterDataModel());
  public filterDatas = this.filterData.asObservable();

  private filterForm = new BehaviorSubject<any>({});
  public filterForms = this.filterForm.asObservable();

  private returnPaymentData = new BehaviorSubject<ReturnRevisionDataModel>(new ReturnRevisionDataModel());
  public returnPaymentDatas = this.returnPaymentData.asObservable();

  private staticPixData = new BehaviorSubject<StaticPixDataModel>(null);
  public staticPixDatas = this.staticPixData.asObservable();

  private ticket = new BehaviorSubject<TicketModel>(null);
  public tickets = this.ticket.asObservable();

  private mall = new BehaviorSubject<MallModel>(null);
  public malls = this.mall.asObservable();

  private allowLocation = new BehaviorSubject<number>(0);
  public allowLocations = this.allowLocation.asObservable();

  private ilustration = new BehaviorSubject<string>('../../assets/svg/active-location.svg');
  public ilustrations = this.ilustration.asObservable();

  private ilustrationMessage = new BehaviorSubject<string>('Pague o estacionamento sem complicação');
  public ilustrationMessages = this.ilustrationMessage.asObservable();

  private creditCard = new BehaviorSubject<CreditCardModel>(null);
  public creditCards = this.creditCard.asObservable();

  private cart = new BehaviorSubject<CartModel>(null);
  public carts = this.cart.asObservable();

  private store = new BehaviorSubject<StoreModel>(null);
  public stores = this.store.asObservable();

  private productFilter = new BehaviorSubject<any>(null);
  public productsFilter = this.productFilter.asObservable();

  private category = new BehaviorSubject<CategoryModel[]>(null);
  public categories = this.category.asObservable();

  private itemTag = new BehaviorSubject<ItemTagModel[]>(null);
  public itemTags = this.itemTag.asObservable();

  private deleteImg = new BehaviorSubject< { indexItemVariant: number; indexItemVariantOption: number; indexImage: number }>(null);
  public deletImgs = this.deleteImg.asObservable();

  private sucessAlert = new BehaviorSubject<StatusAlertModel>(null);
  public sucessAlerts = this.sucessAlert.asObservable();

  private errorAlert = new BehaviorSubject<StatusAlertModel>(null);
  public errorAlerts = this.errorAlert.asObservable();

  private errorAlertCheckout = new BehaviorSubject<boolean>(null);
  public errorAlertCheckouts = this.errorAlertCheckout.asObservable();

  //Checkout
  private titleCheckout = new BehaviorSubject<string>('Checkout');
  public titleCheckouts = this.titleCheckout.asObservable();
  //Checkout
  private productFilterType = new BehaviorSubject<string>('ALL');
  public productFilterTypes = this.productFilterType.asObservable();

  private removeItemPriceValidation = new BehaviorSubject<boolean>(false);
  public removeItemPriceValidations = this.removeItemPriceValidation.asObservable();

  private isShowCategory = new BehaviorSubject<boolean>(true);
  public isShowCategories = this.isShowCategory.asObservable();

  private paymentInfo = new BehaviorSubject<PaymentInfo>(null);
  public paymentInfos = this.paymentInfo.asObservable();

  private formValid = new BehaviorSubject<boolean>(null);
  public formValids = this.formValid.asObservable();

  private backCreditCard = new BehaviorSubject<boolean>(null);
  public backCreditCards = this.backCreditCard.asObservable();

  private isFormAddress = new BehaviorSubject<boolean>(false);
  public isFormAddresses = this.isFormAddress.asObservable();

  private goToNextStepCheckout = new BehaviorSubject<string>(null);
  public goToNextStepCheckouts = this.goToNextStepCheckout.asObservable();
  private collapsedSidenav = new BehaviorSubject<boolean>(false);
  public collapsedSidenavs = this.collapsedSidenav.asObservable();

  public isClone = new BehaviorSubject<boolean>(null);
  public isClones = this.isClone.asObservable();

  public itemVariant =  new BehaviorSubject<ItemVariantModel[]>(null);
  public itemVariants = this.itemVariant.asObservable();

  public setCollapsedSidenav(value: boolean): void {
    this.collapsedSidenav.next(value);
  }

  public setIsClone(value: boolean): void {
    this.isClone.next(value);

    setTimeout(() => {
      this.isClone.next(false);
    }, 500);
  }

  public setRemoveItemPriceValidations(value: boolean): void {
    this.removeItemPriceValidation.next(value);

    setTimeout(() => {
      this.removeItemPriceValidation.next(false);
    }, 500);
  }

  public setFormValid(value: boolean): void {
    this.formValid.next(value);
  }

  public setIsFormAddress(value: boolean): void {
    this.isFormAddress.next(value);
  }

  public setBackCreditCard(value: boolean): void {
    this.backCreditCard.next(value);
  }

  public setGoToNextStepCheckout(value: string): void {
    this.goToNextStepCheckout.next(value);

    setTimeout(() => {
      this.goToNextStepCheckout.next(null);
    }, 200);
  }

  public setSellerImageUrl(value: string): void {
    this.sellerImageUrl.next(value);
  }

  public setPaymentInfo(value: PaymentInfo): void {
    this.paymentInfo.next(value);
  }

  public setPointId(id: string): void {
    this.pointId.next(id);
  }

  public setPoint(point: PointModel): void {
    this.point.next(point);
  }

  public setCleanBasket(value: boolean): void {
    this.cleanBasket.next(value);
  }

  public setIsLoggedIn(value: boolean): void {
    this.isLoggedIn.next(value);
  }

  public setCoupon(value: CouponModel): void {
    this.coupon.next(value);
  }

  public setContactDetail(value: ContactModel): void {
    this.contactDetail.next(value);
  }

  public setOrder(value: OrderModel): void {
    this.order.next(value);
  }

  public setTypeResult(value: string): void {
    this.typeResult.next(value);

    setTimeout(() => {
      this.typeResult.next(null);
    }, 500);
  }

  public setErrorMesage(value: string): void {
    this.errorMesage.next(value);
  }

  public setLocation(value: LocationType): void {
    this.location.next(value);
  }

  public setPlan(value: PlansModel): void {
    this.plan.next(value);
  }

  public setPayment(value: PaymentsModel): void {
    this.payment.next(value);
  }

  public setPlanDetail(value: any): void {
    this.planDetail.next(value);
  }

  public setItem(value: ItemsV2Model): void {
    this.item.next(value);
  }

  public setActiveCoupon(value: string): void {
    this.activeCoupon.next(value);
  }

  public setSelectedPrice(value: PriceModel): void {
    this.selectedPrice.next(value);
  }

  public setSubscription(value: any): void {
    this.subscription.next(value);
  }

  public setLoading(value: boolean): void {
    this.loading.next(value);
  }

  public setUser(value: any): void {
    this.user.next(value);
  }

  public setLead(value: LeadModel): void {
    this.lead.next(value);
  }

  public setCustomer(value: CustomerModel): void {
    this.customer.next(value);
  }

  public setRouteParams(value: RouteParamsModel): void {
    this.routeParam.next(value);
  }

  public setOrderId(value: string): void {
    this.orderId.next(value);
  }

  public setBanner(value: string): void {
    this.banner.next(value);
  }

  public setAvatar(value: string): void {
    this.avatar.next(value);
  }

  public setSeller(value: SellerModel): void {
    this.seller.next(value);
  }

  public setCheckedData(value: Array<any>): void {
    this.checkedData.next(value);
  }

  public setChangedCategory(value: CategoryModel): void {
    this.changedCategory.next(value);
  }

  public setIsCategory(value: boolean): void {
    this.isShowCategory.next(value);
  }

  public setSearchValue(value: string): void {
    this.searchValue.next(value);
  }

  public setEmailForgot(value: string): void {
    this.emailForgot.next(value);
  }

  public setChartUpdate(value: Array<ChartDataModel>): void {
    this.chartUpdate.next(value);
  }

  public setPhoneNumber(value: string): void {
    this.phoneNumber.next(value);
  }

  public setTitle(value: string): void {
    this.title.next(value);
  }

  // Onboarding
  public setOnboardingCollection(value: OnBoardingModel): void {
    this.onboardingCollection.next(value);
  }

  public setDashboardActivationCollection(value: DashboardModel): void {
    this.dashboardActivationCollection.next(value);
  }

  public setOnboardingProgress(value: number): void {
    this.onboardingProgress.next(value);
  }

  public setOnboardingBtnLoading(value: boolean): void {
    this.onboardingBtnLoading.next(value);
  }

  public setOnboardingBtnDisabled(value: boolean): void {
    this.onboardingBtnDisabled.next(value);
  }

  public setIdDetails(value: any): void {
    this.idDetail.next(value);
  }

  public setDashboardDates(value: Array<Date>): void {
    this.dashboardDate.next(value);
  }

  public setFilterData(value: FilterDataModel): void {
    this.filterData.next(value);
  }

  public setFilterForm(value: any): void {
    this.filterForm.next(value);
  }

  //PIX
  public setReturnPaymentData(value: ReturnRevisionDataModel): void {
    this.returnPaymentData.next(value);
  }

  public setStaticPixData(value: StaticPixDataModel): void {
    this.staticPixData.next(value);
  }

  public setTicket(value: TicketModel): void {
    this.ticket.next(value);
  }

  public setMall(value: MallModel): void {
    this.mall.next(value);
  }

  public setAllowLocation(value: number): void {
    this.allowLocation.next(value);
  }

  public setIlustration(value: string): void {
    this.ilustration.next(value);
  }

  public setIlustrationMessage(value: string): void {
    this.ilustrationMessage.next(value);
  }

  public setCreditCard(value: CreditCardModel): void {
    this.creditCard.next(value);
  }

  public setCart(value: CartModel): void {
    this.cart.next(value);
  }

  public setStore(value: StoreModel): void {
    this.store.next(value);
  }

  public setCategoriesList(value: CategoryModel[]): void {
    this.category.next(value);
  }

  public setITemTagsList(value: ItemTagModel[]): void {
    this.itemTag.next(value);
  }

  public setDeleteImgs(value: { indexItemVariant: number; indexItemVariantOption: number; indexImage: number }): void {
    this.deleteImg.next(value);
  }

  public setSucessAlert(value: StatusAlertModel): void {
    this.sucessAlert.next(value);
  }

  public setErrorAlert(value: StatusAlertModel): void {
    this.errorAlert.next(value);
  }

  public setErrorAlertCheckout(value: boolean): void {
    this.errorAlertCheckout.next(value);

    setTimeout(() => {
      this.errorAlertCheckout.next(false);
    }, 3000);
  }
  //Checkout
  public setTitleCheckout(value: string): void {
    this.titleCheckout.next(value);
  }

  //Store
  public setProductFilter(value: any): void {
    this.productFilter.next(value);
  }

  public setOrderBy(value: string): void {
    this.orderByItem.next(value);
  }

  public setProductFilterType(value: string): void {
    this.productFilterType.next(value);
  }

  public setUpdateItemVariant(value: ItemVariantModel[]): void {
    this.itemVariant.next(value);

    setTimeout(() => {
      this.itemVariant.next(null);
    }, 500);
  }
}
