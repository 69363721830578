import { AddressModel } from '../address/address.model';
import { CustomerModel } from '../customer/customer.model';

export class CreditCardModel {
  public id?: string;
  public marketplaceId?: string;
  public holderName?: string;
  public brand?: string;
  public last4?: string;
  public inUse?: boolean;
  public cardNumber?: string;
  public token?: string;
  public cardName?: string;
  public mainCard?: string;
  public expirationMonth?: string;
  public securityCode?: string;
  public expirationYear?: string;
  public customerId?: string;
  public billingAddress?: AddressModel;
  public creditCardId?: string;
  public insertedAt?: string;
  public updatedAt?: string;
  public customer?: Partial<CustomerModel>;
  public isFavorite?: boolean;
  public consumidor?: CustomerModel;
  public document?: string;
  public numero?: string;
  public portador?: string;
  public validade?: string;
  public codigoSeguranca?: string;
  public name?: string;
  public expirationDate?: string;
}
