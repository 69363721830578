import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { environment } from '@gen/environments';
import { ResponseModel } from '../../models/response/response.model';

declare const window: any;

@Injectable()
export class HubspotService {
  public isChatOpen: boolean = false;

  constructor(private $functions: AngularFireFunctions) {}

  public toggleChat(): void {
    if (environment.production) {
      window.HubSpotConversations.widget.load();

      if (this.isChatOpen) {
        window.HubSpotConversations.widget.remove();
      } else {
        window.HubSpotConversations.widget.open();
      }

      this.isChatOpen = !this.isChatOpen;
    }
  }

  public createHubspotToken(email: string, firstName: string, lastName: string): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('createHubspotToken')({ email, firstName, lastName });
  }
}
