import { Component, Input } from "@angular/core";
import { PointModel } from "../../models/points/point-of-sales.model";
import { AuthService } from "../../services/auth/auth-business/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-checkout-footer',
  templateUrl: './checkout-footer.component.html',
  styleUrls: ['./checkout-footer.component.scss']
})

export class CheckoutFooterComponent {
  @Input() public customerName: string;
  @Input() public point: PointModel

  constructor(private $auth: AuthService, private router: Router) { }

  public logout(): void {
    this.$auth.logout();
    delete this.point.auth;
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });

  }
}