import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-tag',
  templateUrl: './status-tag.component.html',
  styleUrls: ['./status-tag.component.scss'],
})
export class StatusTagComponent implements OnInit {
  @Input() public type: string = '';
  @Input() public gender: string = 'male';
  @Input() public page: string = '';

  private listOfTypes: any = {
    PRE_AUTHORIZED: 'PRÉ APROVADO', // Payment
    WAITING: 'AGUARDANDO', // Payment && Subscription
    WAITING_SETTLEMENT: 'AGUARDANDO LIQUIDAÇÃO', // Extracts
    IN_ANALYSIS: 'EM ANÁLISE', // Payment && Seller
    AVAILABLE: 'DISPONÍVEL', // Point of sales
    UNAVAILABLE: 'INDISPONÍVEL', // Point of sales
    IN_PROGRESS: 'EM PROGRESSO', // CUSTOMERS
    PENDING: 'PENDENTE', // CUSTOMERS DOCUMENT
    REPROCESS_REVIEW: 'REVISÃO DE PROCESSO', // CUSTOMERS
    IN_DISPUTE: 'EM DISPUTA', // Payment
    LOST_DISPUTE: 'DISPUTA PERDIDA', // Payment
    REFUNDED: 'REEMBOLSADO',
    PARTIALLY_REFUNDED: 'REEEMBOLSADO PARCIALMENTE',
    WON_DISPUTE: 'DISPUTA GANHA', // Payment
    IN_PREPARATION: 'EM PREPARO', // Kitchen
    IN_QUEUE: 'NA FILA', // Kitchen
    PENDING_DOC: 'PENDENTE', // Document
    PENDING_VERIFICATION_DOC: 'VERIFICAÇÃO PENDENTE', // Document
    INPUTS: 'Entradas', // PIX
    OUTPUTS: 'Saídas', // PIX
    IN_COMPLIANCE_REVIEW: 'REVISÃO COMPLIANCE', // SELLERS && CUSTOMERS
    PENDING_IN_GETNET: 'PENDENTE GETNET', // SELLERS && CUSTOMERS
    FAILED_IN_GETNET: 'FALHA GETNET', // SELLERS && CUSTOMERS
    FAILED: 'FALHA', // SELLERS && CUSTOMERS
    SOON: 'Em breve!', // BNPL
    DELIVERED: 'ENTREGUE', // ORDER
    IN_ROUTE: 'EM ROTA', // ORDER
    IN_SEPARATION: 'EM SEPARAÇÃO', // ORDER
    RETURNING: 'CANCELANDO', // ORDER
    RECEIVABLE: 'À receber', // ORDER && PAYMENT
    REQUESTED_CANCEL: 'Cancelamento solicitado', // ORDER && PAYMENT
    ONGOING: 'Em andamento', // ORDER
    PENDING_DELIVERY: 'Entrega pendente', // ORDER
    DOC_PENDING: 'Verificação pendente', // DOCS
    DOC_VERIFIED: 'Verificado', // DOCS
    DOC_REJECTED: 'Negado', // DOCS
    COMPLETE: 'Completo', // Contacts
    INCOMPLETE: 'Incompleto', // Contacts
  };
  private listOfTypesExtract: any = {
    SETTLED: 'Liquidada',
    APPROVED: 'Aprovada',
    PAID: 'Pago',
    WAITING: 'Aguardando',
    PENDING: 'Pendente',
    PENDING_PAYMENT: 'Aguardando Pagamento',
    TIMEOUT: 'Timeout',
    UNDONE: 'Desfeita',
    INEXISTENT: 'Inexistente',
    DENIED_ADMINISTRATOR: 'Negado pela administradora',
    REVERSAL: 'Estornada',
    REPEATED: 'Repetida',
    REFUNDED: 'REEMBOLSADO',
    PARTIALLY_REFUNDED: 'REEEMBOLSADO PARCIALMENTE',
    REVERSAL_CONCILIATION: 'Conciliação',
    CANCELED_WITHOUT_CONFIRMATION: 'Cancelada',
    DENIED_MGM: 'Negada',
    UNKNOW: 'Desconhecido',
  };
  private listMale: any = {
    CREATED: 'CRIADO', // Payment && Order
    AUTHORIZED: 'APROVADO', // Payment
    PAID: 'PAGO', // Payment && Order
    CANCELLED: 'CANCELADO', // Payment
    REFUNDED: 'REEMBOLSADO', // Payment
    REVERSED: 'ESTORNADO', // Payment
    REVERTED: 'REVERTIDO', // Payment
    SETTLED: 'CONCLUÍDO', // Extracts
    ACTIVE: 'ATIVO', // Seller && Subscription
    FAILING: 'FALHO', // Payment && Subscription
    PARTIALLY_REFUNDED: 'REEEMBOLSADO PARCIALMENTE',
    INACTIVE: 'INATIVO', // Subscription
    OCCUPIED: 'OCUPADO', // Point of sales
    DEACTIVATE: 'DESATIVADO', // Subscription
    NOT_PAID: 'CANCELADO', // Payment
    PARTIALLY_PAID: 'PAGO PARCIALMENTE', // Payment
    VALID: 'VALIDADO', // email and phone
    NOT_VALID: 'NÃO VALIDADO', // email and phone
    DISABLED: 'DESATIVADO', // plans
    RECOVERY: 'RECUPERADO', // Kitchen
    CANCELED: 'Cancelado', // Kitchen
    CONCLUDED: 'CONCLUÍDO', // Kitchen
    FINISHED: 'FINALIZADO', // Kitchen
    REJECTED: 'RECUSADO', // Document
    VERIFIED: 'VERIFICADO', // Document
    REGISTER: 'CADASTRADO',
    BANNED: 'BANIDO', // CUSTOMERS && SELLERS
    BLOCKED: 'BLOQUEADO', // CUSTOMERS && SELLERS
    DECLINED: 'RECUSADO', // CUSTOMERS
    APPROVED: 'APROVADO', // WITHDRAW
    EXECUTED: 'EXECUTADO', // WITHDRAW
    ACCEPTED: 'ACEITO', //Cancellments
    NEW: 'NOVO', // SELLERS && CUSTOMERS
    DENIED: 'NEGADO', // BNPL
    EXPIRED: 'EXPIRADO', // ORDER
    RETURNED: 'CANCELADO', // ORDER
    HIRED: 'Contratado', // ORDER
  };
  private listFemale: any = {
    CREATED: 'CRIADA', // Payment && Order
    AUTHORIZED: 'APROVADA', // Payment
    PAID: 'PAGA', // Payment && Order
    CANCELLED: 'CANCELADA', // Payment
    REFUNDED: 'REEMBOLSADA', // Payment
    REVERSED: 'ESTORNADA', // Payment
    REVERTED: 'REVERTIDA', // Payment
    SETTLED: 'CONCLUÍDA', // Extracts
    ACTIVE: 'ATIVA', // Seller && Subscription
    FAILING: 'FALHA', // Payment && Subscription
    INACTIVE: 'INATIVA', // Subscription
    OCCUPIED: 'OCUPADA', // Point of sales
    DEACTIVATE: 'DESATIVADA', // Subscription
    NOT_PAID: 'CANCELADA', // Payment
    PARTIALLY_PAID: 'PAGA PARCIALMENTE', // Payment
    VALID: 'VALIDADA', // email and phone
    NOT_VALID: 'NÃO VALIDADA', // email and phone
    DISABLED: 'DESATIVADA', // plans
    RECOVERY: 'RECUPERADA', // Kitchen
    CANCELED: 'Cancelada', // Kitchen
    CONCLUDED: 'CONCLUÍDA', // Kitchen
    FINISHED: 'FINALIZADA', // Kitchen
    REJECTED: 'RECUSADA', // Document
    VERIFIED: 'VERIFICADA', // Document
    REGISTER: 'CADASTRADA',
    BANNED: 'BANIDA', // CUSTOMERS && SELLERS
    BLOCKED: 'BLOQUEADA', // CUSTOMERS && SELLERS
    DECLINED: 'RECUSADA', // CUSTOMERS
    APPROVED: 'APROVADA', // CUSTOMERS DOCUMENT
    EXECUTED: 'EXECUTADA', // WITHDRAW
    ACCEPTED: 'ACEITA', //Cancellments
    NEW: 'NOVA', // SELLERS && CUSTOMERS
    DENIED: 'NEGADA', // BNPL
    EXPIRED: 'EXPIRADA', // ORDER
    PARTIALLY_REFUNDED: 'REEEMBOLSADA PARCIALMENTE',
    RETURNED: 'CANCELADA', // ORDER
    HIRED: 'Contratada', // ORDER
  };
  public status: string = '';
  public class: string = '';

  constructor() {}

  public ngOnInit(): void {
    this.status = this.getType();
    this.class = this.getClasses();
  }

  private getClasses(): string {
    const classes: any = {
      PRE_AUTHORIZED: 'gold',
      WAITING: 'gold',
      WAITING_SETTLEMENT: 'orange',
      IN_ANALYSIS: 'volcano',
      AVAILABLE: '#34aa44',
      UNAVAILABLE: 'default',
      PENDING: 'gold',
      IN_DISPUTE: 'volcano',
      LOST_DISPUTE: 'red',
      WON_DISPUTE: 'green',
      IN_PREPARATION: 'orange',
      IN_QUEUE: '#f4edff',
      PENDING_DOC: '#fe4a4a',
      PENDING_VERIFICATION_DOC: '#ff8a00',
      CREATED: 'magenta',
      AUTHORIZED: 'green',
      PAID: 'green',
      CANCELLED: 'red',
      REFUNDED: 'red',
      PARTIALLY_REFUNDED: 'orange',
      REVERSED: 'red',
      REVERTED: 'red',
      SETTLED: 'magenta',
      ACTIVE: 'green',
      FAILING: 'red',
      INACTIVE: 'default',
      OCCUPIED: '#6d54fc',
      DEACTIVATE: 'default',
      NOT_PAID: 'red',
      PARTIALLY_PAID: 'geekblue',
      VALID: 'green',
      NOT_VALID: 'red',
      DISABLED: 'default',
      RECOVERY: 'volcano',
      CANCELED: '#F1F1F1',
      CONCLUDED: 'green',
      FINISHED: 'volcano',
      REJECTED: '#fe4a4a',
      VERIFIED: '#34aa44',
      INPUTS: 'green',
      OUTPUTS: 'red',
      EXECUTED: 'green',
      REGISTER: 'green',
      APPROVED: 'green',
      IN_PROGRESS: 'blue',
      BANNED: 'red',
      BLOCKED: 'red',
      REPROCESS_REVIEW: 'orange',
      DECLINED: 'red',
      ACCEPTED: 'green',
      IN_COMPLIANCE_REVIEW: '#333',
      PENDING_IN_GETNET: 'gold',
      FAILED_IN_GETNET: 'red',
      FAILED: 'red',
      NEW: 'blue',
      SOON: 'green',
      DENIED: 'red',
      DELIVERED: 'green',
      EXPIRED: 'default',
      IN_ROUTE: 'blue',
      IN_SEPARATION: 'blue',
      RETURNED: 'red',
      RETURNING: 'orange',
      RECEIVABLE: 'blue',
      REQUESTED_CANCEL: 'gold',
      ONGOING: 'gold',
      HIRED: 'green',
      PENDING_DELIVERY: 'gold',
      DOC_PENDING: 'geekblue',
      DOC_VERIFIED: 'green',
      DOC_REJECTED: 'red',
      COMPLETE: 'green',
      INCOMPLETE: 'gold',
    };
    return classes[this.type?.toUpperCase()];
  }

  private getType(): string {
    if (this.gender === 'male') {
      const list = Object.assign({}, this.listOfTypes, this.listMale);
      return list[this.type?.toUpperCase()];
    }

    if (this.page === 'extract' && this.gender === 'extract') {
      const list = Object.assign({}, this.listOfTypes, this.listOfTypesExtract);
      return list[this.type?.toUpperCase()];
    }

    const list = Object.assign({}, this.listOfTypes, this.listFemale);
    return list[this.type?.toUpperCase()];
  }
}
