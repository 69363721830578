import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { environment } from '@gen/environments';
import { UtilsService } from '../utils/utils.service';
import { ParkingUserModel } from '../../models/parking-user/parking-user';
import { UserParkingModel } from '../../models/parking/user-parking-model';
import { ResponseParkingModel } from '../../models/parking/response-parking.model';
import { OperacionalParkingModel } from '../../models/parking/operacional-parking.model';
import { OperacionalRequestParkingModel } from '../../models/parking/operacional-request-parking.model';

@Injectable()
export class ParkingService {
  constructor(private $utils: UtilsService, private readonly afStore: AngularFirestore) {}

  public getUser(id: string): Observable<ParkingUserModel> | undefined {
    return this.afStore.doc(`parkingUsers/${id}`).valueChanges() as Observable<ParkingUserModel>;
  }

  public setUser(data: ParkingUserModel): void {
    this.afStore.doc(`parkingUsers/${data.id}`).set(data, { merge: true });
  }

  public getUserByCpf(cpf: string, mallId: number): Observable<ParkingUserModel[]> | undefined {
    return this.afStore
      .collection(`parkingUsers`, (ref) => ref.where('cpf', '==', cpf).where('mallId', '==', mallId))
      .valueChanges() as Observable<ParkingUserModel[]>;
  }

  public getUserLatestReceipt(id: string): Observable<any> {
    return this.afStore
      .collection(`parkingUsers/${id}/receipts`, (ref) => ref.orderBy('createdAt', 'desc'))
      .valueChanges();
  }

  public getTicketOfTransactions(
    data: OperacionalRequestParkingModel
  ): Observable<ResponseParkingModel<OperacionalParkingModel>> {
    const options = this.prepareOptions(data);
    return this.$utils.httpRequest('GET', `${environment.parkingApi}/ticket/transactions`, options);
  }

  public getOperationalData(data: OperacionalRequestParkingModel): Observable<any> {
    const options = this.prepareOptions(data);
    return this.$utils.httpRequest('GET', `${environment.parkingApi}/ticket/transactions/percentage`, options);
  }

  public getTicketOfFinancialTransactions(
    data: OperacionalRequestParkingModel
  ): Observable<ResponseParkingModel<OperacionalParkingModel>> {
    const options = this.prepareOptions(data);
    return this.$utils.httpRequest('GET', `${environment.parkingApi}/ticket/transactions/financial`, options);
  }

  public getFinancialData(data: OperacionalRequestParkingModel): Observable<any> {
    const options = this.prepareOptions(data);
    return this.$utils.httpRequest(
      'GET',
      `${environment.parkingApi}/ticket/transactions/financial/percentage`,
      options
    );
  }

  public createUserParking(body: UserParkingModel): Observable<any> {
    return this.$utils.httpRequest('POST', `${environment.parkingApi}/user`, { body });
  }

  private prepareOptions(data: OperacionalRequestParkingModel): any {
    const parkingToken = sessionStorage.getItem('parkingToken');
    const headers = new HttpHeaders().append('Authorization', `Bearer ${parkingToken}`);
    let params = new HttpParams().append('startDate', data.startDate).append('endDate', data.endDate);

    if (data.mallId) {
      params = params.append('id_mall', data.mallId);
    }

    if (data.page) {
      params = params.append('page', data.page).append('perPage', data.perPage);
    }

    return {
      params,
      headers,
    };
  }
}
