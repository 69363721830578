import { gql } from 'apollo-angular';

const CREATE_LEAD_MUTATION = gql`
  mutation CreateLead($lead: CreateLeadsInput!) {
    createLead(lead: $lead) {
      id
    }
  }
`;

const LEAD_SEND_SMS_MUTATION = gql`
  mutation SendAuthLead($phoneNumber: String!) {
    sendAuthLead(phoneNumber: $phoneNumber)
  }
`;

const LEAD_VERIFY_AUTH_CODE_MUTATION = gql`
  mutation VerifyAuthCodeLead($phoneNumber: String!, $code: String!) {
    verifyAuthCodeLead(phoneNumber: $phoneNumber, code: $code) {
      accessToken
    }
  }
`;

const RESCUE_LEAD_COUPON_MUTATION = gql`
  mutation LinkToCouponToLead($couponId: ID!) {
    linkToCouponToLead(couponId: $couponId) {
      id
    }
  }
`;

const LEAD_LIKE_COUPON_MUTATION = gql`
  mutation AddLeadLikeCoupon($couponId: String!) {
    addLeadLikeCoupon(couponId: $couponId)
  }
`;

const LEAD_UNLIKE_COUPON_MUTATION = gql`
  mutation RemoveLeadLikeCoupon($couponId: String!) {
    removeLeadLikeCoupon(couponId: $couponId)
  }
`;

const SHARE_COUPON_MUTATION = gql`
  mutation ShareCoupon($couponId: String!) {
    shareCoupon(couponId: $couponId)
  }
`;

const CREATE_COUPON_AVALIATION_MUTATION = gql`
  mutation CeateCouponAvaliation(
    $rating: Float!
    $couponId: String!
    $origin: OriginType
    $message: String
    $leadId: String
  ) {
    createCouponAvaliation(rating: $rating, couponId: $couponId, origin: $origin, message: $message, leadId: $leadId) {
      id
    }
  }
`;

export {
  CREATE_LEAD_MUTATION,
  SHARE_COUPON_MUTATION,
  LEAD_SEND_SMS_MUTATION,
  LEAD_LIKE_COUPON_MUTATION,
  RESCUE_LEAD_COUPON_MUTATION,
  LEAD_UNLIKE_COUPON_MUTATION,
  LEAD_VERIFY_AUTH_CODE_MUTATION,
  CREATE_COUPON_AVALIATION_MUTATION,
};
