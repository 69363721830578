import * as Sentry from "@sentry/angular-ivy";
import { ErrorHandler, Injectable } from '@angular/core';

import { environment } from '@gen/environments';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}

  handleError(error: any): void {
    if (environment.production) {
      Sentry.captureException(error);
    } else {
      console.error('Internal Error: ', error);
    }
  }
}
