export class PersonalDataModel {
  public name: string = '';
  public cpf: string = '';
  public phoneNumber: string = '';
  public neighborhood: string = '';
  public postalCode: string = '';
  public city: string = '';
  public state: string = '';
  public street: string = '';
  public number: string = '';
  public complement?: string = '';
}
