import { CouponModel } from '../coupon/coupon.model';
import { ItemsV2Model } from '../items/V2/ItemV2.model';
import { OrderModel } from '../orders/order.model';
import { SellerModel } from '../sellers/sellers.model';
import { StoreModel } from '../store/store.model';

export class BasketModel {
  public id: string;
  public sellerId: string;
  public seller?: SellerModel;
  public store: StoreModel;
  public order?: OrderModel;
  public cartId: string;
  public pointId: string;
  public orderId?: string;
  public needUpdate?: boolean;
  public items: Array<ItemsV2Model>;
  public totalValue?: number;
  public installments?: number;
  public coupon?: CouponModel;
}
