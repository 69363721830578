import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hidePartialCNPJ',
})
export class HidePartialCNPJPipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return '';
    }

    const cnpj = value.toString().replace(/[^\d]/g, '');
    const hidden = '**.' + cnpj.substr(2, 3) + '.' + cnpj.substr(5, 3) + '.' + cnpj.substr(8, 4) + '-' + cnpj.substr(12, 2);
    return hidden;
  }
}
