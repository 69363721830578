import { Component, Input, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { interval, Observable, Subject, takeUntil } from 'rxjs';
import { CreateAccountModel } from '../../../models/create-account/create-account.model';
import { AuthService } from '../../../services/auth/auth-business/auth.service';

@Component({
  selector: 'app-validation-phone',
  templateUrl: './validation-phone.component.html',
  styleUrls: ['./validation-phone.component.scss'],
})
export class ValidationPhoneComponent implements OnInit {
  @ViewChildren('input') inputsList!: any;
  @Input() data: any;

  public timer: Observable<number>;
  public ispause = new Subject();
  public formCode: FormGroup;
  public createAccountPaylod: CreateAccountModel;
  public countdown: number = 60;
  public loading: boolean = false;

  constructor(private fb: FormBuilder, private $auth: AuthService, private $message: NzMessageService) {}

  ngOnInit(): void {
    this.createForm();
    this.resendSmsCode();
    this.getTimer();
  }

  public createForm(): void {
    this.formCode = this.fb.group({
      code1: ['', Validators.required],
      code2: ['', Validators.required],
      code3: ['', Validators.required],
      code4: ['', Validators.required],
      code5: ['', Validators.required],
      code6: ['', Validators.required],
    });
  }

  public getTimer(): void {
    this.timer = interval(1000).pipe(takeUntil(this.ispause));
    this.timer.subscribe({
      next: (_: number) => {
        if (this.countdown === 0) {
          this.ispause.next;
        }
        this.countdown--;
      },
    });
  }

  public onInput(event: any, index: number): void {
    if (event.data) {
      this.inputsList._results[index].nativeElement.focus();
    }
  }

  public onPressDeleteKey(event: KeyboardEvent, index: number): void {
    if (event.key === 'Backspace') {
      this.inputsList._results[index].nativeElement.focus();
    }
  }

  public resendSmsCode(): void {
    this.loading = true;

    this.$auth.sendSmsCode({ phoneNumber: this.data.phone }, 'B2B', 'SMS').subscribe({
      next: (res) => {
        if (res.data.sendVerificationCode) {
          this.countdown = 60;
        }

        this.loading = false;
      },
      error: (error) => {
        this.$message.create('error', error);
        this.loading = false;
        throw new Error(error);
      },
    });
  }

  public pasteNumeric(event: ClipboardEvent): boolean {
    if (event.clipboardData) {
      const code = event.clipboardData.getData('text').replace(/[^0-9]/g, '');

      this.formCode.setValue({
        code1: code[0] || '',
        code2: code[1] || '',
        code3: code[2] || '',
        code4: code[3] || '',
        code5: code[4] || '',
        code6: code[5] || '',
      });

      if (code.length === 6) {
        setTimeout(() => {
          this.savePhone();
          event.preventDefault();
          return false;
        }, 500);
      }
    }

    return true;
  }

  public savePhone(): void {
    this.loading = true;

    const code =
      this.formCode.get('code1').value +
      this.formCode.get('code2').value +
      this.formCode.get('code3').value +
      this.formCode.get('code4').value +
      this.formCode.get('code5').value +
      this.formCode.get('code6').value;

    this.createAccountPaylod = new CreateAccountModel();
    this.createAccountPaylod.code = code;
    this.createAccountPaylod.phoneNumber = this.data.phone;
    this.createAccountPaylod.type = 'SIGN_IN_PHONE_NUMBER';

    this.$auth.validatePhoneNumberCode(this.createAccountPaylod).subscribe({
      next: (res) => {
        if (res.data.validateVerificationCodeFirebase) {
          this.$auth.customLogin(res?.data?.validateVerificationCodeFirebase);
        }

        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.$message.create('error', error);
        throw new Error(error);
      },
    });
  }
}
