import { Injectable, Query } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { CreateItemVariantParams } from '../../../models/items/V2/createItemVariantParams';
import { GET_ITEM_VARIANT_QUERY, LIST_ITEM_VARIANT_QUERY } from '../../../graphql/queries/itemVariant.queries';
import {
  CREATE_ITEM_VARIANT_MUTATION,
  CREATE_ITEM_VARIANT_OPTION_MUTATION,
  DELETE_ITEM_VARIANT_MUTATION,
  UPDATE_ITEM_VARIANT_MUTATION,
} from '../../../graphql/mutations/item-variant.mutations';
import { CreateItemUnitParams } from '../../../models/items/V2/createItemUnitParams';
import { ItemsV2Model } from '../../../models/items/V2/ItemV2.model';
import { ItemVariantModel } from '../../../models/items/V2/itemVariant.model';
import { ItemVariantOptionModel } from '../../../models/items/V2/itemVariantOption.model';
import { ItemUnitModel } from '../../../models/items/V2/itemUnit.model';

@Injectable({
  providedIn: 'root',
})
export class ItemVariantService {
  constructor(private readonly apollo: Apollo) {}

  cores = {
    'vermelho': '#FF0000',
    'verde': '#00FF00',
    'azul': '#0000FF',
    'amarelo': '#FFFF00',
    'rosa': '#FF69B4',
    'laranja': '#FFA500',
    'roxo': '#800080',
    'branco': '#FFFFFF',
    'preto': '#000000',
    'cinza': '#808080',
    'marrom': '#8B4513',
    'ciano': '#00FFFF',
    'indigo': '#4B0082',
    'ouro': '#FFD700',
    'prata': '#C0C0C0',
    'turquesa': '#40E0D0',
    'violeta': '#8A2BE2',
    'vinho': '#C73B51'
  };


  public listItemVariant(filters: any = {}): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_ITEM_VARIANT_QUERY,
      variables: { filters },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getItemVariant(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_ITEM_VARIANT_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public createItemVariant(params: CreateItemVariantParams): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_ITEM_VARIANT_MUTATION,
      variables: { params },
    });
  }

  public createItemVariantOption(params: any): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_ITEM_VARIANT_OPTION_MUTATION,
      variables: { params },
    });
  }
  public updateItemVariant(id: string, params: CreateItemVariantParams): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_ITEM_VARIANT_MUTATION,
      variables: { id, params },
    });
  }

  public deleteItemVariant(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_ITEM_VARIANT_MUTATION,
      variables: { id },
    });
  }
}
