import { gql } from 'apollo-angular';

const CREATE_ITEM_V2_MUTATION = gql`
  mutation CreateV2Item($params: CreateV2ItemParams!) {
    createV2Item(params: $params) {
      id
      description
      details
      name
      itemCategory {
        id
        name
      }
      itemTags {
        id
        name
      }
      name
      seller {
        id
      }
      type
    }
  }
`;

const UPDATE_ITEM_V2_MUTATION = gql`
  mutation UpdateV2Item($id: UUID4!, $params: UpdateV2ItemParams!) {
    updateV2Item(id: $id, params: $params) {
      id
      description
      details
      name
      itemCategory {
        id
        name
      }
      itemUnits {
        active
        price
        id
        stockCount
        itemVariantOptions {
         id
         orderIndex
         name
         itemVariant {
          id
          name
          orderIndex
          type
         }
        }
       }
      itemTags {
        id
        name
      }
      name
      seller {
        id
      }
      type
    }
  }
`;

const UPDATE_ITEM_UNIT_MUTATION = gql`
  mutation updateItemUnit($id: UUID4!, $params: UpdateItemUnitParams!) {
    updateItemPrice(id: $id, params: $params) {
      id
    }
  }
`;

const DELETE_ITEM_V2_MUTATION = gql`
  mutation DeleteV2Item($id: UUID4!) {
    deleteV2Item(id: $id) {
      deletedAt
    }
  }
`;


export {
  UPDATE_ITEM_V2_MUTATION,
  DELETE_ITEM_V2_MUTATION,
  CREATE_ITEM_V2_MUTATION,
  UPDATE_ITEM_UNIT_MUTATION
};
