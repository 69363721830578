import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { Message } from '../../../utils/message';
import { SellerModel } from '../../../models/sellers/sellers.model';
import { ForgotPasswordService } from '../../../services/external/forgot-password.service';

@Component({
  selector: 'app-modal-change-password',
  templateUrl: './modal-change-password.component.html',
  styleUrls: ['./modal-change-password.component.scss'],
})
export class ModalChangePasswordComponent implements OnInit {
  public formDescription: any;
  public seller: SellerModel;

  public pattern: RegExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{6,}/;

  public isNumber: string = 'initial';
  public isCapitalLetter: string = 'initial';
  public isSpecialCharacter: string = 'initial';
  public isLowerCase: string = 'initial';
  public isLengthNumber: string = 'initial';
  public errorPasswordPattern: string = Message.MESSAGE_ERROR_PASSWORD_PATTERN;
  public errorPasswordRequired: string = Message.MESSAGE_ERROR_PASSWORD_REQUIRED;
  public errorConfirmPasswordValid: string = Message.MESSAGE_ERROR_CONFIRM_PASSWORD_VALID;

  public isValid: boolean = false;
  public rehide: boolean = true;
  public rehideConfirm: boolean = true;
  public showDetails: boolean = false;
  public loading: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly modalRef: NzModalRef,
    private $forgotPassword: ForgotPasswordService
  ) {}

  public ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.formDescription = this.fb.group({
      password: new FormControl('', [Validators.required, Validators.pattern(this.pattern)]),
      newPassword: new FormControl('', [Validators.required, Validators.pattern(this.pattern)]),
    });
  }

  public setHandleSubmit(sendData: boolean): void {
    if (sendData) {
      this.modalRef.close(this.formDescription.value);
    } else {
      this.modalRef.close();
    }
  }

  public onKeyPassword(password: any): void {
    this.showDetails = true;

    if (this.$forgotPassword.validateStrongPassword(password)) {
      this.formDescription.get('newPassword')?.setValue(password);
      this.showDetails = false;
    } else {
      this.formDescription.get('newPassword')?.setValue('');
      this.showDetails = true;
    }
  }

  public validPassword(): void {
    this.isCapitalLetter = this.$forgotPassword.validPassword(this.formDescription.get('newPassword').value)[
      'capitalLetter'
    ];
    this.isSpecialCharacter = this.$forgotPassword.validPassword(this.formDescription.get('newPassword').value)[
      'specialCharacter'
    ];
    this.isLowerCase = this.$forgotPassword.validPassword(this.formDescription.get('newPassword').value)['lowerCase'];
    this.isLengthNumber = this.$forgotPassword.validPassword(this.formDescription.get('newPassword').value)[
      'lengthNumber'
    ];
    this.isNumber = this.$forgotPassword.validPassword(this.formDescription.get('newPassword').value)['number'];
  }
}
