import { SellerModel } from './../../models/sellers/sellers.model';
import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CategoryModel } from '../../models/categories/categories.model';
import { ExpandedSubHeaderComponent } from './expanded-sub-header/expanded-sub-header.component';
import { FilterMenuComponent } from './filter-menu/filter-menu.component';
import { FilterCategoriesComponent } from './filter-categories/filter-categories.component';
import { CategoriesService } from '../../services/products/categories/categories.service';
import { take, timeout } from 'rxjs';
import { StateManagementService } from '../../state-management/state-management.service';
import { NavigationEnd, Route, Router } from '@angular/router';
import { Location } from '@angular/common';
import { StoreModel } from '../../models/store/store.model';
import { ItemVariantService } from '../../services/item/itemVariant/item-variant.service';
import { ItemVariantModel } from '../../models/items/V2/itemVariant.model';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
})
export class SubHeaderComponent implements OnInit, AfterContentChecked {
  @Input() public seller: SellerModel = new SellerModel();
  @Input() public store: StoreModel = new StoreModel();
  @Input() public pointId: string;

  @Output() public filterOpened = new EventEmitter<boolean>();

  public productsCategoryList: Array<CategoryModel> = [];

  public form: FormGroup;

  public clickedFavorite: boolean = false;
  public showFilterDesktop: boolean = false;
  public showSubHeader: boolean = true;
  public isShowInputSearch: boolean = false;

  public activeIndex: number;

  public colors: Array<{ label: string; value: string }> = [];
  public sizes: Array<{ label: string; value: string }> = [];
  public amountCents: Array<{ label: string; value: any }> = [];
  public imagesUrl = [
    '../../assets/images/shopping-cart.svg',
    '../../assets/images/clicked-heart.svg',
    '../../assets/images/unclicked-heart.svg',
  ];

  constructor(
    private $itemVariant: ItemVariantService,
    private $drawer: NzDrawerService,
    private $notification: StateManagementService,
    private $category: CategoriesService,
    private $location: Location,
    private fb: FormBuilder,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private route: Router
  ) {}

  public ngOnInit(): void {
    this.getSeller();
    this.createForm();
    this.getValuesChange();
    this.getIsCategory();
  }

  public ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  public getSeller(): void {
    if (this.seller?.id) {
      this.getCategoryList();
      this.getArrayValues();
    }
  }

  private createForm(): void {
    this.form = this.fb.group({
      search: new FormControl(''),
      category: new FormControl(''),
      amountCents: new FormControl(''),
      color: new FormControl(''),
      size: new FormControl(''),
    });
  }

  public getHeaderConfig(): {
    headerTypePrimary: boolean;
    showFilterMobile: boolean;
    headerTitle: string;
    hideIcons: boolean;
    hideShoppingCart: boolean;
  } {
    let headerTypePrimary = true;
    let showFilterMobile = true;
    let hideShoppingCart = false;
    let hideIcons = false;
    let headerTitle = '';

    const verifyRoute = this.route.url;

    if (verifyRoute.includes('/loggedCart')) {
      headerTypePrimary = true;
      showFilterMobile = false;
      headerTitle = 'Sacola';
    }

    if (verifyRoute.includes('/details')) {
      headerTypePrimary = false;
      showFilterMobile = false;
      hideShoppingCart = false;
      hideIcons = false;
      headerTitle = '';
    }

    if (verifyRoute.includes('/list')) {
      showFilterMobile = true;
      headerTypePrimary = true;
      hideShoppingCart = true;
      headerTitle =  this.store.displayName;
    }

    if (verifyRoute.includes('/select-order-type')) {
      showFilterMobile = false;
      headerTypePrimary = true;
      hideIcons = true;
      headerTitle = 'Entrega';
    }

    if (verifyRoute.includes('/address')) {
      showFilterMobile = false;
      headerTypePrimary = true;
      hideIcons = true;
      hideShoppingCart = true;
      headerTitle = 'Endereços';
    }

    return { headerTypePrimary, showFilterMobile, headerTitle, hideIcons, hideShoppingCart };
  }

  public getArrayValues(): void {
    const width = window.innerWidth;

    if (width > 768) {
      this.$itemVariant.listItemVariant({sellerId: this.seller.id}).subscribe({
        next: (res) => {
          if (res?.data?.listItemVariants) {
            const data: Array<ItemVariantModel> = [...res.data.listItemVariants];

            if (data && data.length > 0) {
              this.colors = data[0].itemVariantOptions.map((color: any) => {
                return { label: color.name, value: color.id };
              });

              if (data.length > 1) {
                if (data[1]) {
                  this.sizes = data[1].itemVariantOptions.map((size: any) => {
                    return { label: size.name, value: size.id };
                  });
                }
              }
            }

            this.amountCents = [
              { value: [200, 500], label: '200 a 500' },
              { value: [500, 800], label: '500 a 800' },
              { value: [600, 900], label: '600 a 900' },
              { value: [900, 3000], label: '900 a 3000' },
              { value: [3000, 6000], label: '3000 a 6000' },
            ];
          }
        },
        error: (error) => {
          throw new Error(error);
        },
      });
    }
  }

  public getValuesChange(): void {
    this.form.get('search').valueChanges.subscribe((res) => {
      const currentUrl = this.router.url;
      const desiredUrl = `/internal/${this.seller?.id}/${this.pointId}/products/list`;
      this.$notification.setSearchValue(res);

      if (currentUrl !== desiredUrl) {
        this.router.navigate([desiredUrl]);
      }

      this.$notification.setSearchValue(res);

    });
  }

  public clearList(): void {
    this.$notification.setSearchValue(null);
    this.$notification.setChangedCategory(null);
    this.$notification.setIsCategory(true);
  }

  public openCategoriesFilter(selectedProductCategory?: CategoryModel): void {
    const width = window.innerWidth;
    let nzHeight = '100%';

    if (width > 768) {
      nzHeight = '60%';

      this.showFilterDesktop = false;
      this.filterOpened.emit(this.showFilterDesktop);
    }

    const drawer = this.$drawer.create({
      nzClosable: false,
      nzWidth: '100%',
      nzHeight,
      nzBodyStyle: {
        'max-width': '100vm',
        'overflow-x': 'hidden',
        height: nzHeight,
        padding: '0',
      },
      nzPlacement: 'top',
      nzContentParams: { categories: this.productsCategoryList, selectedProductCategory: selectedProductCategory },
      nzContent: FilterCategoriesComponent,
    });
    drawer.afterClose.subscribe((res: string) => {});
  }

  public openExpandedSubHeader(): void {
    this.showFilterDesktop = false;

    const drawer = this.$drawer.create({
      nzHeight: '40%',
      nzWidth: '100%',
      nzClosable: false,
      nzBodyStyle: {
        height: '40%',
        padding: '0',
      },
      nzPlacement: 'top',
      nzContent: ExpandedSubHeaderComponent,
    });
    drawer.afterClose.subscribe((res: string) => {
      if (res) {
        switch (res) {
          case 'filter':
            this.openFilterMenu();
            break;
          case 'filter-categories':
            this.openCategoriesFilter();
            break;
          default:
            this.orderByProducts(res);
            break;
        }
      }
    });
  }

  public openFilterMenu(): void {
    const width = window.innerWidth;

    if (width > 768) {
      this.showFilterDesktop = !this.showFilterDesktop;
      this.filterOpened.emit(this.showFilterDesktop);
    } else {
      const drawer = this.$drawer.create({
        nzClosable: false,
        nzWidth: '100%',
        nzHeight: '50%',
        nzBodyStyle: {
          'max-width': '100vm',
          'overflow-x': 'hidden',
          height: '50%',
          padding: '0',
        },
        nzContentParams: { categories: this.productsCategoryList },
        nzContent: FilterMenuComponent,
      });
      drawer.afterClose.subscribe((res: string) => {
        if (res) {
          this.$notification.setProductFilter(res);
        }
      });
    }
  }

  public setFilterForm(field: string, value: any) {
    this.form.get(field).setValue(value);
  }

  public orderByProducts(res): void {}

  public getCategoryList(): void {
    this.$category
      .getCategoryList(this.seller.id)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (res?.data?.listItemCategories) {
            this.productsCategoryList = res.data.listItemCategories;
          }
        },
        error: (error) => {
          throw new Error(error);
        },
      });
  }

  public goBack(): void {
    this.$location.back();
  }

  public goToCart(): void {
    this.router.navigate(['/loggedCart/' + this.pointId + '/cart']);
  }

  public getOrderByValue(orderBy): void {
    this.$notification.setOrderBy(orderBy);
  }

  public onSelectCategory(category: any, type: string, index: number): void {
    this.activeIndex = index;

    if (category) {
      if (type === 'selected') {
        this.router.navigate(['/internal/' + this.seller?.id + '/' + this.pointId + '/products/list']);
        this.$notification.setChangedCategory(category);
        this.$notification.setIsCategory(true);
      }
    }
  }

  public showInputSearch(): void {
    this.isShowInputSearch = true;
  }

  public getIsCategory(): void {
    this.$notification.isShowCategories.subscribe((res) => {
      if (res === false) {
        this.activeIndex = -1;
      }
    });
  }
}
