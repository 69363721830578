import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { SellerModel } from '../../../models/sellers/sellers.model';
import { AuthService } from '../../../services/auth/auth-business/auth.service';
import { StateManagementService } from '../../../state-management/state-management.service';
import { Message } from '../../../utils/message';
import { ValidationEmailComponent } from '../../security/validation-email/validation-email.component';

@Component({
  selector: 'app-modal-change-email',
  templateUrl: './modal-change-email.component.html',
  styleUrls: ['./modal-change-email.component.scss'],
})
export class ModalChangeEmailComponent implements OnInit {
  public formDescription: any;
  public seller: SellerModel;

  public isConfirmPassword: boolean = false;

  constructor(
    private fb: FormBuilder,
    private $notification: StateManagementService,
    private modalRef: NzModalRef,
    private $message: NzMessageService,
    private $modal: NzModalService,
    private $auth: AuthService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getSeller();
  }

  public createForm(): void {
    this.formDescription = this.fb.group({
      email: new FormControl('', [Validators.email, Validators.required]),
      password: new FormControl(''),
    });
  }

  public getSeller(): void {
    this.$notification.sellers.subscribe((res) => {
      this.seller = res;
    });
  }

  public setHandleSubmit(value?): void {
    if (value) {
      this.modalRef.close(this.formDescription.value);
    } else {
      this.modalRef.close();
    }
  }

  public nextStep(): void {
    this.isConfirmPassword = true;
  }

  public async confirmPassword(): Promise<void> {
    try {
      const result = await this.$auth.validatePassword(this.seller.email, this.formDescription.get('password').value);

      if (result.user) {
        const params: Object = {
          data: {
            ...this.seller,
            email: this.formDescription.get('email').value,
            oldEmail: this.seller.email,
            password: this.formDescription.get('password').value,
          },
        };

        this.$modal.create({
          nzComponentParams: params,
          nzTitle: 'Validação do e-mail',
          nzWidth: '500px',
          nzContent: ValidationEmailComponent,
        });
      }
    } catch (error: any) {
      this.$message.error(Message.INCORRECT_PASSWORD);
    }
  }
}
