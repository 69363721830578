import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, Mutation, Query } from 'apollo-angular';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import {
  GET_USER,
  LIST_USERS_QUERY,
  CURRENT_USER_QUERY,
  LIST_SUBSELLERS_QUERY,
  GET_SUBSELLER_QUERY,
} from '../../graphql/queries/user.queries';
import { RoleEnum } from '../../enums/roles';
import {
  CREATE_USER_MUTATION,
  UPDATE_USER_MUTATION,
  CHANGE_USER_ROLE_MUTATION,
  CREATE_SUBSELLER_MUTATION,
  DELETE_SUBSELLER_MUTATION,
  UPDATE_SUBSELLER_MUTATION,
} from '../../graphql/mutations/user.mutations';
import { UserModel } from '../../models/users/users.model';
import { PeriodFilterParams } from '../../models/users/filter-users-params.model';

@Injectable()
export class UsersService {
  constructor(private readonly apollo: Apollo, private readonly fireStore: AngularFirestore) {}

  public getUser(marketplaceId: string, sellerId: string, externalUserId: string): Observable<UserModel> {
    return this.fireStore
      ?.doc(`marketplaces/${marketplaceId}/sellers/${sellerId}/users/${externalUserId}`)
      .valueChanges() as Observable<UserModel>;
  }

  public getSubsellerById(sellerId: string, userId: string): Observable<any> {
    return this.apollo.watchQuery<UserModel>({
      query: GET_SUBSELLER_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { sellerId, userId },
    }).valueChanges;
  }

  public getSubsellerList(sellerId: string, filters?: any): Observable<any> {
    return this.apollo.watchQuery<UserModel>({
      query: LIST_SUBSELLERS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { sellerId, filters },
    }).valueChanges;
  }

  public createSubseller(sellerId: string, params: Partial<UserModel>): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_SUBSELLER_MUTATION,
      variables: {
        sellerId,
        params,
      },
    });
  }

  public updateSubseller(userSellerId: string, params: Partial<UserModel>): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_SUBSELLER_MUTATION,
      variables: {
        userSellerId,
        params,
      },
    });
  }

  public deleteSubseller(sellerId: string, userId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: DELETE_SUBSELLER_MUTATION,
      variables: {
        sellerId,
        userId,
      },
    });
  }

  public getUserListApollo(
    email: string,
    name: string,
    insertedAt: PeriodFilterParams
  ): Observable<ApolloQueryResult<UserModel>> {
    return this.apollo.watchQuery<UserModel>({
      query: LIST_USERS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { email, name, insertedAt },
    }).valueChanges;
  }

  public getCurrentUser(): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getUserApollo(id: string): Observable<ApolloQueryResult<UserModel>> {
    return this.apollo.watchQuery<UserModel>({
      query: GET_USER,
      fetchPolicy: 'cache-and-network',
      variables: { id },
    }).valueChanges;
  }

  public createUser(
    active: boolean,
    cpf: string,
    name: string,
    email: string,
    password: string,
    role: string,
    phone: string
  ): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_USER_MUTATION,
      variables: {
        active,
        cpf,
        name,
        email,
        password,
        role,
        phone,
      },
    });
  }

  public updateUser(
    id: string,
    active: boolean,
    cpf: string,
    name: string,
    email: string,
    password: string,
    role: string,
    phone: string
  ): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_USER_MUTATION,
      variables: {
        id,
        active,
        cpf,
        name,
        email,
        password,
        role,
        phone,
      },
    });
  }

  public changeUserRole(id: string, role: RoleEnum): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CHANGE_USER_ROLE_MUTATION,
      variables: {
        id,
        role,
      },
    });
  }
}
