import { Observable, of } from 'rxjs';
import * as moment from 'moment';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  Query,
  AngularFirestore,
  CollectionReference,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';

import { environment } from '@gen/environments';
import { ContactModel, ContactType } from '../../models/contact/contact.model';
import { ResponseFilterModel } from '../../models/filters/response-filter.model';
import { PointOfSalesService } from './../point-of-sales/point-of-sales.service';
import { Apollo } from 'apollo-angular';
import {
  LIST_CONTACTS_QUERY,
  LIST_CONTACT_BY_ID_QUERY,
  LIST_CONTACT_CUSTOMER_QUERY,
} from '../../graphql/queries/contacts.queries';
import {
  CREATE_CONTACT_MUTATION,
  DELETE_CONTACT_MUTATION,
  UPDATE_CONTACT_MUTATION,
} from '../../graphql/mutations/contact.mutations';
import { SellerModel } from '../../models/sellers/sellers.model';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  public marketplaceId: string = environment.marketplaceId;

  constructor(
    private readonly fireStore: AngularFirestore,
    public $point: PointOfSalesService,
    private readonly apollo: Apollo
  ) {}

  public getContactList(filters: {
    sellerId: string;
    fullName?: string;
    isLegalEntity?: string;
    email?: string;
    cpf?: string;
    cnpj?: string;
    startDate?: Date;
    endDate?: Date;
  }): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_CONTACTS_QUERY,
      variables: filters,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getContactDetailById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_CONTACT_BY_ID_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getContactCustomer(sellerId: string, customerId: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_CONTACT_CUSTOMER_QUERY,
      variables: { sellerId, customerId },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public createContact(contacts: ContactModel, seller: SellerModel): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_CONTACT_MUTATION,
      variables: { contacts, seller },
    });
  }

  public updateContact(contactId: string, sellerId: string, contact: ContactModel): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_CONTACT_MUTATION,
      variables: { contactId, sellerId, contact },
    });
  }

  public deleteContact(contactId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_CONTACT_MUTATION,
      variables: { contactId },
    });
  }

  public getContactDetail(sellerId: string, id: string): Observable<ContactModel> | undefined {
    return this._collection(this.marketplaceId, sellerId)?.doc(id).valueChanges();
  }

  public getContactDetailByCustomerId(sellerId: string, customerId: string): Observable<ContactModel[]> {
    if (customerId) {
      return this.fireStore
        .collection(`marketplaces/${this.marketplaceId}/sellers/${sellerId}/contacts`, (ref) =>
          ref.where('customerId', '==', customerId)
        )
        .valueChanges() as Observable<ContactModel[]>;
    }

    return of([]);
  }

  public async addNewContact(sellerId: string, pointId: string, data: ContactModel): Promise<any> {
    const contactId = uuidv4();
    data.id = contactId;

    this.$point.updatePoint(pointId, { contactId: contactId, customerPhone: data.phone });

    return this._collection(this.marketplaceId, sellerId)?.doc(data.id).set(data);
  }

  public async addContact(sellerId: string, data: ContactModel): Promise<any> {
    const contactId = uuidv4();
    data.id = contactId;

    return this._collection(this.marketplaceId, sellerId)?.doc(data.id).set(data);
  }

  public getContactByCPF(sellerId: string, cpf: string): Observable<any> {
    const filter = {
      sellerId,
      cpf,
    };
    return this.getContactList(filter);
  }

  public getContactById(sellerId: string, id: string): Observable<ContactModel> | undefined {
    return this.fireStore
      .doc(`marketplaces/${this.marketplaceId}/sellers/${sellerId}/contacts/${id}`)
      .valueChanges() as Observable<ContactModel>;
  }

  public getContactByCNPJ(
    marketplaceId: string,
    sellerId: string,
    cnpj: string
  ): Observable<ContactModel[]> | undefined {
    if (cnpj) {
      return this.fireStore
        .collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/contacts`, (ref) =>
          ref.where('cnpj', '==', cnpj)
        )
        .valueChanges() as Observable<ContactModel[]>;
    }

    return of([]);
  }

  public getContactsWithFilters(sellerId: string, filter: ResponseFilterModel): Observable<ContactModel[]> {
    return this._collection(this.marketplaceId, sellerId, filter).valueChanges();
  }

  private _collection(
    marketplaceId: string,
    sellerId: string,
    filter?: ResponseFilterModel
  ): AngularFirestoreCollection<ContactModel> {
    if (filter && !isEmpty(filter)) {
      return this.fireStore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/contacts`, (ref) => {
        let query: CollectionReference | Query = ref;

        if (filter.creationDateFilter) {
          if (filter.creationDateFilter.date) {
            const startDate = moment(filter.creationDateFilter.date).set({ hour: 0, minute: 0, second: 0 }).format();
            const endDate = moment(filter.creationDateFilter.date).set({ hour: 23, minute: 59, second: 59 }).format();

            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else if (filter.creationDateFilter.startDate) {
            const startDate = moment(filter.creationDateFilter.startDate)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();
            const endDate = moment(filter.creationDateFilter.endDate)
              .set({ hour: 23, minute: 59, second: 59 })
              .format();

            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else {
            const date = moment()
              .subtract(filter.creationDateFilter.dateNumber, filter.creationDateFilter.datePeriod)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();

            query = query.where('insertedAt', '>=', date);
          }
        }

        if (filter.documentFilter) {
          let document = filter.documentFilter;
          if (document.length === 11) {
            query = query.where('cpf', '==', filter.documentFilter);
          } else {
            query = query.where('cnpj', '==', filter.documentFilter);
          }
        }

        return query.orderBy('insertedAt', 'desc');
      });
    }

    return this.fireStore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/contacts`, (ref) =>
      ref.orderBy('insertedAt', 'desc')
    );
  }
}
