import { take } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, BehaviorSubject, EMPTY } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { Message } from '../../../utils/message';
import {
  LOGIN_BY_PASSWORD_MUTATION,
  RENEW_ACCESS_TOKEN_MUTATION,
  SEND_VERIFICATION_CODE_MUTATION,
  VALIDATE_VERIFICATION_CODE_MUTATION,
} from '../../../graphql/mutations/auth.mutations';
import { shortenName } from '../../../utils/utils';
import { EmailModel } from '../../../models/email/email.model';
import { LoginModel } from '../../../models/login/login.model';
import { OnboardingService } from '../../onboarding/onboarding.service';
import { ResponseModel } from '../../../models/response/response.model';
import { CreateAccountModel } from '../../../models/auth/create-account.model';
import { StateManagementService } from '../../../state-management/state-management.service';
import { OnboardingVerifyPhoneNumber } from '../../../models/onboarding/external/onboarding-verify-phone-number.model';
import { AlertService } from '../../alert/alert.service';

@Injectable()
export class AuthService {
  constructor(
    private readonly router: Router,
    private readonly apollo: Apollo,
    private $onboarding: OnboardingService,
    private readonly $alert: AlertService,
    private $notification: StateManagementService,
    private readonly firebaseAuth: AngularFireAuth,
    private readonly $functions: AngularFireFunctions
  ) {}
  private auth: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public auths: Observable<string> = this.auth.asObservable();

  public setAuth(value: string): void {
    this.auth.next(value);
  }

  public renewToken(): Observable<any> {
    const refreshToken = sessionStorage.getItem('refreshToken');

    if (refreshToken) {
      return this.apollo.mutate({
        mutation: RENEW_ACCESS_TOKEN_MUTATION,
        variables: {
          refreshToken,
        },
      });
    } else {
      this.logout();

      return EMPTY;
    }
  }

  public async signIn(value: LoginModel): Promise<void> {
    await this.firebaseAuth.signInWithEmailAndPassword(value.email, value.password).then((res) => {
      res.user?.getIdTokenResult(true).then((tokenResult) => {
        this.$notification.setUser(tokenResult?.claims);

        // Set token to GraphQL
        sessionStorage.setItem('accessToken', tokenResult.token);
        sessionStorage.setItem('refreshToken', res.user.refreshToken);
        this.setAuth(tokenResult.token);

        this.$alert.setAlertInfo('SUCCESS', Message.LOGIN_SUCCESS);
        this.router.navigate(['/internal']);
      });
    });
  }

  public sendResetPasswordCode(email: string): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('sendResetPasswordCode')({ email });
  }

  public validateEmailCode(payload: EmailModel): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('validateEmailCode')(payload);
  }

  public changeUserPassword(email: string, password: string): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('changeUserPassword')({ email, password });
  }

  public async updatePassword(oldPassword: string, newPassword: string): Promise<any> {
    let status: any = { status: 201 };
    await this.firebaseAuth.currentUser.then(async (user) => {
      if (user && user.email) {
        const credentials = firebase.auth.EmailAuthProvider.credential(user.email, oldPassword);

        await user
          .reauthenticateWithCredential(credentials)
          .then(() => {
            user.updatePassword(newPassword).then(() => {
              user.getIdTokenResult(true);
              this.$alert.setAlertInfo('SUCCESS', Message.UPDATE_PASSWORD);
            });
          })
          .catch((err) => {
            this.$alert.setAlertInfo('ERROR', Message.ERROR_UPDATE_PASSWORD);
            status = err;
          });
      }
    });
    return status;
  }

  public async validatePassword(email: string, password: string): Promise<any> {
    return await firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(async () => await this.firebaseAuth.signInWithEmailAndPassword(email, password).then((res) => res))
      .catch((err) => {
        this.$alert.setAlertInfo('ERROR', Message.INCORRECT_PASSWORD);
        return err;
      });
  }

  public sendSmsCode(data: CreateAccountModel, platform: string, communicationMethod: string): Observable<any> {
    return this.apollo.mutate({
      mutation: SEND_VERIFICATION_CODE_MUTATION,
      variables: {
        phoneNumber: `+55${data.phoneNumber}`,
        platform,
        communicationMethod,
      },
    });
  }

  public userWithPhoneNumberExists(phoneNumber: string): Observable<ResponseModel<boolean>> {
    return this.$functions.httpsCallable('userWithPhoneNumberExists')(`+55${phoneNumber}`);
  }

  public validatePhoneNumberCode(data: CreateAccountModel): Observable<any> {
    return this.apollo.mutate({
      mutation: VALIDATE_VERIFICATION_CODE_MUTATION,
      variables: {
        phoneNumber: `+55${data.phoneNumber}`,
        code: data.code,
      },
    });
  }

  public sendVerifyEmailCode(email: string, name: string): Observable<any> {
    return this.$functions.httpsCallable('sendVerifyEmailCode')({
      email,
      username: shortenName(name),
    });
  }

  public async sendChangeSellerEmail(payload: EmailModel): Promise<any> {
    try {
      const result = await this.$functions.httpsCallable('changeSellerEmail')(payload).pipe(take(1)).toPromise();

      if (result.status === 200) {
        return true;
      } else {
        this.$alert.setAlertInfo('ERROR', result.error);

        return result.error;
      }
    } catch (error) {
      this.$alert.setAlertInfo('ERROR', Message.ERROR_SEND_EMAIL);
      return error;
    }
  }

  public customLogin(
    token: { accessToken: string; refreshToken: string; customToken: string },
    data?: OnboardingVerifyPhoneNumber,
    canNavigate: boolean = true
  ): void {
    this.firebaseAuth.signInWithCustomToken(token.customToken).then((res) => {
      if (res?.user) {
        this.$notification.setUser(res.user);

        // Set token to GraphQL
        sessionStorage.setItem('accessToken', token.accessToken);
        sessionStorage.setItem('refreshToken', token.refreshToken);
        this.setAuth(token.accessToken);

        if (data) {
          this.$onboarding.savePhoneNumber(res.user.uid, data);

          // Create dashboard activation incomplete.
          this.$onboarding.saveDashboardPersonalData(res.user);
        }

        this.$alert.setAlertInfo('SUCCESS', Message.LOGIN_SUCCESS);

        if (canNavigate) {
          this.router.navigate(['/internal']);
        }
      }
    });
  }

  public logout(): void {
    this.firebaseAuth
      .signOut()
      .then(() => {
        sessionStorage.clear();

        // Change route
        this.router.navigate(['/external']).then(() => {
          window.location.reload();
        });
      })
      .catch(() => {
        this.$alert.setAlertInfo('ERROR', 'Erro ao tentar o logout, tente novamente!');
      });
  }

  public standardEncoding(v: string): string {
    return encodeURIComponent(v);
  }

  public async refreshUser(): Promise<void> {
    await this.firebaseAuth.currentUser.then((u) => {
      if (u) {
        u.getIdTokenResult(true);
      }
    });
  }

  public loginByPassword(email: string, password: string, platform?: string): Observable<any> {
    return this.apollo.mutate({
      mutation: LOGIN_BY_PASSWORD_MUTATION,
      variables: {
        email,
        password,
        platform,
      },
    });
  }
}
