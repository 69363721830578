import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { WithdrawlsModel } from '../../../models/withdrawls/withdrawl.model';

@Component({
  selector: 'app-modal-withdraw',
  templateUrl: './modal-withdraw.component.html',
  styleUrls: ['./modal-withdraw.component.scss'],
})
export class ModalWithdrawComponent implements OnInit {
  @Input() public withdrawal: WithdrawlsModel;
  @Input() public textBtn: string;

  public formGroup!: FormGroup;

  constructor(public readonly $modalRef: NzModalRef, private readonly fb: FormBuilder) {}

  public ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.formGroup = this.fb.group({
      statusReason: new FormControl<string>('', [Validators.maxLength(250)]),
    });
  }

  public close(hasData: boolean): void {
    if (hasData) {
      this.$modalRef.close(this.formGroup.value);
    } else {
      this.$modalRef.close();
    }
  }
}
