import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { ResponseModel } from '../../models/response/response.model';
import { ApplicationModel } from '../../models/application/application.model';

@Injectable()
export class ApplicationsService {
  constructor(private afFunc: AngularFireFunctions) {}

  public createApplication(params = {}): Promise<ResponseModel<ApplicationModel>> {
    return this.afFunc
      .httpsCallable<any, ResponseModel<ApplicationModel>>('createApplication')(params)
      .pipe(take(1))
      .toPromise();
  }

  public updateApplication(params = {}): Promise<ResponseModel<ApplicationModel>> {
    return this.afFunc
      .httpsCallable<any, ResponseModel<ApplicationModel>>('updateApplication')(params)
      .pipe(take(1))
      .toPromise();
  }

  public listApplications(params = {}): Observable<ResponseModel<ApplicationModel[]>> {
    return this.afFunc.httpsCallable<any, ResponseModel<ApplicationModel[]>>('listApplications')(params);
  }
}
