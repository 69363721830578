import { CartModel } from '../cart/cart.model';
import { CreditCardModel } from '../credit-card/credit-card.model';
import { AddressModel } from './../address/address.model';

export class PointModel {
  public id?: string;
  public sellerId?: string;
  public insertedAt: string;
  public method?: string;
  public cartId?: string;
  public cart?: CartModel;
  public priceToPay?: number;
  public orderId?: string;
  public paymentAt?: string;
  public paymentId?: string;
  public selectedType?: 'DELIVERY' | 'PICK_AND_GO' | 'LOCKER';
  public installments?: number;
  public customerId?: string;
  public customerPhone?: string;
  public contactId?: string;
  public shippingAddress?: AddressModel;
  public coupom?: string;
  public allowCoupon?: boolean;
  public description: string;
  public cvv?: string;
  public auth?: { accessToken: string; refreshToken: string; customToken: string };
  public isAnonymous?: boolean;
  public selectPaymentMethod?: { method: string, data: Partial<CreditCardModel> };
  public selectedCreditCard?: CreditCardModel;
}
