import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { PointModel } from '../../models/points/point-of-sales.model';
import { StateManagementService } from '../../state-management/state-management.service';
import { CustomerModel } from '../../models/customer/customer.model';

@Component({
  selector: 'app-checkout-processing-review',
  templateUrl: './checkout-processing-review.component.html',
  styleUrls: ['./checkout-processing-review.component.scss'],
})
export class CheckoutProcessReviewComponent implements OnInit {
  public point: PointModel = new PointModel();
  public customer: CustomerModel = new CustomerModel();

  constructor(private readonly router: Router, private $notification: StateManagementService) {}

  public ngOnInit(): void {
    this.$notification.setTitleCheckout('Pedido');
    this.getPoint();
    this.getCustomer();
  }

  private getPoint(): void {
    this.$notification.points.subscribe((res) => {
      if (res) {
        this.point = res;
      }
    });
  }

  private getCustomer(): void {
    this.$notification.customers.subscribe((customer) => {
      if (customer && Object.keys(customer).length > 0) {
        this.customer = customer;
      }
    });
  }

  public goToCart(): void {
    this.router.navigate([`/loggedCart/${this.point.id}/cart`]);
  }
}
