import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { SidenavModel } from '../../models/utils/sidenav.model';
import { SYNPASES_SIDENAV } from '../../utils/side-nav-synapses';
import { AuthService } from '../../services/auth/auth-business/auth.service';
import { ModalLogoutComponent } from '../modals/modal-logout/modal-logout.component';

@Component({
  selector: 'app-simple-sidenav',
  templateUrl: './simple-sidenav.component.html',
  styleUrls: ['./simple-sidenav.component.scss'],
})
export class SimpleSidenavComponent {
  public routes: SidenavModel[] = SYNPASES_SIDENAV;
  public isCollapsed: boolean = false;

  constructor(
    private $auth: AuthService,
    private $notify: NzNotificationService,
    private readonly $modal: NzModalService
  ) {}

  public logout(): void {
    this.$modal
      .create({
        nzContent: ModalLogoutComponent,
      })
      .afterClose.subscribe((res: boolean) => {
        if (res) {
          this.$notify.remove();
          this.$auth.logout();
        }
      });
  }

  public openHandler(title: string): void {
    this.routes.forEach((route) => {
      if (route.title !== title) {
        route.isOpen = false;
      }
    });
  }
}
