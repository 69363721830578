import { gql } from 'apollo-angular';

const UPSERT_STORE = gql`
  mutation UpsertStore($params: UpsertStoreParams!, $sellerId: UUID4!) {
    upsertStore(params: $params, sellerId: $sellerId) {
      mall
    }
  }
`;

const UPSERT_STORE_PREFERENCES = gql`
  mutation UpsertStorePreferences($params: StorePreferencesParams, $sellerId: UUID4!) {
    upsertStorePreferences(params: $params, sellerId: $sellerId) {
      mall
    }
  }
`;

const UPDATE_STORE_WHATSAPP_NUMBER_CONFIRMATION = gql`
  mutation UpdateStoreWhatsappNumberConfirmation(
    $params: UpdateStoreWhatsappNumberConfirmationParams!
    $sellerId: UUID4!
  ) {
    updateStoreWhatsappNumberConfirmation(params: $params, sellerId: $sellerId) {
      whatsappNumber
    }
  }
`;

const UPDATE_STORE_WHATSAPP_NUMBER = gql`
  mutation UpdateStoreWhatsappNumber($params: UpdateStoreWhatsappNumberParams!, $sellerId: UUID4!) {
    updateStoreWhatsappNumber(params: $params, sellerId: $sellerId) {
      whatsappNumber
    }
  }
`;

export {
  UPSERT_STORE,
  UPSERT_STORE_PREFERENCES,
  UPDATE_STORE_WHATSAPP_NUMBER_CONFIRMATION,
  UPDATE_STORE_WHATSAPP_NUMBER,
};
