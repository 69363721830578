import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { Injectable, Query } from '@angular/core';

import { LIST_CANCELLMENTS_QUERY } from '../../graphql/queries/cancellments.queries';
import { FilterCancellationParams } from './../../models/cancellments/filter-cancellation-params.model';

@Injectable()
export class CancellmentsService {
  constructor(private readonly apollo: Apollo) {}

  public getListCancellments(filter: FilterCancellationParams): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_CANCELLMENTS_QUERY,
      variables: { filter },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }
}
