import { gql } from 'apollo-angular';

const CREATE_BANK_ACCOUNT_MUTATION = gql`
  mutation CreateBankAccount(
    $accountCheckNumber: String!
    $accountNumber: String!
    $agencyCheckNumber: String
    $agencyNumber: String!
    $bankNumber: String!
    $sellerId: UUID4!
    $type: String!
  ) {
    createBankAccount(
      params: {
        accountCheckNumber: $accountCheckNumber
        accountNumber: $accountNumber
        agencyCheckNumber: $agencyCheckNumber
        agencyNumber: $agencyNumber
        bankNumber: $bankNumber
        sellerId: $sellerId
        type: $type
      }
    ) {
      id
    }
  }
`;

export { CREATE_BANK_ACCOUNT_MUTATION };
