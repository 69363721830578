import { Component } from '@angular/core';

@Component({
  selector: 'app-coupon-footer',
  templateUrl: './coupon-footer.component.html',
  styleUrls: ['./coupon-footer.component.scss'],
})
export class CouponFooterComponent {
  public goToOutside(type: string): void {
    const urls = {
      ABOUT: 'https://gen.com.br/#quem-somos',
      PRIVACY: 'https://gen.com.br/politica-de-privacidade',
      CAREERS: 'https://apply.workable.com/sejagen/#jobs',
      TERMS: 'https://gen.com.br/termos-e-condicoes',
      TALK: 'https://gen.com.br/#fale-com-a-gente',
      FACEBOOK: 'https://www.facebook.com/sejagen',
      INSTAGRAM: 'https://www.instagram.com/sejagen',
      TIKTOK: 'https://www.tiktok.com/@sejagen',
      LINKEDIN: 'https://www.linkedin.com/company/seja-gen',
      ANDROID: 'https://play.google.com/store/apps/details?id=br.com.gen&hl=pt_BR&gl=US',
      APPLE: 'https://apps.apple.com/br/app/gen-promo%C3%A7%C3%B5es-e-descontos/id6443802221',
      QUESTIONS: 'https://resolve.gen.com.br/hc/pt-br',
    };

    window.open(urls[type], '_blank');
  }
}
