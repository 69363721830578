import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export class AddressNumberValidator {
  constructor() {}

  static isValid(): ValidatorFn {
    return (control: AbstractControl): Validators | null => {
      const number = control.value;
      const re = /^\d+(\w+)?$/g;

      if (number && !re.test(number)) {
        return { format: true };
      }

      return null;
    };
  }
}
