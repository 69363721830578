import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

import { CustomerModel } from '../../../../models/customer/customer.model';
import { CustomerService } from '../../../../services/customer/customer.service';

@Component({
  selector: 'app-drawer-customers-edit',
  templateUrl: './drawer-customers-edit.component.html',
  styleUrls: ['./drawer-customers-edit.component.scss'],
})
export class DrawerCustomersEditComponent implements OnInit {
  @Input() public customer: CustomerModel;

  public customerFormGroup: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private $customers: CustomerService,
    public readonly drawerRef: NzDrawerRef,
    private readonly $message: NzMessageService
  ) {}

  public ngOnInit(): void {
    this.createFormGroup();
    this.getData();
  }

  private createFormGroup(): void {
    this.customerFormGroup = this.fb.group({
      name: new FormControl<string>(''),
      birthDate: new FormControl<string>(''),
      nameMother: new FormControl<string>(''),
      email: new FormControl<string>(''),
      line1: new FormControl<string>(''),
      line2: new FormControl<string>(''),
      line3: new FormControl<string>(''),
      postalCode: new FormControl<string>(''),
      neighborhood: new FormControl<string>(''),
      city: new FormControl<string>(''),
      state: new FormControl<string>(''),
    });
  }

  private getData(): void {
    this.customerFormGroup.patchValue({
      name: this.customer?.name,
      birthDate: this.customer?.birthDate,
      nameMother: this.customer?.nameMother,
      email: this.customer?.email,
      line1: this.customer?.address?.line1,
      line2: this.customer?.address?.line2,
      line3: this.customer?.address?.line3,
      postalCode: this.customer?.address?.postalCode,
      neighborhood: this.customer?.address?.neighborhood,
      city: this.customer?.address?.city,
      state: this.customer?.address?.state,
    });
  }

  public editCustomer(): void {
    const updateCustomerParams = {
      name: this.customerFormGroup.get('name').value,
      birthDate: this.customerFormGroup.get('birthDate').value,
      nameMother: this.customerFormGroup.get('nameMother').value,
      email: this.customerFormGroup.get('email').value,
      address: {
        line1: this.customerFormGroup.get('line1').value,
        line2: this.customerFormGroup.get('line2').value,
        line3: this.customerFormGroup.get('line3').value,
        postalCode: this.customerFormGroup.get('postalCode').value,
        neighborhood: this.customerFormGroup.get('neighborhood').value,
        city: this.customerFormGroup.get('city').value,
        state: this.customerFormGroup.get('state').value,
      },
    };

    this.$customers.editCustomer(this.customer.id, updateCustomerParams).subscribe({
      next: (res) => {
        if (res) {
          this.$message.success('Usuário B2C editado com sucesso.');
          this.drawerRef.close();
        } else {
          this.$message.error('Não foi possível editar o usuário B2C.');
        }
      },
      error: (error) => {
        this.$message.error('Não foi possível editar o usuário B2C.');
        throw new Error(error);
      },
    });
  }
}
