import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import {
  Query,
  AngularFirestore,
  CollectionReference,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { Apollo } from 'apollo-angular';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { Message } from '../../utils/message';
import {
  GET_ORDER_QUERY,
  LIST_ORDERS_QUERY,
  LIST_SELLER_ORDERS,
  GET_PUBLIC_ORDER_QUERY,
} from '../../graphql/queries/orders.queries';
import {
  CREATE_ORDER,
  CREATE_ORDERS,
  DELETE_ORDER,
  UPDATE_ORDER,
  BATCH_DELETE_ORDERS,
  BATCH_CONFIRM_ORDER_PAYMENT,
} from '../../graphql/mutations/order.mutation';
import { environment } from '@gen/environments';
import { CartModel } from '../../models/cart/cart.model';
import { ItemModel } from '../../models/items/item.model';
import { BasketModel } from '../../models/cart/basket.model';
import { OrderModel } from '../../models/orders/order.model';
import { PaymentsService } from '../payments/payments.service';
import { CustomerService } from '../customer/customer.service';
import { InternalService } from '../internal/internal.service';
import { AddressModel } from './../../models/address/address.model';
import { PaymentsModel } from '../../models/payments/payments.model';
import { ResponseModel } from '../../models/response/response.model';
import { CustomerModel } from '../../models/customer/customer.model';
import { PaymentInfo } from '../../models/payments/payment-info.model';
import { PointModel } from './../../models/points/point-of-sales.model';
import { PointOfSalesService } from '../point-of-sales/point-of-sales.service';
import { ResponseFilterModel } from '../../models/filters/response-filter.model';
import { FilterOrdersParams } from '../../models/orders/filter-orders-params.model';
import { StateManagementService } from './../../state-management/state-management.service';

@Injectable()
export class OrdersService {
  public marketplaceId: string = environment.marketplaceId;

  constructor(
    private readonly router: Router,
    private readonly apollo: Apollo,
    private $methods: InternalService,
    private $payment: PaymentsService,
    public $customer: CustomerService,
    private $point: PointOfSalesService,
    private readonly $message: NzMessageService,
    private readonly fireStore: AngularFirestore,
    private $notification: StateManagementService,
    private readonly $device: DeviceDetectorService,
    private readonly $functions: AngularFireFunctions
  ) {}

  public getOrdersList(sellerId: string, type?: string): Observable<Array<OrderModel>> {
    if (type) {
      return this.fireStore
        .collection(`marketplaces/${this.marketplaceId}/sellers/${sellerId}/orders`, (ref) =>
          ref.where('type', '==', type).orderBy('insertedAt', 'desc')
        )
        .valueChanges() as Observable<Array<OrderModel>>;
    }

    return this._collection(this.marketplaceId, sellerId).valueChanges();
  }

  public getOrderDetail(sellerId: string, id: string): Observable<OrderModel> {
    return this._collection(this.marketplaceId, sellerId).doc(id).valueChanges();
  }

  public getPayments(sellerId: string, id: string): Observable<Array<PaymentsModel>> {
    return this.fireStore
      .collection<PaymentsModel>(`marketplaces/${this.marketplaceId}/sellers/${sellerId}/orders/${id}/payments`)
      .valueChanges();
  }

  private _collection(
    marketplaceId: string,
    sellerId: string,
    filter?: ResponseFilterModel,
    type?: string
  ): AngularFirestoreCollection<OrderModel> {
    if (filter && !isEmpty(filter)) {
      return this.fireStore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/orders`, (ref) => {
        let query: CollectionReference | Query = ref;

        if (filter.creationDateFilter) {
          if (filter.creationDateFilter.date) {
            const startDate: string = moment(filter.creationDateFilter.date)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();
            const endDate: string = moment(filter.creationDateFilter.date)
              .set({ hour: 23, minute: 59, second: 59 })
              .format();

            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else if (filter.creationDateFilter.startDate) {
            const startDate: string = moment(filter.creationDateFilter.startDate)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();
            const endDate: string = moment(filter.creationDateFilter.endDate)
              .set({ hour: 23, minute: 59, second: 59 })
              .format();

            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else {
            const date: string = moment()
              .subtract(filter.creationDateFilter.dateNumber, filter.creationDateFilter.datePeriod)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();

            query = query.where('insertedAt', '>=', date);
          }
        }

        if (filter.valuesFilter && !filter.creationDateFilter) {
          if (filter.valuesFilter.value) {
            switch (filter.valuesFilter.type) {
              case 0:
                query = query.where('totalCents', '==', filter.valuesFilter.value);
                break;
              case 2:
                query = query.where('totalCents', '>', filter.valuesFilter.value).orderBy('totalCents', 'asc');
                break;
              case 3:
                query = query.where('totalCents', '<', filter.valuesFilter.value).orderBy('totalCents', 'desc');
                break;
              default:
                break;
            }
          } else {
            query = query
              .where('totalCents', '>=', filter.valuesFilter.lowerValue)
              .where('totalCents', '<=', filter.valuesFilter.highestValue)
              .orderBy('totalCents', 'asc');
          }
        }

        if (filter.statusFilter) {
          query = query.where('status', 'in', filter.statusFilter);
        }

        if (type) {
          query = query.where('type', '==', type);
        }

        return query.orderBy('insertedAt', 'desc');
      });
    }

    if (type) {
      return this.fireStore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/orders`, (ref) =>
        ref.where('type', '==', type).orderBy('insertedAt', 'desc')
      );
    }

    return this.fireStore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/orders`, (ref) =>
      ref.orderBy('insertedAt', 'desc')
    );
  }

  public createOrderApollo(params: any): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_ORDER,
      variables: { params },
    });
  }

  public batchDeleteOrder(ordersIds: Array<string>): Observable<any> {
    return this.apollo.mutate({
      mutation: BATCH_DELETE_ORDERS,
      variables: { ordersIds },
    });
  }

  public batchConfirmOrderPayment(ordersIds: Array<string>, paymentMethod: string): Observable<any> {
    return this.apollo.mutate({
      mutation: BATCH_CONFIRM_ORDER_PAYMENT,
      variables: { ordersIds, paymentMethod },
    });
  }

  public createOrdersCheckout(params: any): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_ORDERS,
      variables: { params },
    });
  }

  public updateOrderApollo(params: any): Observable<any> {
    const orderId = JSON.parse(JSON.stringify(params.id));
    delete params.id;

    return this.apollo.mutate({
      mutation: UPDATE_ORDER,
      variables: { orderId, params },
    });
  }

  public createOrder(data: any): Observable<ResponseModel<OrderModel>> {
    return this.$functions.httpsCallable('createOrder')(data);
  }

  public updateOrder(data: any): Observable<ResponseModel<OrderModel>> {
    return this.$functions.httpsCallable('updateOrder')(data);
  }

  public deleteOrder(orderId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_ORDER,
      variables: { orderId },
    });
  }
  public batchDeleteOrders(orders: Array<OrderModel>): Observable<any> {
    return this.$functions.httpsCallable('batchDeleteOrders')(orders);
  }

  public batchUpdateOrders(orders: Array<Partial<OrderModel>>): Observable<any> {
    return this.$functions.httpsCallable('batchUpdateOrders')(orders);
  }

  public sendLinkSms(data: { phone: string; link: string; companyName: string }): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('sendStoreOrderLink')(data);
  }

  public newOrder(
    point: PointModel,
    basket: BasketModel,
    customer: CustomerModel,
    indexes: Array<number>,
    cart: CartModel
  ): boolean {
    if (basket.store) {
      let payload = {
        items: basket.items?.map((product) => ({
          description: product.name,
          unitPriceCents: product.price,
          quantity: product.quantity || 1,
        })),
        pointOfSaleId: point.id,
        deliveryMethod: cart.selectedType,
        deliveryPhone: customer.phone,
        deliveryContactName: customer.name,
        sellerId: basket.sellerId,
        type: 'ORDER',
      };

      if (point.customerId) {
        payload = Object.assign(payload, { customerId: point.customerId });
      } else {
        // fluxo não autenticado
      }

      if (cart.selectedType === 'DELIVERY') {
        payload = Object.assign(payload, { shippingAddress: customer.address });
      } else {
        const address: AddressModel = {
          line1: basket.store.pointOfSale?.line1,
          line2: basket.store.pointOfSale?.line2,
          line3: basket.store.pointOfSale?.line3,
          neighborhood: basket.store.pointOfSale?.neighborhood,
          state: basket.store.pointOfSale?.state,
          city: basket.store.pointOfSale?.city,
          postalCode: basket.store.pointOfSale?.postalCode,
          countryCode: 'BR',
        };

        payload = Object.assign(payload, { shippingAddress: address });
      }

      this.createOrder(payload).subscribe((response) => {
        if (response.status === 201) {
          basket.orderId = response.body.id;
          (basket.order = response.body), (basket.needUpdate = false);
          basket.totalValue = response.body.totalCents;

          cart.orders.push(response.body.id);

          this.$point.updateCart(point.id, cart.id, cart);
          this.$point.updateBasket(point.id, point.cartId, basket);
        } else {
          this.$message.error('Erro ao criar o pedido, tenta novamente mais tarde.');
        }

        if (indexes[0] === indexes[1]) {
          this.$notification.setLoading(false);
        }
      });

      return true;
    }
    this.$message.error('Não foi possível criar pedido, esse seller não possui uma loja configurada!');
    return false;
  }

  public updateProductOrder(
    point: PointModel,
    basket: BasketModel,
    customer: CustomerModel,
    indexes: Array<number>,
    cart: CartModel
  ): boolean {
    this.$notification.setLoading(true);

    let payload = {
      id: basket.orderId,
      items: basket.items?.map((product) => ({
        description: product.name,
        unitPriceCents: product.price,
        quantity: product.quantity || 1,
      })),
      pointOfSaleId: point.id,
      deliveryMethod: cart.selectedType,
      deliveryPhone: customer.phone,
      deliveryContactName: customer.name,
      sellerId: point.sellerId,
      type: 'ORDER',
    };

    if (point.customerId) {
      payload = Object.assign(payload, { customerId: point.customerId });
    }

    if (cart.selectedType === 'DELIVERY') {
      payload = Object.assign(payload, { shippingAddress: customer.shippingAddress });
    } else {
      const address: AddressModel = {
        line1: basket.store.pointOfSale?.line1,
        line2: basket.store.pointOfSale?.line2,
        line3: basket.store.pointOfSale?.line3,
        neighborhood: basket.store.pointOfSale?.neighborhood,
        state: basket.store.pointOfSale?.state,
        city: basket.store.pointOfSale?.city,
        postalCode: basket.store.pointOfSale?.postalCode,
        countryCode: 'BR',
      };
      payload = Object.assign(payload, { shippingAddress: address });
    }

    this.updateOrder(payload).subscribe((response) => {
      if (response.status === 200) {
        basket.orderId = response.body.id;
        basket.needUpdate = false;
        basket.totalValue = response.body.totalCents;

        this.$point.updateBasket(point.id, point.cartId, basket);
      } else {
        this.$message.error('Erro ao atualizar o pedido, tenta novamente mais tarde.');
      }

      if (indexes[0] === indexes[1]) {
        this.$notification.setLoading(false);
      }
    });

    return true;
  }

  public applyCoupon(data: any): Observable<ResponseModel<any>> {
    return this.$functions.httpsCallable('applyCoupon')(data);
  }

  public getPublicOrderDetail(id: string): Observable<OrderModel> {
    return this.getOrderFirebase(id);
  }

  private getOrderFirebase(id: string): Observable<OrderModel> {
    return this._publicCollection().doc(id).valueChanges();
  }

  private _publicCollection(): AngularFirestoreCollection<OrderModel> {
    return this.fireStore.collection(`marketplaces/${environment.marketplaceId}/orders`, (ref) =>
      ref.orderBy('insertedAt', 'desc')
    );
  }

  public onPayOrder(payload: any, paymentInfo: PaymentInfo): void {
    this.$payment.createCustomerPayment(payload).subscribe({
      next: (response) => {
        if (response) {
          const payment = response.data.createCustomerPayment;

          if (response.data.createCustomerPayment) {
            paymentInfo.payment = payment;

            if (payment?.status === 'PENDING' || payment?.status === 'WAITING') {
              this.$notification.setPaymentInfo(paymentInfo);

              if (payment.method === 'BANK_SLIP') {
                this.$notification.setTypeResult('BANK_SLIP');
              } else if (payment.method === 'MONEY_EXTERNAL' || payment.method === 'DEBIT_EXTERNAL') {
                this.$notification.setTypeResult('RECEIPT');
              } else {
                this.$notification.setTypeResult('PIX');
              }
            }

            if (payment?.status === 'CANCELLED') {
              this.$methods.treatError(null);
            }

            if (payment?.status === 'PAID') {
              this.$notification.setPayment(response.data.createCustomerPayment);
              this.$notification.setPaymentInfo(paymentInfo);

              if (paymentInfo.redirectTo) {
                window.location.href = paymentInfo.redirectTo;
              } else {
                this.$notification.setTypeResult('RECEIPT');
              }
            }
          } else {
            this.$methods.treatError(response.errors);
          }
        }

        this.$notification.setLoading(false);
      },
      error: (error) => {
        this.$methods.treatError(error);
        this.$notification.setLoading(false);
        throw new Error(error);
      },
    });
  }

  public createPayload(info: PaymentInfo): any {
    const deviceInfo = this.$device.getDeviceInfo();

    let data: any = {
      orderId: info?.order?.id,
      method: info?.method,
      sessionId: uuidv4(),
      externalId: info?.externalId,
      device: {
        userAgent: `${deviceInfo.browser} (${deviceInfo.browser_version})`,
        model: deviceInfo.device,
        platform: `${deviceInfo.os} (${deviceInfo.os_version})`,
        ip: null,
      },
    };

    if (info.location) {
      data = Object.assign(data, {
        location: {
          longitude: info.location.longitude,
          latitude: info.location.latitude,
        },
      });

      data.device.ip = info.location.ip;
    }

    data.device = JSON.stringify(data.device);

    if (info.customer?.id && info.method === 'PIX') {
      data = Object.assign(data, { customerId: info.customer.id });
    } else {
      if (info.method !== 'PIX') {
        delete info.customer.id;
      }

      let customer: any = info.customer;

      if (customer && customer.address && customer.address.__typename) {
        delete customer.__typename;
      }
      data = Object.assign(data, { customer: customer });
    }

    if (info.method === 'CREDIT_CARD') {
      if (info.creditCard?.id) {
        data = Object.assign(data, {
          credit: {
            creditCardId: info?.creditCard.id,
            creditCard: {
              securityCode: info?.creditCard?.securityCode,
            },
          },
        });
      } else {
        data = Object.assign(data, {
          credit: {
            transactionType: info.installments > 1 ? 'INSTALL_NO_INTEREST' : 'FULL',
            installments: Number(info.installments),
            creditCard: {
              token: info.token,
              holderName: info.customer?.name,
              expirationMonth: info.creditCard?.expirationMonth,
              expirationYear: info.creditCard?.expirationYear,
              securityCode: info.creditCard?.securityCode,
            },
          },
        });
      }
    } else {
      if (info.method === 'PIX') {
        const today = moment();
        let expirationDate = moment(info?.order.expirationDate);

        if (info.order?.expirationDate === today.format('YYYY-MM-DD')) {
          expirationDate = moment(`${info.order?.expirationDate}T${moment(today).format('HH:mm')}`)
            .add(3, 'hours')
            .add(5, 'minutes');
        }

        data = Object.assign(data, {
          pix: {
            expiration: info.order?.expirationDate
              ? expirationDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
              : moment().add(3, 'hours').add(5, 'minutes').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          },
        });
      } else {
        data = Object.assign(data, {
          bankSlip: {
            expirationDate: info.order?.expirationDate
              ? moment(info.order?.expirationDate).format('YYYY-MM-DD')
              : moment().format('YYYY-MM-DD'),
          },
        });
      }
    }

    return data;
  }

  public createPayloadOrder(paymentInfo: PaymentInfo): Partial<OrderModel> {
    let payload: Partial<OrderModel> = {
      sellerId: paymentInfo.seller?.id || paymentInfo.item?.sellerId || paymentInfo.order?.sellerId,
      type: 'ORDER',
      channel: 'IN_STORE',
      methods: [paymentInfo.method as any],
    };

    if (paymentInfo.order?.id) {
      payload = Object.assign(payload, { id: paymentInfo?.order?.id });
      payload = Object.assign(payload, { items: paymentInfo?.order?.items });
    }

    if (paymentInfo.item?.id) {
      const items = [
        {
          itemId: paymentInfo.item.id,
          description: paymentInfo.item.name,
          quantity: paymentInfo.item.quantity || 0,
          unitPriceCents: paymentInfo.item.itemUnits[0].price,
        },
      ];

      payload = Object.assign(payload, { items: items });
    }

    if (paymentInfo.customer?.id) {
      payload = Object.assign(payload, { customerId: paymentInfo?.customer?.id });
    } else {
      if (paymentInfo.customer?.name) {
        payload = Object.assign(payload, { customer: paymentInfo.customer });
      }
    }

    return payload;
  }

  public createPayloadOrders(paymentInfo: PaymentInfo): { orders: any[]; customer: CustomerModel } {
    let payload: Partial<OrderModel> = {
      sellerId: paymentInfo.seller?.id || paymentInfo.item?.sellerId || paymentInfo.order?.sellerId,
      type: 'ORDER',
      channel: 'IN_STORE',
      methods: paymentInfo.paymentMethods,
    };

    let customer: any = paymentInfo.customer;

    if (customer && customer.address && customer.address.__typename) {
      delete customer.__typename;
    }

    if (paymentInfo.item?.id) {
      const items = [
        {
          // itemId: paymentInfo.item.id,
          description: paymentInfo.item.name,
          quantity: paymentInfo.item.quantity || 1,
          unitPriceCents: paymentInfo.item.itemUnits[0].price,
        },
      ];

      payload = Object.assign(payload, { items: items });
    } else {
      const items = [
        {
          description: paymentInfo.paymentLink?.description || 'Pagamento Avulso',
          quantity: 1,
          unitPriceCents: paymentInfo.paymentLink?.amountCents || paymentInfo.amountCents,
        },
      ];

      payload = Object.assign(payload, { items: items });
    }

    if (paymentInfo.order?.id) {
      payload = Object.assign(payload, { id: paymentInfo?.order?.id });
      payload = Object.assign(payload, { items: paymentInfo?.order?.items });
    }

    return { orders: [payload], customer };
  }

  public updateAndDoAction(paymentInfo: PaymentInfo, isCoupon?: boolean): void {
    try {
      let payload = this.createPayloadOrder(paymentInfo);

      this.updateOrder(payload).subscribe({
        next: (response) => {
          if (response.status === 200) {
            paymentInfo.order = response.body;

            if (isCoupon) {
              this.$notification.setOrder(response.body);
              this.$notification.setActiveCoupon(paymentInfo.coupon?.code);
            } else {
              const payload = this.createPayload(paymentInfo);
              this.onPayOrder(payload, paymentInfo);
            }
          } else {
            this.$methods.treatError(response.error);
          }
        },
        error: (error) => {
          this.$notification.setTypeResult('ERROR');
          this.$notification.setErrorMesage(JSON.stringify(error));
          this.$notification.setLoading(false);
          throw new Error(error);
        },
      });
    } catch (error: any) {
      this.$notification.setTypeResult('ERROR');
      this.$notification.setErrorMesage(JSON.stringify(error));
      this.$notification.setLoading(false);
      throw new Error(error);
    }
  }

  public createItemCheckoutOrder(paymentInfo: PaymentInfo, isCoupon: boolean): void {
    try {
      let payload = this.createPayloadOrders(paymentInfo);

      this.createOrdersCheckout(payload).subscribe({
        next: (response) => {
          if (response.data.createOrders && response.data.createOrders.length > 0) {
            const order = response.data.createOrders[0];

            if (isCoupon) {
              this.$notification.setActiveCoupon(paymentInfo.coupon?.code);
              this.router.navigate(['external/order/' + response.body.id]);
            } else {
              paymentInfo.order = order;

              const payload = this.createPayload(paymentInfo);
              this.onPayOrder(payload, paymentInfo);
              this.$notification.setPaymentInfo(paymentInfo);
            }
          } else {
            this.$methods.treatError(response.error);
          }
        },
        error: (error) => {
          this.$methods.treatError(error);
          this.$notification.setLoading(false);
          throw new Error(error);
        },
      });
    } catch (error: any) {
      this.$methods.treatError(error);
      this.$notification.setLoading(false);
      throw new Error(error);
    }
  }

  public getTotalValues(products: Array<ItemModel>): number {
    return products.reduce((acc, product) => (acc += product.unitPriceCents * product.quantity), 0);
  }

  public getOrderListWithCustomer(customerId: string, marketplaceId: string, sellerId: string): Observable<any> {
    return this.fireStore
      .collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/orders`, (ref) =>
        ref.where('customerId', '==', customerId).where('type', '==', 'INVOICE').orderBy('insertedAt', 'desc')
      )
      .valueChanges();
  }

  public getOrdersWithFilters(sellerId: string, filter: ResponseFilterModel, type?: string): Observable<OrderModel[]> {
    return this._collection(this.marketplaceId, sellerId, filter, type).valueChanges();
  }

  public deleteOrders(data: Array<OrderModel>): void {
    try {
      this.$notification.setLoading(true);

      this.batchDeleteOrders(data).subscribe((response) => {
        if (response.status !== 200) {
          this.$message.error('Ocorreu um erro ao excluir os pedidos. Por favor, tente novamente mais tarde.');
        }
        this.$message.success(data?.length > 1 ? 'Pedidos excluídos com sucesso!' : 'Pedido excluído com sucesso!');
        this.$notification.setLoading(false);
      });
    } catch (error: any) {
      this.$message.error('Ocorreu um erro ao excluir os pedidos. Por favor, tente novamente mais tarde.');
      this.$notification.setLoading(false);
      throw new Error(error);
    }
  }

  public setPayments(method: string, data: Array<OrderModel>): void {
    try {
      this.$notification.setLoading(true);

      const payload: any = data.map((order) => ({
        sessionId: order.id,
        orderId: order.id,
        method,
        sellerId: order.sellerId,
        customerId: order.customerId,
      }));

      this.$payment.batchCreatePayments(payload).subscribe((response) => {
        if (response.status === 200 && response.body[0]?.status === 'PENDING' && response.body[0]?.id) {
          this.$payment.batchConfirmPayments(response.body).subscribe((confirm) => {
            if (confirm.status === 200 && confirm.body[0]?.id) {
              this.$message.success('Registro de pagamento realizado com sucesso!');
              this.$notification.setLoading(false);
            }
            this.$notification.setLoading(false);
            this.$message.error('Não foi possível concluir o pagamento. Tente novamente em alguns minutos.');
          });
        }

        this.$notification.setLoading(false);
        this.$message.error('Não foi possível concluir o pagamento. Tente novamente em alguns minutos.');
      });
    } catch (error: any) {
      this.$message.error(Message.ERROR_CONFIRM_PAYMENT);
      this.$notification.setLoading(false);
      throw new Error(error);
    }
  }

  public getOrderList(filter?: FilterOrdersParams): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_ORDERS_QUERY,
      variables: { filter },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getSellerOrdersList(sellerId: string, filter?: FilterOrdersParams): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_SELLER_ORDERS,
      variables: { sellerId, filter },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getOrderById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_ORDER_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getPublicOrderById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_PUBLIC_ORDER_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
}
