import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { StateManagementService } from '../../state-management/state-management.service';
import { PointModel } from '../../models/points/point-of-sales.model';
import { CouponService } from '../../services/products/coupon/coupon.service';
import { take } from 'rxjs';
import { isString } from 'lodash';

@Component({
  selector: 'app-checkout-coupon',
  templateUrl: './checkout-coupon.component.html',
  styleUrls: ['./checkout-coupon.component.scss'],
})
export class CheckoutCouponComponent implements OnInit {
  public loading: boolean = false;
  public couponCode: string = '';
  public formCoupon!: FormGroup;
  public point: PointModel;
  public validatedCoupon: boolean;
  public showSubmitButton: boolean = false;

  @Output() public updatedPoint = new EventEmitter<PointModel>();

  constructor(
    private readonly fb: FormBuilder,
    private $message: NzMessageService,
    private $notification: StateManagementService,
    private $coupon: CouponService
  ) {}

  public ngOnInit(): void {
    this.getPoint();
    this.createForm();
    this.getValuesChange();
  }

  public createForm(): void {
    this.formCoupon = this.fb.group({
      coupon: new FormControl(''),
    });
  }

  public getPoint(): void {
    this.$notification.points.subscribe((res) => {
      if (res) {
        this.point = res;
      }
    });
  }

  public getValuesChange(): void {
    this.formCoupon.get('coupon').valueChanges.subscribe((coupon) => {
      if (coupon.length) {
        this.showSubmitButton = true;
      } else {
        this.showSubmitButton = false;
      }
    });
  }

  public applyCoupon(): void {
    this.loading = true;
    try {
      const payload = {
        sellerId: this.point.cart.baskets[0]?.seller?.id,
        orderId: this.point.cart.baskets[0].orderId,
        code: this.formCoupon.get('coupon').value?.toUpperCase(),
      };

      this.$coupon
        .applyCoupon(payload)
        .pipe(take(1))
        .subscribe({
          next: (data) => {
            if (data.status === 201) {
              this.validatedCoupon = true;
              this.$message.success('Cupom aplicado!');
              this.formCoupon.get('coupon').markAsTouched();
              this.formCoupon.get('coupon').disable();
              this.showSubmitButton = false;
              this.point.cart.baskets[0].coupon = data.body;
              this.updatedPoint.emit(this.point);
            } else {
              const reason =
                this.$coupon.getReason(
                  isString(data.body.errors) ? data.body.errors : JSON.stringify(data.body.errors)
                ) || 'Erro ao aplicar o cupom';

              this.$message.error(reason);
            }

            this.loading = false;
          },
          error: (error) => {
            this.formCoupon.get('coupon').setErrors({ notFound: true });
            this.formCoupon.get('coupon').markAsTouched();
            this.loading = false;

            throw new Error(error);
          },
        });
    } catch (error: any) {
      this.$message.error('Erro ao aplicar o cupom. Por favor, tente novamente mais tarde.');
      this.loading = false;
      throw new Error(error);
    }
  }
}
