import { Observable } from 'rxjs';
import { Injectable, Query } from '@angular/core';
import { Apollo, Mutation } from 'apollo-angular';

import {
  CREATE_BLACKLIST_ITEM_MUTATION,
  UPDATE_BLACKLIST_ITEM_MUTATION,
} from '../../graphql/mutations/blacklist.mutations';
import { BlacklistModel } from '../../models/blacklist/blacklist.model';
import { FilterBlacklistParams } from '../../models/blacklist/filter-blacklists-params.model';
import { GET_BLACKLIST_QUERY, LIST_BLACKLIST_QUERY } from '../../graphql/queries/blacklist.queries';

@Injectable()
export class BlacklistService {
  constructor(private readonly apollo: Apollo) {}

  public getListBlacklist(filters: FilterBlacklistParams): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_BLACKLIST_QUERY,
      variables: { filters },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getBlacklistById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_BLACKLIST_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public createBlacklistItem(data: BlacklistModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_BLACKLIST_ITEM_MUTATION,
      variables: {
        cnpj: data.cnpj,
        cpf: data.cpf,
        email: data.email,
        phone: data.phone,
        source: data.source,
      },
    });
  }

  public updateBlacklistItem(id, data: BlacklistModel): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_BLACKLIST_ITEM_MUTATION,
      variables: {
        id: id,
        cnpj: data.cnpj,
        cpf: data.cpf,
        email: data.email,
        phone: data.phone,
        source: data.source,
      },
    });
  }
}
