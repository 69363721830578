import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Mutation } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { environment } from '@gen/environments';
import {
  GET_SELLER,
  LIST_SELLERS_QUERY,
  GET_SELLER_BUSINESS,
  GET_SELLER_DOCUMENTS,
  LIST_PLATFORM_TERMS_QUERY,
  GET_LAST_PLATFORM_TERM_QUERY,
  GET_EXTERNAL_CONSULT_DETAILS_QUERY,
} from '../../graphql/queries/seller.queries';
import {
  BAN_SELLER_MUTATION,
  EDIT_SELLER_MUTATION,
  BLOCK_SELLER_MUTATION,
  DECLINE_SELLER_MUTATION,
  UNBLOCK_SELLER_MUTATION,
  APPROVE_SELLER_MUTATION,
  REPROCESS_SELLER_MUTATION,
  UPDATE_STORE_PREFERENCES_MUTATION,
  ACCEPT_SAAS_PLATFORM_TERM_MUTATION,
  CREATE_PLATFORM_TERM_MUTATION,
  UPLOAD_SELLER_DOCUMENT_MUTATION,
} from '../../graphql/mutations/seller.mutations';
import { SellerModel } from '../../models/sellers/sellers.model';
import { ResponseModel } from '../../models/response/response.model';
import { FilterSellersParams } from '../../models/sellers/filter-sellers-params.model';
import { StateManagementService } from '../../state-management/state-management.service';

@Injectable()
export class SellersService {
  public marketplaceId?: string = environment.marketplaceId;
  public sellerSetted: boolean = false;

  constructor(
    private readonly apollo: Apollo,
    private $storage: AngularFireStorage,
    private $functions: AngularFireFunctions,
    private readonly fireStore: AngularFirestore,
    private readonly $notification: StateManagementService
  ) {}

  public getSellerImageURL(sellerId: string): Observable<string | undefined> {
    return this.$storage.ref(`sellers/${sellerId}/avatar`).getDownloadURL();
  }

  public getAndSetSellerImageUrl(seller: SellerModel): void {
    this.$storage
      .ref(`sellers/${seller.id!}/avatar`)
      .getDownloadURL()
      .subscribe((imageUrl) => {
        this.$notification.setSellerImageUrl(imageUrl);
      });
  }

  public getSellerImageBannerURL(sellerId: string): Observable<string | undefined> {
    return this.$storage.ref(`sellers/${sellerId}/banner`).getDownloadURL();
  }

  public getSeller(sellerId?: string): Observable<SellerModel> | undefined {
    return this.fireStore
      .doc(`marketplaces/${this.marketplaceId}/sellers/${sellerId}`)
      .valueChanges() as Observable<SellerModel>;
  }

  public setSellerInfo(sellerId: string): void {
    if (sellerId && sellerId !== undefined && !this.sellerSetted) {
      this.sellerSetted = true;
      const seller = new SellerModel();
      seller.id = sellerId;
      this.$notification.setSeller(seller);
    }
  }

  public updateExternalSeller(data: Partial<SellerModel>): Observable<ResponseModel<SellerModel>> {
    return this.$functions.httpsCallable('updateSeller')(data);
  }

  public sellerWithEmailExists(email: string): Observable<ResponseModel<SellerModel>> {
    return this.$functions.httpsCallable('sellerWithEmailExists')(email);
  }

  public uploadSellerDocuments(params: any): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPLOAD_SELLER_DOCUMENT_MUTATION,
      variables: { params },
    });
  }

  public getSellerDocuments(id: string): Observable<ApolloQueryResult<SellerModel>> {
    return this.apollo.watchQuery<SellerModel>({
      query: GET_SELLER_DOCUMENTS,
      fetchPolicy: 'cache-and-network',
      variables: { id },
    }).valueChanges;
  }

  public updateSeller(sellerId: string, data: Partial<SellerModel>): void {
    this.fireStore.collection(`marketplaces/${this.marketplaceId}/sellers`)?.doc(sellerId).set(data, { merge: true });
  }

  public getSellerImageLogoURL(sellerId: string): Observable<string | undefined> {
    return this.$storage.ref(`sellers/${sellerId}/avatar`).getDownloadURL();
  }

  public getSellerList(filter: FilterSellersParams): Observable<ApolloQueryResult<SellerModel>> {
    return this.apollo.watchQuery<SellerModel>({
      query: LIST_SELLERS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { filter },
    }).valueChanges;
  }

  public getSellerById(id: string): Observable<ApolloQueryResult<SellerModel>> {
    return this.apollo.watchQuery<SellerModel>({
      query: GET_SELLER,
      fetchPolicy: 'cache-and-network',
      variables: { id },
    }).valueChanges;
  }

  public getSellerByIdBusiness(id: string): Observable<ApolloQueryResult<SellerModel>> {
    return this.apollo.watchQuery<SellerModel>({
      query: GET_SELLER_BUSINESS,
      fetchPolicy: 'cache-and-network',
      variables: { id },
    }).valueChanges;
  }

  public listLastTerm(): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery<any>({
      query: GET_LAST_PLATFORM_TERM_QUERY,
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public listTerms(filters: any): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery<any>({
      query: LIST_PLATFORM_TERMS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { filters },
    }).valueChanges;
  }

  public getExternalConsultDetails(id: string): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery<any>({
      query: GET_EXTERNAL_CONSULT_DETAILS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { id },
    }).valueChanges;
  }

  public banSeller(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: BAN_SELLER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public blockSeller(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: BLOCK_SELLER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public unblockSeller(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UNBLOCK_SELLER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public reprocessSeller(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: REPROCESS_SELLER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public approveSeller(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: APPROVE_SELLER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public declineSeller(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: DECLINE_SELLER_MUTATION,
      variables: {
        id,
      },
    });
  }

  public editSeller(id: string, params: any): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: EDIT_SELLER_MUTATION,
      variables: {
        id,
        params,
      },
    });
  }

  public updateStorePreferences(sellerId: string, params: any): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_STORE_PREFERENCES_MUTATION,
      variables: {
        params,
        sellerId,
      },
    });
  }

  public acceptTerm(sellerId: string, platformTermId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: ACCEPT_SAAS_PLATFORM_TERM_MUTATION,
      variables: {
        sellerId,
        platformTermId,
      },
    });
  }

  public createTerm(params: any): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_PLATFORM_TERM_MUTATION,
      variables: {
        params,
      },
    });
  }
}
