import { NzMessageService } from 'ng-zorro-antd/message';
import { mergeMap, of, Subject, Subscription } from 'rxjs';
import BankAccountValidator from 'br-bank-account-validator';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { trimData } from '../../../utils/utils';
import { Message } from '../../../utils/message';
import { BankModel } from '../../../models/bank/bank.model';
import { BankService } from '../../../services/bank/bank.service';
import { SellerModel } from '../../../models/sellers/sellers.model';
import { UserClaimsModel } from '../../../models/user-claims/user-claims.model';
import { AuthService } from '../../../services/auth/auth-business/auth.service';
import { OnboardingService } from '../../../services/onboarding/onboarding.service';
import { StateManagementService } from '../../../state-management/state-management.service';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss'],
})
export class BankComponent implements OnInit, OnDestroy {
  @Input() onSubmit: Subject<boolean>;

  public user: UserClaimsModel = new UserClaimsModel();
  public seller: SellerModel = new SellerModel();
  public gutter: any = { xs: 8, sm: 16, md: 24, lg: 32 };
  public formGroup!: FormGroup;
  public banks: BankModel[] = [];
  public minAccountMask: string = '';
  public accountMask: string = '';
  public accountCheckNumberMask: string = '';
  public agencyMask: string = '';
  public agencyCheckNumberMask: string = '';
  public bankName: string = '';
  public accountErrorMsg: string = '';
  public agencyErrorMsg: string = '';
  private subscription!: Subscription;

  constructor(
    private $bank: BankService,
    private $auth: AuthService,
    private readonly fb: FormBuilder,
    private $onboarding: OnboardingService,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.$auth.refreshUser(); // Refresh token

    this.createForm();
    this.getUser();
    this.getBanks();
    this.getDashboardActivationCollection();
    this.getSeller();

    this.formGroup.valueChanges.subscribe((data) => {
      this.$notification.setOnboardingBtnDisabled(this.formGroup.invalid);
    });

    this.subscription = this.onSubmit.subscribe((v) => {
      if (v) {
        if (this.formGroup.valid) {
          this.saveData();
        } else {
          Object.values(this.formGroup.controls).forEach((control) => {
            if (control.invalid) {
              control.markAsDirty();
              control.updateValueAndValidity({ onlySelf: true });
            }
          });
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private getSeller(): void {
    this.$notification.sellers.subscribe((res) => {
      if (res) {
        this.seller = res;
      }
    });
  }

  private getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res) {
        this.user = res;
      }
    });
  }

  private getBanks(): void {
    this.$bank.getBanks().subscribe((banks) => {
      if (banks && banks.length > 0) {
        this.banks = banks;
      }
    });
  }

  private getDashboardActivationCollection(): void {
    this.$notification.dashboardActivationsCollection.subscribe((collection) => {
      if (collection) {
        const bankData = collection.bankData?.data;

        // To know if there is data on bank collection.
        if (bankData?.agency) {
          this.formGroup.patchValue({
            agency: bankData.agency,
            account: bankData.account,
            accountType: bankData.accountType,
            bank: bankData.bank,
            accountCheckNumber: bankData.accountCheckNumber,
            agencyCheckNumber: bankData.agencyCheckNumber,
          });
        }
      }

      this.$notification.setOnboardingBtnDisabled(this.formGroup.invalid);
    });
  }

  private saveData(): void {
    try {
      this.$notification.setOnboardingBtnLoading(true);

      BankAccountValidator.validate({
        bankNumber: this.formGroup.get('bank').value,
        agencyNumber: this.formGroup.get('agency').value,
        agencyCheckNumber: this.formGroup.get('agencyCheckNumber').value,
        accountNumber: this.formGroup.get('account').value,
        accountCheckNumber: this.formGroup.get('accountCheckNumber').value,
      });

      if (
        this.formGroup.get('agencyCheckNumber').value.toLowerCase() === 'x' ||
        this.formGroup.get('agencyCheckNumber').value === ''
      ) {
        this.formGroup.get('agencyCheckNumber').setValue('0');
      }

      const payload = {
        ...trimData(this.formGroup.value),
        sellerId: this.seller.id,
      };

      this.$onboarding
        .createBankAccount(payload)
        .pipe(
          mergeMap((res) => {
            if (res?.data?.createBankAccount?.id) {
              this.$onboarding.updateDashboardFirebase('stage', 2);
              return this.$onboarding.updateContactOnHubspot('COMPLIANCE');
            } else {
              this.$message.error(Message.CREATED_BANK_ERROR);
              return of(undefined);
            }
          })
        )
        .subscribe({
          next: async (response) => {
            if (response) {
              this.$auth.refreshUser(); // Refresh token

              await this.$onboarding.saveBankData(this.user, {
                done: true,
                data: payload,
              });
              this.$message.success(Message.BANK_ACCOUNT_CREATED);
            }

            this.$notification.setOnboardingBtnLoading(false);
          },
          error: (error) => {
            this.$notification.setOnboardingBtnLoading(false);
            this.$message.error(Message.CREATED_BANK_ERROR);
            throw new Error(error);
          },
        });
    } catch (error: any) {
      this.$notification.setOnboardingBtnLoading(false);
      this.$message.error(Message.DIGIT_INVALID_ACCOUNT);
      throw new Error(error);
    }
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      agency: new FormControl('', [Validators.required]),
      account: new FormControl('', [Validators.required]),
      accountType: new FormControl('', [Validators.required]),
      bank: new FormControl('', [Validators.required]),
      accountCheckNumber: new FormControl('', [Validators.required]),
      agencyCheckNumber: new FormControl(''),
    });
  }

  public validateBankForm(option: string): void {
    this.accountMask = '';
    this.agencyMask = '';
    this.accountCheckNumberMask = '';
    this.agencyCheckNumberMask = '';
    this.minAccountMask = '';

    const bank: BankModel = this.banks.find((bank) => bank.code === option);

    this.bankName = bank?.name || '';

    if (bank?.agencyMask) {
      this.formGroup.controls['agency'].setValidators([
        Validators.required,
        Validators.minLength(bank.agencyMask),
        Validators.maxLength(bank.agencyMask),
      ]);
      this.agencyMask = this.agencyMask.padStart(bank.agencyMask, '0');
      this.agencyErrorMsg = `A agência deve conter ${this.agencyMask.length} dígitos.`;
    } else {
      this.formGroup.controls['agency'].setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(4),
      ]);
      this.agencyMask = this.agencyMask.padStart(4, '0');
      this.agencyErrorMsg = 'A agência deve conter de 1 a 4 dígitos';
    }

    if (bank?.accountMask) {
      this.formGroup.controls['account'].setValidators([
        Validators.required,
        Validators.minLength(bank.minAccountMask ? bank.minAccountMask : bank.accountMask),
        Validators.maxLength(bank.accountMask),
      ]);
      this.minAccountMask = bank.minAccountMask ? this.minAccountMask.padStart(bank.minAccountMask, '0') : '';
      this.accountMask = this.accountMask.padStart(bank.accountMask, '0');
      this.accountErrorMsg = bank.minAccountMask
        ? `O numero da conta deve conter de
          ${this.minAccountMask.length} a
          ${this.accountMask.length} dígitos.`
        : `O número da conta deve conter ${this.accountMask.length} dígitos.`;
    } else {
      this.formGroup.controls['account'].setValidators([
        Validators.required,
        Validators.minLength(bank?.minAccountMask ? bank.minAccountMask : 1),
        Validators.maxLength(9),
      ]);
      this.accountMask = this.accountMask.padStart(9, '0');
      this.accountErrorMsg = bank?.minAccountMask
        ? `O numero da conta deve conter de ${this.minAccountMask.length} a 9 dígitos.`
        : 'O numero da conta deve conter de 1 a 9 dígitos.';
    }

    if (bank?.accountCheckNumberMask) {
      this.formGroup.controls['accountCheckNumber'].setValidators([
        Validators.required,
        Validators.minLength(bank.accountCheckNumberMask),
        Validators.maxLength(bank.accountCheckNumberMask),
      ]);
      this.accountCheckNumberMask = this.accountCheckNumberMask.padStart(bank.accountCheckNumberMask, '0');
    } else {
      this.formGroup.controls['accountCheckNumber'].setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1),
      ]);
      this.accountCheckNumberMask = '0';
    }

    if (bank?.agencyCheckNumberMask) {
      this.formGroup.controls['agencyCheckNumber'].setValidators([Validators.maxLength(bank.agencyCheckNumberMask)]);
      this.agencyCheckNumberMask = this.agencyCheckNumberMask.padStart(bank.agencyCheckNumberMask, 'A');
    } else {
      this.agencyCheckNumberMask = '0';
      this.formGroup.controls['agencyCheckNumber'].patchValue('');
    }

    this.formGroup.controls['agency'].updateValueAndValidity();
    this.formGroup.controls['account'].updateValueAndValidity();
    this.formGroup.controls['accountCheckNumber'].updateValueAndValidity();
  }

  public format(): void {
    if (this.bankName === 'BANCO DO BRASIL') {
      this.formGroup
        .get('account')
        .setValue(this.padLeft(this.formGroup.get('account').value, '0', this.accountMask.length));
    }
  }

  public numberAndXonly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode === 120) {
      return true;
    }

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  private padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size - text.length) + text).substring(size * -1, size);
  }
}
