import { gql } from "apollo-angular";

const LIST_SEGMENTATIONS_QUERY = gql`
  query Segmentations($sellerId: String, $startDate: DateTime) {
    segmentations(sellerId: $sellerId, startDate: $startDate) {
      id
      created_at
      updated_at
      name
      description
      contacts {
        id
        fullName
        created_at
        updated_at
        cpf
        cnpj
        email
        phoneNumber
        isLegalEntity
        businessName
        fantasyName
        addressId
        sellerId
        status
      }
      sellerId
      status
    }
  }
`;

const SEGMENTATION_QUERY = gql`
  query Segmentation($id: String!) {
    segmentation(id: $id) {
      id
      created_at
      updated_at
      name
      description
      contacts {
        id
        fullName
        created_at
        updated_at
        cpf
        cnpj
        email
        phoneNumber
        isLegalEntity
        businessName
        fantasyName
        addressId
        sellerId
        status
      }
      sellerId
      status
    }
  }
`;

export { LIST_SEGMENTATIONS_QUERY, SEGMENTATION_QUERY };
