export class AddressModel {
  public name?: string;
  public id?: string;
  public city: string;
  public countryCode?: string;
  public line1?: string; // Street
  public line2?: string; // Number
  public line3?: string; // Complement
  public neighborhood: string;
  public postalCode?: string;
  public postal_code?: string;
  public state?: string;
  public addressType?: string;
  public zipCode?: string;
  public street?: string;
  public country?: string;
  public number?: string;
  public complement?: string;
}
