import { gql } from 'apollo-angular';

const CREATE_BLACKLIST_ITEM_MUTATION = gql`
  mutation CreateBlacklistItem($cnpj: String, $cpf: String, $email: String, $phone: String, $source: String) {
    createBlacklistItem(params: { cnpj: $cnpj, cpf: $cpf, email: $email, phone: $phone, source: $source }) {
      id
      cnpj
      cpf
      email
      phone
      source
    }
  }
`;

const UPDATE_BLACKLIST_ITEM_MUTATION = gql`
  mutation UpdateBlacklistItem(
    $id: UUID4!
    $cnpj: String
    $cpf: String
    $email: String
    $phone: String
    $source: String
  ) {
    updateBlacklistItem(id: $id, params: { cnpj: $cnpj, cpf: $cpf, email: $email, phone: $phone, source: $source }) {
      id
      cnpj
      cpf
      email
      phone
      source
    }
  }
`;

export { CREATE_BLACKLIST_ITEM_MUTATION, UPDATE_BLACKLIST_ITEM_MUTATION };
