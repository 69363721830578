import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { interval, Observable, Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit, ViewChildren } from '@angular/core';

import { ModalChangeEmailComponent } from '../../modals/modal-change-email/modal-change-email.component';
import { AuthService } from '../../../services/auth/auth-business/auth.service';
import { CreateAccountModel } from '../../../models/create-account/create-account.model';
import { ForgotValidateCode } from '../../../models/forgot-password/forgotValidateCode.model';
import { shortenName } from '../../../utils/utils';

@Component({
  selector: 'app-validation-email',
  templateUrl: './validation-email.component.html',
  styleUrls: ['./validation-email.component.scss'],
})
export class ValidationEmailComponent implements OnInit {
  @ViewChildren('input') inputsList!: any;
  @Input() data: any;

  public timer: Observable<number>;
  public ispause = new Subject();
  public formCode: FormGroup;
  public createAccountPaylod: CreateAccountModel;
  public forgotPaylod: ForgotValidateCode;
  public countdown: number = 60;
  public loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private $auth: AuthService,
    private $modal: NzModalService,
    private $message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.sendVerifyEmailCode();
    this.getTimer();
  }

  public createForm(): void {
    this.formCode = this.fb.group({
      code1: ['', Validators.required],
      code2: ['', Validators.required],
      code3: ['', Validators.required],
      code4: ['', Validators.required],
      code5: ['', Validators.required],
      code6: ['', Validators.required],
    });
  }

  public getTimer(): void {
    this.timer = interval(1000).pipe(takeUntil(this.ispause)) as any;
    this.timer.subscribe({
      next: (_: number) => {
        if (this.countdown === 0) {
          this.ispause.next;
        }
        this.countdown--;
      },
    });
  }

  public onInput(event: any, index: number): void {
    if (event.data) {
      this.inputsList._results[index].nativeElement.focus();
    }
  }

  public onPressDeleteKey(event: KeyboardEvent, index: number): void {
    if (event.key === 'Backspace') {
      this.inputsList._results[index].nativeElement.focus();
    }
  }

  public async sendVerifyEmailCode(): Promise<void> {
    try {
      this.loading = true;
      await this.$auth
        .sendChangeSellerEmail({
          email: this.data.email,
          oldEmail: this.data.oldEmail,
          username: shortenName(this.data.person.name),
        })
        .then((data) => {
          if (data === 'Email inserido já existente.') {
            this.data.IsInvalidEmail = true;
            this.$modal.create({
              nzComponentParams: data,
              nzTitle: 'Alterar e-mail',
              nzWidth: '500px',
              nzContent: ModalChangeEmailComponent,
            });
          }
        });
    } catch (error: any) {
      throw new Error(error);
    } finally {
      this.countdown = 60;
      this.loading = false;
    }
  }

  public pasteNumeric(event: ClipboardEvent): boolean {
    if (event.clipboardData) {
      const code = event.clipboardData.getData('text').replace(/[^0-9]/g, '');

      this.formCode.setValue({
        code1: code[0] || '',
        code2: code[1] || '',
        code3: code[2] || '',
        code4: code[3] || '',
        code5: code[4] || '',
        code6: code[5] || '',
      });

      if (code.length === 6) {
        setTimeout(() => {
          this.saveEmail();
          event.preventDefault();
          return false;
        }, 500);
      }
    }

    return true;
  }

  public async saveEmail(): Promise<void> {
    this.loading = true;

    const code =
      this.formCode.value.code1 +
      this.formCode.value.code2 +
      this.formCode.value.code3 +
      this.formCode.value.code4 +
      this.formCode.value.code5 +
      this.formCode.value.code6;

    this.forgotPaylod = new ForgotValidateCode();
    this.forgotPaylod.code = code;
    this.forgotPaylod.email = this.data.email;
    this.forgotPaylod.type = 'CHANGE_EMAIL';
    this.forgotPaylod.sellerId = this.data.id;
    this.forgotPaylod.oldEmail = this.data.oldEmail;
    this.forgotPaylod.username = shortenName(this.data.company?.businessName || this.data.person?.name || '');

    this.$auth.validateEmailCode(this.forgotPaylod).subscribe((res) => {
      if (res.status === 200) {
        this.$auth.validatePassword(this.data.email, this.data.password);
        this.$message.success('Email atualizado com sucesso.');
        this.$modal.closeAll();
      } else {
        this.$message.error(res.error);
      }

      this.loading = false;
    });
  }
}
