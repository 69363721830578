import { gql } from 'apollo-angular';

const START_SELLER_ONBOARDING_MUTATION = gql`
  mutation StartSellerOnboarding($cpf: String!, $name: String!, $phoneNumber: String!) {
    startSellerOnboarding(cpf: $cpf, name: $name, phoneNumber: $phoneNumber)
  }
`;

const START_CUSTOMER_ONBOARDING_MUTATION = gql`
  mutation StartCustomerOnboarding($cpf: String!, $name: String!, $phoneNumber: String!) {
    startCustomerOnboarding(cpf: $cpf, name: $name, phoneNumber: $phoneNumber)
  }
`;

const FINISH_SELLER_ONBOARDING_MUTATION = gql`
  mutation FinishSellerOnboarding(
    $businessSegmentId: String
    $cnpj: String
    $company: CreateCompanyParams
    $cpf: String!
    $email: String
    $emailVerifiedAt: NaiveDateTime!
    $occupation: String
    $person: CreatePersonParams!
    $phone: String
    $termAcceptedAt: NaiveDateTime!
    $termVersion: String!
  ) {
    finishSellerOnboarding(
      params: {
        businessSegmentId: $businessSegmentId
        cnpj: $cnpj
        company: $company
        cpf: $cpf
        email: $email
        emailVerifiedAt: $emailVerifiedAt
        occupation: $occupation
        person: $person
        phone: $phone
        termAcceptedAt: $termAcceptedAt
        termVersion: $termVersion
      }
    ) {
      id
      person {
        name
        address {
          city
          state
          postalCode
        }
      }
      email
      status
      phone
      company {
        businessName
        cnpj
        address {
          city
          state
          postalCode
        }
      }
      occupation
    }
  }
`;

const FINISH_CUSTOMER_ONBOARDING_MUTATION = gql`
  mutation FinishCustomerOnboarding(
    $address: CreateAddressParams!
    $cpf: String!
    $name: String!
    $termAcceptedAt: NaiveDateTime!
    $termVersion: String!
  ) {
    finishCustomerOnboarding(
      params: { address: $address, cpf: $cpf, name: $name, termAcceptedAt: $termAcceptedAt, termVersion: $termVersion }
    ) {
      id
      email
      status
      phone
    }
  }
`;

export {
  START_SELLER_ONBOARDING_MUTATION,
  FINISH_SELLER_ONBOARDING_MUTATION,
  START_CUSTOMER_ONBOARDING_MUTATION,
  FINISH_CUSTOMER_ONBOARDING_MUTATION,
};
