import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

import { StateManagementService } from '../../../state-management/state-management.service';
import { CategoryModel } from '../../../models/categories/categories.model';
import { Message } from '../../../utils/message';
import { ItemsV2Model } from '../../../models/items/V2/ItemV2.model';
import { ItemsService } from '../../../services/items/items.service';

@Component({
  selector: 'app-modal-item-associate',
  templateUrl: './modal-item-associate.component.html',
  styleUrls: ['./modal-item-associate.component.scss'],
})
export class ModalItemAssociateComponent implements OnInit {
  @Input() public data: CategoryModel;
  public form!: FormGroup;

  public itemList: Array<ItemsV2Model> = [];
  public filteredItems: Array<ItemsV2Model> = [];
  public countItemsSelected: number = 0;
  public listLoading: boolean = true;

  constructor(
    private $items: ItemsService,
    private readonly fb: FormBuilder,
    public readonly $modalRef: NzModalRef,
    private readonly $msg: NzMessageService,
    private $notification: StateManagementService
  ) {}

  ngOnInit(): void {
    this.getNotification();
    this.createForm();
    this.getValuesChanges();
  }

  public createForm(): void {
    this.form = this.fb.group({
      search: new FormControl(''),
    });
  }

  public getValuesChanges(): void {
    this.form.get('search').valueChanges.subscribe((res) => {
      this.filterItems(res);
    });
  }

  public getNotification(): void {
    this.$notification.users.subscribe((res) => {
      if (res?.sellerId) {
        this.initList(res.sellerId);
      }
    });
  }

  public initList(sellerId: string): void {
    this.$items.getV2ItemList(sellerId)?.subscribe({
      next: (res) => {
        if (res?.data?.listV2Items) {
          const data = JSON.parse(JSON.stringify(res?.data.listV2Items));

          this.itemList = data?.map((item: any) => {
            item.selected === false;
            return item;
          });

          this.countItemsSelected = 0;
          this.filteredItems = this.itemList;
        }

        this.listLoading = false;
      },
      error: (error) => {
        this.listLoading = false;
        throw new Error(error);
      },
    });
  }

  public getCountItemsSelected(): number {
    return this.itemList.filter((item) => item.selected).length;
  }

  public filterItems(search: string): void {
    this.filteredItems = this.itemList.filter((item) =>
      search
        .toLowerCase()
        .split(' ')
        .every((v) => item.name.toLowerCase().includes(v))
    );
  }

  public onSelectItem(selectedItem: ItemsV2Model): void {
    if (selectedItem.selected) {
      this.itemList.map((item) => {
        if (item.id === selectedItem.id) {
          item.selected = false;
        }
      });
    } else {
      this.itemList.map((item) => {
        if (item.id === selectedItem.id) {
          item.selected = true;
        }
      });
    }

    this.countItemsSelected = this.getCountItemsSelected();
  }

  public setHandleSubmit(): void {
    const data = {
      id: this.data?.id,
      itemsId: this.itemList.filter((item) => item.selected).map((item) => item?.id),
    };

    if (data.itemsId.length === 0) {
      this.$msg.error(Message.CHOOSE_AT_LEAST_ONE_PRODUCT);
      return;
    }

    this.$modalRef.close(data);
  }
}
