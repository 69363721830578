import { v4 as uuidv4 } from 'uuid';
import { Injectable } from '@angular/core';
import { catchError, EMPTY, finalize, mergeMap, Observable, take } from 'rxjs';
import { Apollo, Query } from 'apollo-angular';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import {
  CREATE_ITEM_V2_MUTATION,
  DELETE_ITEM_V2_MUTATION,
  UPDATE_ITEM_UNIT_MUTATION,
  UPDATE_ITEM_V2_MUTATION,
} from '../../graphql/mutations/item.mutations';
import { environment } from '@gen/environments';
import {
  GET_ITEM_IMG_UPLOAD_URL,
  GET_ITEM_PUBLIC_V2,
  GET_ITEM_V2_PUBLIC_QUERY,
  GET_ITEM_V2_QUERY,
  LIST_ITEMS_V2_PUBLIC_QUERY,
  LIST_ITEMS_V2_QUERY,
} from '../../graphql/queries/item.queries';
import { StateManagementService } from '../../state-management/state-management.service';
import { Router } from '@angular/router';
import { AlertDuration, AlertType, StatusAlertModel } from '../../models/status/status-alert.model';
import { AlertService } from '../alert/alert.service';
import { CreateV2ItemParams, UpsertV2ItemImageParams } from '../../models/items/V2/createItemParams';
import { ItemsV2Model } from '../../models/items/V2/ItemV2.model';
import { CreateItemUnitParams } from '../../models/items/V2/createItemUnitParams';
import { HttpClient } from '@angular/common/http';
import { base64ToBlob } from '../../utils/files';
import { ErrorHandler } from '@apollo/client/link/error';
import { ErrorService } from '../error/error.service';
import { ItemImagesModel } from '../../models/items/item-images.model';
import { ItemVariantModel } from '../../models/items/V2/itemVariant.model';
@Injectable({
  providedIn: 'root',
})
export class ItemsService {
  public alertInfo: StatusAlertModel = new StatusAlertModel();
  public alertType: AlertType = new AlertType();
  public alertDuration: AlertDuration = new AlertDuration();

  constructor(
    public router: Router,
    private readonly apollo: Apollo,
    private $error: ErrorService,
    private readonly storage: AngularFireStorage,
    private $http: HttpClient,
    private readonly afFunc: AngularFireFunctions,
    private $notification: StateManagementService,
    private $alert: AlertService
  ) {}

  public getV2ItemList(filters: any = {}, pagination?: any): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_ITEMS_V2_QUERY,
      variables: { filters, pagination },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getV2PublicItemList(filters: any = {}, pagination?: any): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_ITEMS_V2_PUBLIC_QUERY,
      variables: { filters, pagination },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getItemV2ById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_ITEM_V2_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getItemImageUploadUrl(extension: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_ITEM_IMG_UPLOAD_URL,
      variables: { extension },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public getPublicItemV2ById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_ITEM_V2_PUBLIC_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  public createItemV2(params: CreateV2ItemParams): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_ITEM_V2_MUTATION,
      variables: { params },
    });
  }

  public updateV2Item(id: string, params: CreateV2ItemParams): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_ITEM_V2_MUTATION,
      variables: { id, params },
    });
  }

  public updateItemUnit(params: any): Observable<any> {
    const id = JSON.parse(JSON.stringify(params.id));
    delete params.id;

    return this.apollo.mutate({
      mutation: UPDATE_ITEM_UNIT_MUTATION,
      variables: { id, params },
    });
  }

  public updateActiveItem(id: string, itemUnits: CreateItemUnitParams[]): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_ITEM_V2_MUTATION,
      variables: { id, params: { itemUnits } },
    });
  }

  public deleteV2Item(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_ITEM_V2_MUTATION,
      variables: { id },
    });
  }

  public goToStore(sellerId: string): void {
    window.open(`${environment.pwaDomain}/internal/${sellerId}/store/products/list`);
  }

  public goToStoreProduct(sellerId: string, productId: string): void {
    window.open(this.getStoreProductUrl(sellerId, productId));
  }

  public getStoreProductUrl(sellerId: string, productId: string): string {
    return `${environment.pwaDomain}/internal/${sellerId}/store/products/details?productId=${productId}`;
  }

  public createProductV2(data: CreateV2ItemParams): void {
    this.$notification.setLoading(true);

    this.createItemV2(data).subscribe({
      next: (res) => {
        if (res?.data?.createV2Item) {
          this.$alert.setAlertInfo('SUCCESS', 'Item cadastrado com sucesso!');
          this.router.navigate([`internal/inventory/product`]);
        }

        this.$notification.setLoading(false);
      },
      error: (error) => {
        this.$alert.setAlertInfo('ERROR', 'Erro ao criar item.');

        this.$notification.setLoading(false);
        throw new Error(error);
      },
    });
  }
  public updateProductV2(id: string, data: CreateV2ItemParams): void {
    this.$notification.setLoading(true);

    this.updateV2Item(id, data).subscribe({
      next: (res) => {
        if (res?.data?.updateV2Item) {
          this.$alert.setAlertInfo('SUCCESS', 'Item atualizado com sucesso!');
          this.router.navigate([`internal/inventory/product`]);
        }

        this.$notification.setLoading(false);
      },
      error: (error) => {
        this.$alert.setAlertInfo('ERROR', 'Erro ao atualizar item.');
        this.$notification.setLoading(false);
        throw new Error(error);
      },
    });
  }

  public getTotalStockCount(item: ItemsV2Model): number {
    return item.itemUnits.reduce((acc, itemUnit) => {
      acc += itemUnit.stockCount;

      return acc;
    }, 0);
  }

  public async checkForInactives(
    sellerId: string,
    pointId: string,
    products: ItemsV2Model[],
    cartId: string
  ): Promise<boolean> {
    let foundInactive = false;

    const inactiveItens = products.filter((product) => !product.itemUnits[0].active);

    if (inactiveItens.length > 0) {
      return this.inactiveFound(sellerId, pointId);
    }

    products.map(async (element) => {
      const originalProduct = await this.getItemV2ById(element.originalId)?.pipe(take(1)).toPromise();

      if (originalProduct.active === false && originalProduct !== undefined) {
        element.itemUnits[0].active = false;
        foundInactive = true;
      }
    });

    if (foundInactive) {
      return this.inactiveFound(sellerId, pointId);
    }

    return foundInactive;
  }

  public inactiveFound(sellerId: string, pointId: string): boolean {
    this.$alert.setAlertInfo('ERROR', 'Não é possivel pagar um pedido com produtos inativos.');
    this.router.navigate(['/external/' + sellerId + '/' + pointId + '/basket']);
    return true;
  }

  public calcTotalPrice(products: Array<ItemsV2Model>): number {
    const totalPrice = products.reduce((acc, product) => (acc += product.itemUnits[0].price * 1), 0);
    return Number(totalPrice);
  }

  public hexFromColorName(colorName: string): string {
    const colors: { [key: string]: string } = {
      preto: '#000000',
      vermelho: '#C73B51',
      verde: '#FEB8C3',
      azul: '#0000FF',
      amarelo: '#48CEA6',
    };
    return colors[colorName.toLowerCase()] || '';
  }

  public uploadItemImage(file: any, storageUrl: string): Observable<any> {
    const headers = {
      'response-content-disposition': 'inline',
      'content-disposition': 'inline',
      'content-type': file.type,
    };

    return this.$http.put(storageUrl, file, { headers });
  }

  public uploadImage(url: string, obj: any): void {
    obj.url = url;
    obj.invalid = false;

    const blob = base64ToBlob(url.split(',')[1]);
    const extension = obj.file.type.split('/')[1];

    const imgLabel = obj.label + '.' + extension;

    obj.file = new File([blob], imgLabel, { type: obj.file.type });

    this.getItemImageUploadUrl(extension)
      .pipe(
        take(1),
        catchError((error) => this.handleImageError(obj, error)),
        mergeMap((res) => {
          if (res?.data?.getV2ItemImageUploadUrl) {
            const putUrl = JSON.parse(JSON.stringify(res.data.getV2ItemImageUploadUrl));
            obj.filename = putUrl.filename;

            return this.uploadItemImage(obj.file, putUrl.url).pipe(
              catchError((error) => this.handleImageError(obj, error))
            );
          }
          return EMPTY;
        })
      )
      .subscribe({
        next: (res) => {},
        error: (error) => {
          obj.loading = false;
          throw error;
        },
      });
  }

  public setItemImagesPayload(images: ItemImagesModel[]) {
    const imageUploads: UpsertV2ItemImageParams[] = [];

    if (images && images.length > 0) {
      images.forEach((image) => {
        let imageUpload: UpsertV2ItemImageParams = null;

        if (image.id) {
          imageUpload = {
            id: image.id,
          };
        }

        if (image.filename) {
          imageUpload = {
            orderIndex: image.orderIndex,
            filename: image.filename,
            url: image.url,
            description: image.label,
            id: image.id,
          };
        }

        if (imageUpload) {
          imageUploads.push(imageUpload);
        }
      });
    }

    return imageUploads;
  }

  private handleImageError(obj: any, error: any): Observable<never> {
    obj.loading = false;

    this.$error.errorHandling(error, 'Erro no upload de imagens');
    throw error;
  }

  public setItemVariant(item: ItemsV2Model): ItemsV2Model {
    const data: ItemsV2Model = JSON.parse(JSON.stringify(item));

    data.itemVariants.forEach((itemVariant) => {
      const idTracker = {};
      itemVariant.itemVariantOptions = itemVariant.itemVariantOptions.filter((itemVariantOption) => {
        if (!idTracker[itemVariantOption.id]) {
          idTracker[itemVariantOption.id] = true;
          return true;
        }
        return false;
      });
    });

    data.itemVariants.map((itemVariant: ItemVariantModel) => {
      itemVariant.itemVariantOptionsNames = itemVariant.itemVariantOptions
        .map((itemVariantOption) => itemVariantOption.name)
        .join(', ');

      itemVariant.itemVariantOptions.map((res) => {
        res.itemUnitVariantOption = res.itemUnitVariantOptions[0];
        res.images = res.itemUnitVariantOption.images;
      });
    });

    return data;
  }
}
