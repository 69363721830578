import { Injectable } from '@angular/core';

import { AlertService } from '../alert/alert.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable()
export class ErrorService {
  constructor(private $alert: AlertService, private readonly $message: NzMessageService) {}

  public errorHandling(error: any, defaultMessage: string, alertType: string = 'CUSTOM'): void {
    const stringifyError = JSON.stringify(error);
    const parseError = JSON.parse(stringifyError);
    const message =
      stringifyError.includes('Internal Server Error') || stringifyError.includes('Http failure response')
        ? defaultMessage
        : parseError.message;

    if (alertType === 'CUSTOM') {
      this.$alert.setAlertInfo('ERROR', message);
    } else {
      this.$message.error(message);
    }

    throw new Error(error);
  }
}
