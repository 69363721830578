import { gql } from 'apollo-angular';

const DEFAULT_FIELDS = `
  id
  code
  name
  expiration_date
  status
  value
  amount
  mall {
    id
    name
  }
  description
  start_date
  user_usage_limit
  typeDiscount
  viewCoupon
  app_view
  coupon_image
  buy_value_min
  buy_value_max
  isFavorite
  seller {
    business_name
  }
  categories {
    id
    name
    status
  }
  coupon_type {
    id
    name
    status
  }
  redeemed_coupons {
    id
  }
`;

const COUPONS_QUERY = gql`
  query Coupons(
    $status: CouponStatusEnum
    $sellerId: String
    $startDate: DateTime
    $endDate: DateTime
  ) {
    coupons(
      sellerId: $sellerId
      status: $status
      startDate: $startDate
      endDate: $endDate
    ) {
      ${DEFAULT_FIELDS}
    }
  }
`;

const COUPON_BY_ID_QUERY = gql`
  query Coupon($id: String!) {
    coupon(id: $id) {
      ${DEFAULT_FIELDS}
    }
  }
`;

const CUSTOMER_COUPONS_BY_SELLER_QUERY = gql`
  query CustomerCouponsBySeller($sellerId: String!, $startDate: DateTime, $endDate: DateTime, $couponId: String!) {
    customerCouponsBySeller(sellerId: $sellerId, startDate: $startDate, endDate: $endDate, couponId: $couponId) {
      redeemed_coupons {
        id
        redeemed_at
        date_redeemed_seller
        buy_value_seller
        couponId
        coupon {
          id
          code
        }
        customer {
          id
          name
        }
        lead {
          id
          name
        }
      }
      rescued_coupons {
        id
        dateRescuedAt
        couponId
        sellerId
        coupon {
          id
          code
        }
        lead {
          id
          name
        }
        customer {
          id
          name
        }
      }
    }
  }
`;

const ALL_CATEGORIES_COUPON_QUERY = gql`
  query AllCategoriesCoupon {
    allCategoriesCoupon {
      id
      name
    }
  }
`;

const CATEGORIES_COUPON_QUERY = gql`
  query Categories {
    categories {
      id
      name
    }
  }
`;

const COUPONS_TYPE_QUERY = gql`
  query CouponsType {
    couponsType {
      id
      name
    }
  }
`;

const MALLS_QUERY = gql`
  query Malls {
    malls {
      id
      name
    }
  }
`;

const COUPON_ANALYSIS_QUERY = gql`
  query CouponAnalysis($couponId: String!) {
    couponAnalysis(couponId: $couponId) {
      couponViews
      collectedCoupons
      roi
      couponLikes
      couponShare
      redeemedCoupons
    }
  }
`;

const SELLER_COUPON_ANALYSIS_QUERY = gql`
  query SellerCouponAnalysis($sellerId: String!) {
    sellerCouponAnalysis(sellerId: $sellerId) {
      totalCouponViews
      totalCouponLikes
      totalCollectedCoupons
      totalRedeemedCoupons
      totalRoi
      totalSales
      totalShare
    }
  }
`;

const CUSTOMER_COUPONS_QUERY = gql`
  query CustomerCoupons($customerId: String!) {
    customerCoupons(customerId: $customerId) {
      actives {
        ${DEFAULT_FIELDS}
      }
      inactives {
        ${DEFAULT_FIELDS}
      }
    }
  }
`;

const GET_COUPONS_BY_CATEGORY_ID_QUERY = gql`
  query CategoryToCoupons($id: String!) {
    categoryToCoupons(id: $id) {
      name
      coupons {
        ${DEFAULT_FIELDS}
      }
    }
  }
`;

export {
  MALLS_QUERY,
  COUPONS_QUERY,
  COUPON_BY_ID_QUERY,
  COUPONS_TYPE_QUERY,
  COUPON_ANALYSIS_QUERY,
  CUSTOMER_COUPONS_QUERY,
  CATEGORIES_COUPON_QUERY,
  ALL_CATEGORIES_COUPON_QUERY,
  SELLER_COUPON_ANALYSIS_QUERY,
  CUSTOMER_COUPONS_BY_SELLER_QUERY,
  GET_COUPONS_BY_CATEGORY_ID_QUERY,
};
