import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { EventModel } from '../../models/event/event.model';
import { WebhookModel } from '../../models/webhooks/webhooks.model';
import { ResponseModel } from '../../models/response/response.model';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { NotificationsModel } from '../../models/notifications/notifications.model';

@Injectable()
export class WebhooksService {
  constructor(private afFunc: AngularFireFunctions) {}

  public createWebhook(params = {}): Promise<ResponseModel<WebhookModel>> {
    return this.afFunc
      .httpsCallable<any, ResponseModel<WebhookModel>>('createWebhook')(params)
      .pipe(take(1))
      .toPromise();
  }

  public updateWebhook(params = {}): Promise<ResponseModel<WebhookModel>> {
    return this.afFunc
      .httpsCallable<any, ResponseModel<WebhookModel>>('updateWebhook')(params)
      .pipe(take(1))
      .toPromise();
  }

  public deleteWebhook(params = {}): Promise<ResponseModel<WebhookModel>> {
    return this.afFunc
      .httpsCallable<any, ResponseModel<WebhookModel>>('deleteWebhook')(params)
      .pipe(take(1))
      .toPromise();
  }

  public listWebhooks(params = {}): Promise<ResponseModel<WebhookModel[]>> {
    return this.afFunc
      .httpsCallable<any, ResponseModel<WebhookModel[]>>('listWebhooks')(params)
      .pipe(take(1))
      .toPromise();
  }

  public getEvent(eventId: string): Promise<ResponseModel<EventModel>> {
    return this.afFunc.httpsCallable<string, ResponseModel<EventModel>>('getEvent')(eventId).pipe(take(1)).toPromise();
  }

  public listEvents(params = {}): Promise<ResponseModel<EventModel[]>> {
    return this.afFunc.httpsCallable<any, ResponseModel<EventModel[]>>('listEvents')(params).pipe(take(1)).toPromise();
  }

  public getNotification(notificationId: string): Promise<ResponseModel<NotificationsModel>> {
    return this.afFunc
      .httpsCallable<string, ResponseModel<NotificationsModel>>('getNotification')(notificationId)
      .pipe(take(1))
      .toPromise();
  }

  public listNotifications(params = {}): Promise<ResponseModel<NotificationsModel[]>> {
    return this.afFunc
      .httpsCallable<any, ResponseModel<NotificationsModel[]>>('listNotifications')(params)
      .pipe(take(1))
      .toPromise();
  }

  public retryNotification(notificationId: any): Promise<ResponseModel<NotificationsModel>> {
    return this.afFunc
      .httpsCallable<any, ResponseModel<NotificationsModel>>('retryNotification')(notificationId)
      .pipe(take(1))
      .toPromise();
  }
}
