import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-withdrawal-request',
  templateUrl: './modal-withdrawal-request.component.html',
  styleUrls: ['./modal-withdrawal-request.component.scss'],
})
export class ModalWithdrawalRequestComponent implements OnInit {
  @Input() public data: any;

  public title: string = 'Solicitar saque';
  public formDescription!: FormGroup;
  public loading: boolean = false;
  public waitingValue: number = 0;

  constructor(private readonly fb: FormBuilder, private readonly modalRef: NzModalRef) {}

  public ngOnInit(): void {
    this.createForm();

    this.waitingValue = this.data?.withdraws?.reduce((acc: number, item: any) => (acc += item.amountCents), 0);
  }

  public createForm(): void {
    this.formDescription = this.fb.group({
      withdrawValue: new FormControl('', [Validators.required, this.minValueValidator(10), this.maxValueValidator()]),
    });
  }

  public setHandleSubmit(withData: boolean): void {
    if (withData) {
      this.modalRef.close(this.formDescription.value);
    } else {
      this.modalRef.close();
    }
  }

  public minValueValidator(minAmount: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valueInCents = control?.value;
      const forbidden = valueInCents < minAmount;
      return forbidden ? { min: { value: control?.value } } : null;
    };
  }

  public maxValueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valueInCents = control?.value * 100;
      const forbidden = valueInCents > this.data.currentBalance - this.waitingValue;
      return forbidden ? { max: { value: control?.value } } : null;
    };
  }
}
