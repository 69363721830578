import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { Injectable, Query } from '@angular/core';

import { FilterFeesParams } from '../../models/fees/filter-fees-params.model';
import { GET_FEE_QUERY, LIST_FEES_QUERY } from '../../graphql/queries/fees.queries';

@Injectable()
export class FeesService {
  constructor(private readonly apollo: Apollo) {}

  public getFeesList(filter: FilterFeesParams): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_FEES_QUERY,
      variables: { filter },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getFeeById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_FEE_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }
}
