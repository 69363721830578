import { gql } from 'apollo-angular';

const TOKENIZE_CARD_NUMBER = gql`
  mutation TokenizeCardNumber($cardNumber: String!) {
    tokenizeCardNumber(cardNumber: $cardNumber)
  }
`;

const CREATE_CREDIT_CARD = gql`
  mutation CreateCreditCard(
    $customerId: UUID4!
    $default: Boolean
    $expirationMonth: String!
    $expirationYear: String!
    $holderName: String
    $securityCode: String
    $token: String!
  ) {
    createCreditCard(
      params: {
        customerId: $customerId
        default: $default
        expirationMonth: $expirationMonth
        expirationYear: $expirationYear
        holderName: $holderName
        securityCode: $securityCode
        token: $token
      }
    ) {
      last4
      id
      brand
      billingAddress {
        city
        line1
        line2
        line3
        countryCode
        neighborhood
        state
        id
        postalCode
      }
      blocked
      expirationYear
      expirationMonth
      token
      default
      verified
    }
  }
`;

export { TOKENIZE_CARD_NUMBER, CREATE_CREDIT_CARD };
