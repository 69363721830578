import { gql } from 'apollo-angular';

const UPLOAD_SELLER_DOCUMENT_MUTATION = gql`
  mutation UploadSellerDocument($params: UploadSellerDocumentParams!) {
    uploadSellerDocument(params: $params) {
      id
    }
  }
`;

const BAN_SELLER_MUTATION = gql`
  mutation BanSeller($id: UUID4!) {
    banSeller(id: $id) {
      id
    }
  }
`;

const BLOCK_SELLER_MUTATION = gql`
  mutation BlockSeller($id: UUID4!) {
    blockSeller(id: $id) {
      id
    }
  }
`;

const UNBLOCK_SELLER_MUTATION = gql`
  mutation UnblockSeller($id: UUID4!) {
    unblockSeller(id: $id) {
      id
    }
  }
`;

const REPROCESS_SELLER_MUTATION = gql`
  mutation ReprocessSeller($id: UUID4!) {
    reprocessSeller(id: $id) {
      id
    }
  }
`;

const APPROVE_SELLER_MUTATION = gql`
  mutation approveSeller($id: UUID4!) {
    approveSeller(id: $id) {
      id
    }
  }
`;

const DECLINE_SELLER_MUTATION = gql`
  mutation DeclineSeller($id: UUID4!) {
    declineSeller(id: $id) {
      id
    }
  }
`;

const EDIT_SELLER_MUTATION = gql`
  mutation UpdateSeller($id: UUID4!, $params: UpdateSellerParams!) {
    updateSeller(id: $id, params: $params) {
      id
    }
  }
`;

const UPDATE_STORE_PREFERENCES_MUTATION = gql`
  mutation upsertStorePreferences($params: StorePreferencesParams!, $sellerId: UUID4!) {
    upsertStorePreferences(params: $params, sellerId: $sellerId) {
      allowInstallment
      allowMethods
      defaultMaxInstallments
      defaultPaymentMethods
      eatIn
      serviceCharge
      takeOut
      usingDelivery
    }
  }
`;

const ACCEPT_SAAS_PLATFORM_TERM_MUTATION = gql`
  mutation AcceptSaasPlatformTerm($sellerId: UUID4!, $platformTermId: UUID4!) {
    acceptSaasPlatformTerm(sellerId: $sellerId, platformTermId: $platformTermId) {
      id
    }
  }
`;

const CREATE_PLATFORM_TERM_MUTATION = gql`
  mutation CreatePlatformTerm($params: CreatePlatformTermParams!) {
    createPlatformTerm(params: $params) {
      id
    }
  }
`;

export {
  BAN_SELLER_MUTATION,
  EDIT_SELLER_MUTATION,
  BLOCK_SELLER_MUTATION,
  APPROVE_SELLER_MUTATION,
  DECLINE_SELLER_MUTATION,
  UNBLOCK_SELLER_MUTATION,
  REPROCESS_SELLER_MUTATION,
  CREATE_PLATFORM_TERM_MUTATION,
  UPLOAD_SELLER_DOCUMENT_MUTATION,
  UPDATE_STORE_PREFERENCES_MUTATION,
  ACCEPT_SAAS_PLATFORM_TERM_MUTATION,
};
