import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Mutation } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';

import {
  MALLS_QUERY,
  COUPONS_QUERY,
  COUPONS_TYPE_QUERY,
  COUPON_BY_ID_QUERY,
  COUPON_ANALYSIS_QUERY,
  CUSTOMER_COUPONS_QUERY,
  ALL_CATEGORIES_COUPON_QUERY,
  SELLER_COUPON_ANALYSIS_QUERY,
  CUSTOMER_COUPONS_BY_SELLER_QUERY,
  GET_COUPONS_BY_CATEGORY_ID_QUERY,
  CATEGORIES_COUPON_QUERY,
} from '../../graphql/queries/fidelity.queries';
import {
  LIKE_COUPON_MUTATION,
  UNLIKE_COUPON_MUTATION,
  CREATE_TYPE_COUPON_MUTATION,
  DELETE_TYPE_COUPON_MUTATION,
  UPDATE_TYPE_COUPON_MUTATION,
  ASSOC_DATA_TO_COUPON_MUTATION,
  CREATE_CATEGORY_COUPON_MUTATION,
  CREATE_COUPON_FIDELITY_MUTATION,
  DELETE_COUPON_FIDELITY_MUTATION,
  UPDATE_COUPON_FIDELITY_MUTATION,
  DELETE_CATEGORY_COUPON_MUTATION,
  UPDATE_CATEGORY_COUPON_MUTATION,
  REDEEM_COUPON_BY_SELLER_MUTATION,
} from '../../graphql/mutations/fidelity.mutations';
import { CustomerModel } from '../../models/customer/customer.model';
import { CouponFidelityModel } from '../../models/fidelity/coupon-fidelity.model';
import { CouponTypeFidelityModel } from '../../models/fidelity/coupon-type-fidelity.model';
import { CouponCategoryFidelityModel } from '../../models/fidelity/coupon-category-fidelity.model';
import { CouponCustomerFidelityModel } from '../../models/fidelity/coupon-customer-fidelity.model';

@Injectable()
export class FidelityService {
  constructor(private readonly apollo: Apollo) {}

  public getCouponsList(variables?: any): Observable<ApolloQueryResult<CouponFidelityModel>> {
    return this.apollo.watchQuery<CouponFidelityModel>({
      query: COUPONS_QUERY,
      variables,
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getCouponById(id: string): Observable<ApolloQueryResult<CouponFidelityModel>> {
    return this.apollo.watchQuery<CouponFidelityModel>({
      query: COUPON_BY_ID_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getCouponCategoriesList(): Observable<ApolloQueryResult<CouponCategoryFidelityModel>> {
    return this.apollo.watchQuery<CouponCategoryFidelityModel>({
      query: ALL_CATEGORIES_COUPON_QUERY,
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getCategoriesList(): Observable<ApolloQueryResult<CouponCategoryFidelityModel>> {
    return this.apollo.watchQuery<CouponCategoryFidelityModel>({
      query: CATEGORIES_COUPON_QUERY,
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getCustomersBySeller(variables: any): Observable<ApolloQueryResult<CouponCustomerFidelityModel>> {
    return this.apollo.watchQuery<CouponCustomerFidelityModel>({
      query: CUSTOMER_COUPONS_BY_SELLER_QUERY,
      variables,
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getCouponTypesList(): Observable<ApolloQueryResult<CouponTypeFidelityModel>> {
    return this.apollo.watchQuery<CouponTypeFidelityModel>({
      query: COUPONS_TYPE_QUERY,
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getMallList(): Observable<ApolloQueryResult<any>> {
    return this.apollo.watchQuery<any>({
      query: MALLS_QUERY,
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getCouponAnalisys(couponId: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: COUPON_ANALYSIS_QUERY,
      variables: { couponId },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getSellerCouponAnalysis(sellerId: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: SELLER_COUPON_ANALYSIS_QUERY,
      variables: { sellerId },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getCouponsToCustomer(customerId: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: CUSTOMER_COUPONS_QUERY,
      variables: { customerId },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getCouponsByCategoryId(id: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_COUPONS_BY_CATEGORY_ID_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public createCoupon(data: Partial<CouponFidelityModel>): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_COUPON_FIDELITY_MUTATION,
      variables: data,
    });
  }

  public updateCoupon(data: Partial<CouponFidelityModel>): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_COUPON_FIDELITY_MUTATION,
      variables: data,
    });
  }

  public deleteCoupon(couponId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: DELETE_COUPON_FIDELITY_MUTATION,
      variables: { couponId },
    });
  }

  public rescueCoupon(data: any): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: REDEEM_COUPON_BY_SELLER_MUTATION,
      variables: data,
    });
  }

  public assocCategoryOrTypeToCoupon(data: Partial<CouponFidelityModel>): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: ASSOC_DATA_TO_COUPON_MUTATION,
      variables: data,
    });
  }

  public createCategoryCoupon(data: Partial<CouponCategoryFidelityModel>): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_CATEGORY_COUPON_MUTATION,
      variables: data,
    });
  }

  public updateCategoryCoupon(data: Partial<CouponCategoryFidelityModel>): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_CATEGORY_COUPON_MUTATION,
      variables: { category: data },
    });
  }

  public deleteCategoryCoupon(categoryId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: DELETE_CATEGORY_COUPON_MUTATION,
      variables: { categoryId },
    });
  }

  public createCouponType(data: Partial<CouponTypeFidelityModel>): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_TYPE_COUPON_MUTATION,
      variables: data,
    });
  }

  public updateCouponType(data: Partial<CouponTypeFidelityModel>): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_TYPE_COUPON_MUTATION,
      variables: { couponType: data },
    });
  }

  public deleteCouponType(couponTypeId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: DELETE_TYPE_COUPON_MUTATION,
      variables: { couponTypeId },
    });
  }

  public likeCoupon(couponId: string, customer: Partial<CustomerModel>): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: LIKE_COUPON_MUTATION,
      variables: { couponId, customer },
    });
  }

  public unlikeCoupon(couponId: string, customerId: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UNLIKE_COUPON_MUTATION,
      variables: { couponId, customerId },
    });
  }
}
