import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { CpfValidator } from '../../validators/cpfValidator';
import { AddressModel } from '../../models/address/address.model';
import { CustomerModel } from '../../models/customer/customer.model';
import { PointModel } from '../../models/points/point-of-sales.model';
import { FullNameValidator } from '../../validators/fullNameValidator';
import { AddressService } from '../../services/address/address.service';
import { CreditCardModel } from '../../models/credit-card/credit-card.model';
import { expirationDateValidate } from '../../validators/expirationDateValidator';
import { CreditCardService } from '../../services/credit-card/credit-card.service';
import { StateManagementService } from '../../state-management/state-management.service';
import { PointOfSalesService } from '../../services/point-of-sales/point-of-sales.service';

@Component({
  selector: 'app-checkout-select-payment',
  templateUrl: './checkout-select-payment.component.html',
  styleUrls: ['./checkout-select-payment.component.scss'],
})
export class CheckoutSelectPaymentComponent implements OnInit {
  public point: PointModel = new PointModel();
  public openAddCardForm: boolean = false;
  public showCardForm: boolean = true;
  public showAddressForm: boolean = false;
  public showValidationForm: boolean = false;
  public addCardWeb: boolean = false;
  public disableContinueWeb: boolean = true;
  public showError: boolean = false;
  public loading: boolean = false;
  public loadingBtn: boolean = false;
  public loadingCep: boolean = false;
  public creditCard: Partial<CreditCardModel> = new CreditCardModel();
  public creditCardForm!: FormGroup;
  public addressForm!: FormGroup;
  public validationForm!: FormGroup;
  public customer: CustomerModel = new CustomerModel();
  public errorMsg: string = '';
  public creditCardId: string = '';
  public saveCard: boolean = false;
  public proceedToInstallments: boolean = false;
  public cepErrorMessage: string = "Campo obrigatório"
  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private $address: AddressService,
    private $points: PointOfSalesService,
    private $creditCard: CreditCardService,
    private readonly route: ActivatedRoute,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService,
    private readonly $recaptchaV3Service: ReCaptchaV3Service
  ) { }

  public ngOnInit(): void {
    this.$notification.setTitleCheckout('Pagamento');
    this.createForm();
    this.getValuesChange();
    this.getNotifications();
    this.getInstallmentsAllowed();
  }

  public addCard(): void {
    this.openAddCardForm = true;
  }

  public createForm(): void {
    this.creditCardForm = this.fb.group({
      cardNumber: new FormControl<string>('', [Validators.required]),
      expirationDate: new FormControl<string>('', [Validators.required, expirationDateValidate()]),
      securityCode: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(4),
      ]),
      holderName: new FormControl<string>('', [Validators.required, FullNameValidator.isValid()]),
      cpf: new FormControl<string>('', [Validators.required, CpfValidator.isValid()]),
    });

    this.addressForm = this.fb.group({
      cep: new FormControl<string>('', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]),
      street: new FormControl<string>('', [Validators.required]),
      number: new FormControl<string>('', [Validators.required]),
      complement: new FormControl<string>(''),
      neighborhood: new FormControl<string>('', [Validators.required]),
      city: new FormControl<string>('', [Validators.required]),
      state: new FormControl<string>('', [Validators.required]),
    });

    this.validationForm = this.fb.group({
      amountCents: new FormControl(null, [Validators.required]),
    });
  }

  public getNotifications(): void {
    this.$notification.customers.subscribe((customer) => {
      if (customer && Object.keys(customer).length > 0) {
        this.customer = customer;

        this.creditCardForm.patchValue({
          holderName: customer.name,
          cpf: customer.cpf || customer.cnpj,
        });

        this.addressForm.patchValue({
          city: customer.address?.city,
          state: customer.address?.state,
        });

        this.addressForm.controls['city'].disable();
        this.addressForm.controls['state'].disable();
      }
    });

    this.$notification.points.subscribe((res) => {
      if (res) {
        this.point = res;
      }
    });
  }

  public addPaymentMethodWeb(method: string): void {
    this.point.selectPaymentMethod = method === 'CREDIT_CARD' ? this.point.selectPaymentMethod : { method, data: null };
    this.$points.updatePoint(this.point.id, { selectPaymentMethod: this.point.selectPaymentMethod });
    if (this.addCardWeb) {
      this.addCardWeb = false;
    }
    if (this.disableContinueWeb) {
      this.disableContinueWeb = false;
    }
  }

  public addPaymentMethod(method: string): void {
    this.point.selectPaymentMethod = method === 'CREDIT_CARD' ? this.point.selectPaymentMethod : { method, data: null };
    this.$points.updatePoint(this.point.id, { selectPaymentMethod: this.point.selectPaymentMethod });

    this.router.navigate(['../credit-card-installment'], { relativeTo: this.route });
  }

  public getValuesChange(): void {
    this.addressForm.get('cep').valueChanges.subscribe((cep) => {
      if (cep.length === 8) {
        this.searchPostalCode(cep);
      }
    });
  }

  public searchPostalCode(cep: string): void {
    this.loadingCep = true;

    this.$address.getCep(cep).subscribe({
      next: (address) => {
        if (address.cep) {
          this.addressForm.patchValue({
            city: address.localidade,
            street: address.logradouro,
            state: address.uf,
            neighborhood: address.bairro,
          });
        } else {
          this.cepErrorMessage = "CEP inválido"
          this.addressForm.get('cep').markAsDirty();
          this.addressForm.get('cep').setErrors({ invalid: true });
          this.addressForm.get('cep').markAsTouched();
        }

        this.loadingCep = false;
      },
      error: (error) => {
        this.loadingCep = false;
        this.addressForm.get('cep').markAsDirty();
        this.cepErrorMessage = "CEP inválido"
        this.addressForm.get('cep').setErrors({ invalid: true });
        this.addressForm.get('cep').markAsTouched();
        throw new Error(error);
      },
    });
  }

  public tokenizeCreditCard(): void {
    const cardNumber = this.creditCardForm.get('cardNumber').value;

    this.$creditCard.tokenizeCreditCard(cardNumber).subscribe((token) => {
      if (token.data.tokenizeCardNumber) {
        const card = this.getCardObj(token.data.tokenizeCardNumber);

        if (this.saveCard) {
          this.createCreditCard(card);
        } else {
          this.addCreditCardNoSave(card);
        }
      } else {
        this.showError = true;
        this.errorMsg = 'Cartão inválido. Revise os dados e tente novamente.';
        this.showAddressForm = false;
        this.loadingBtn = false;
        this.showCardForm = true;
        setTimeout(() => {
          this.showError = false;
        }, 3000);
      }
    });
  }

  public executeRecaptcha(): void {
    this.loadingBtn = true;

    this.$recaptchaV3Service.execute('createCreditCard').subscribe({
      next: (recaptcha) => {
        if (recaptcha) {
          this.tokenizeCreditCard();
        }
      },
      error: (error) => {
        throw new Error(error);
      },
    });
  }

  public createCreditCard(card: Partial<CreditCardModel>): void {
    this.$creditCard.createNewCreditCard(card).subscribe({
      next: (res) => {
        if (res.data.createCreditCard === null || res.errors) {
          this.showError = true;
          this.errorMsg = 'Cartão inválido. Revise os dados e tente novamente.';
          this.$message.error(this.errorMsg);
          this.showAddressForm = false;
          this.showCardForm = true;
          this.loadingBtn = false;

          setTimeout(() => {
            this.showError = false;
          }, 3000);
        } else {
          if (res.data.createCreditCard.brand === 'visa' || res.data.createCreditCard.brand === 'mastercard') {
            this.$message.success('Cartão adicionado com sucesso!');
            this.openAddCardForm = false;
            this.showAddressForm = false;
            this.point.selectPaymentMethod = { method: 'CREDIT_CARD', data: res.data.createCreditCard };
            this.$notification.setPoint(this.point);
            this.$points.updatePoint(this.point.id, { selectPaymentMethod: this.point.selectPaymentMethod });
            this.proceedNavigation();
          }
        }

        this.loadingBtn = false;
      },
      error: (error) => {
        this.showError = true;
        this.errorMsg = error;
        this.$message.error(error);
        this.showAddressForm = false;
        this.showCardForm = true;
        this.loadingBtn = false;
        throw new Error(error);
      },
    });
  }

  public addCardWebFlow(): void {
    this.addCardWeb = true;
    this.disableContinueWeb = true;
  }

  public saveAndContinue(): void {
    if (this.addCardWeb) {
      this.executeRecaptcha();
    } else if (this.point.selectPaymentMethod.method == 'CREDIT_CARD') {
      this.proceedNavigation();
    } else {
      this.router.navigate(['..'], { relativeTo: this.route });
    }
  }

  public getCardObj(token: string): Partial<CreditCardModel> {
    const cardObj = this.$creditCard.getCreditCardObj(this.creditCardForm.get('cardNumber').value);
    const address: AddressModel = {
      line1: this.addressForm.get('street').value,
      line2: this.addressForm.get('number').value || '0',
      line3: this.addressForm.get('complement').value || '',
      postalCode: this.addressForm.get('cep').value.replace('-', ''),
      neighborhood: this.addressForm.get('neighborhood').value,
      city: this.addressForm.get('city').value,
      state: this.addressForm.get('state').value,
      countryCode: 'BR',
    };
    const obj = {
      customerId: this.customer?.id || this.point.customerId || 'Anônimo',
      default: false,
      expirationMonth: this.creditCardForm.get('expirationDate').value.slice(0, 2),
      expirationYear: this.creditCardForm.get('expirationDate').value.slice(2, 4),
      holderName: this.customer?.name || this.creditCardForm.get('holderName').value,
      securityCode: this.creditCardForm.get('securityCode').value,
      token: token,
      billingAddress: address,
      brand: cardObj.brand,
      last4: cardObj.last4,
    };
    return obj;
  }

  public addCreditCardNoSave(card: Partial<CreditCardModel>): void {
    this.point.selectPaymentMethod = {
      method: 'CREDIT_CARD',
      data: card,
    };
    this.$notification.setPoint(this.point);
    this.$points.updatePoint(this.point.id, { selectPaymentMethod: this.point.selectPaymentMethod });

    this.addCardWeb = false;
    this.showCardForm = false;
    this.showAddressForm = false;

  }

  public getInstallmentsAllowed(): void {
    this.point.cart.baskets.map((basket) => {
      if (basket.store.storePreferences.allowInstallment && basket.store.storePreferences.defaultMaxInstallments > 1) {
        this.proceedToInstallments = true;
      } else {
        basket.installments = 1;
      }
    });
  }

  public proceedNavigation(): void {
    if (this.proceedToInstallments) {
      this.router.navigate(['../credit-card-installment'], { relativeTo: this.route });
    } else {
      this.router.navigate(['..'], { relativeTo: this.route });
    }
  }

  public addCardMobile(): void {
    this.executeRecaptcha();
  }
}
