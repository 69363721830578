import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';

import { UserSellersModel } from '../../../models/users/user-sellers.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-choose-seller',
  templateUrl: './modal-choose-seller.component.html',
  styleUrls: ['./modal-choose-seller.component.scss'],
})
export class ModalChooseSellerComponent implements OnInit {
  @Input() public sellers: Array<UserSellersModel>;
  public formGroup!: FormGroup;

  constructor(private $modalRef: NzModalRef, private readonly fb: FormBuilder) {}

  public ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      choice: new FormControl(null, [Validators.required]),
    });
  }

  public close(): void {
    this.$modalRef.close(this.formGroup.get('choice').value);
  }
}
