import moment from 'moment-timezone';
import { differenceInCalendarDays } from 'date-fns';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { ItemModel } from 'libs/shared/src/lib/models/items/item.model';
import { SellerModel } from 'libs/shared/src/lib/models/sellers/sellers.model';
import { ItemsService } from 'libs/shared/src/lib/services/items/items.service';
import { InternalService } from 'libs/shared/src/lib/services/internal/internal.service';
import { ContactsService } from 'libs/shared/src/lib/services/contacts/contacts.service';
import { SharedMethodsService } from 'libs/shared/src/lib/services/internal/shared-methods.service';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from 'libs/shared/src/lib/services/orders/orders.service';
import { SubscriptionsService } from 'libs/shared/src/lib/services/subscriptions/subscriptions.service';
import { AlertDuration, AlertType, StatusAlertModel } from 'libs/shared/src/lib/models/status/status-alert.model';
import { ItemsV2Model } from 'libs/shared/src/lib/models/items/V2/ItemV2.model';

@Component({
  selector: 'app-drawer-create-simple',
  templateUrl: './drawer-create-simple.component.html',
  styleUrls: ['./drawer-create-simple.component.scss'],
})
export class DrawerCreateSimpleComponent implements OnInit {
  @Input() data: any;

  public orderForm!: FormGroup;
  public listOfOption: Array<{ value: ItemsV2Model; label: string }> = [];
  public itemsInput: { items: Array<ItemModel>; newItem: boolean };
  public listOfInstallments: Array<{ value: any; label: string }> = [];
  public listOfContacts: any;
  public methodsList: Array<{ value: string; label: string; checked: boolean }> = [];
  public scheduleList: Array<{ value: string; label: string }> = [];
  public showInstallments: boolean = false;
  public addItemEnable: boolean = false;
  public newItem: boolean = false;
  public sellerId: string = '';
  public isOpenSelect: boolean = false;
  public title: string = 'Criar fatura';
  public loading: boolean = true;
  public loadingBtn: boolean = false;
  public loadingCreateItem: boolean = false;
  public disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) < 0;
  public showError: boolean = false;
  public type: 'order' | 'subscription';

  public alertInfo: StatusAlertModel = new StatusAlertModel();
  public alertType: AlertType = new AlertType();
  public alertDuration: AlertDuration = new AlertDuration();

  public checkAtLeastOneChecked: ValidatorFn = (control: AbstractControl) => {
    const methods = control.value;
    const checked = methods.some((method: any) => method.checked);
    return checked ? null : { noCheckedMethod: true };
  };

  constructor(
    private $items: ItemsService,
    private readonly fb: FormBuilder,
    private $methods: InternalService,
    private $contacts: ContactsService,
    private $order: OrdersService,
    private $subscription: SubscriptionsService,
    private $shared: SharedMethodsService,
    private router: ActivatedRoute,
    private route: Router,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.getUser();
    this.updateAlertStatus();
  }

  public updateAlertStatus(): void {
    this.$notification.sucessAlerts.subscribe((sucessAlerts) => {
      if (sucessAlerts) {
        this.alertInfo = sucessAlerts;
      }
    });

    this.$notification.errorAlerts.subscribe((errorAlerts) => {
      this.alertInfo = errorAlerts;
    });
  }

  public getInvoice(seller: SellerModel): void {
    this.loading = true;

    this.router.params.subscribe((params) => {
      const id = params['id'];
      this.type = params['type'];

      if (id !== '0') {
        if (this.type === 'order') {
          this.$order.getOrderById(id).subscribe({
            next: (res) => {
              if (res?.data?.getOrder) {
                this.data = JSON.parse(JSON.stringify(res.data.getOrder));
                setTimeout(() => {
                  this.loading = false;
                  this.createForm(seller);
                }, 0);
              }
            },
            error: (error) => {
              this.loading = false;
              throw new Error(error);
            },
          });
        } else {
          this.$subscription.getDetailSubscription(id).subscribe({
            next: (res) => {
              if (res?.data?.getSubscription) {
                this.data = JSON.parse(JSON.stringify(res.data.getSubscription));
                setTimeout(() => {
                  this.loading = false;
                  this.createForm(seller);
                }, 0);
              }
            },
            error: (error) => {
              this.loading = false;
              throw new Error(error);
            },
          });
        }
      } else {
        this.createForm(seller);
        this.loading = false;
      }
    });
  }

  private getUser(): void {
    this.$notification.users.subscribe((res) => {
      if (res?.marketplaceId && res?.sellerId) {
        this.sellerId = res.sellerId;
        this.getItems(res.sellerId);
        this.getContacts(res.sellerId);
      }
    });

    this.$notification.sellers.subscribe((seller) => {
      if (seller) {
        this.getInvoice(seller);
      }
    });
  }

  private setValueChanges(): void {
    this.orderForm.get('items').valueChanges.subscribe((res) => {
      if (res) {
        this.isOpenSelect = false;
        this.itemsInput = { items: res, newItem: this.newItem };
        this.setInstallments();
      }
    });

    this.orderForm.get('endSubscription').valueChanges.subscribe((res) => {
      if (res) {
        this.orderForm.get('endDate').setValidators(Validators.required);
      } else {
        this.orderForm.get('endDate').clearValidators();
      }

      this.orderForm.get('endDate').updateValueAndValidity();
    });

    this.orderForm.get('frequency').valueChanges.subscribe((res) => {
      if (res) {
        if (res === 'subscription') {
          this.orderForm.get('schedule').setValidators(Validators.required);
          this.orderForm.get('startDate').setValidators(Validators.required);

          this.orderForm.get('methods').patchValue([{ label: 'Cartão', value: 'CREDIT_CARD', checked: true }]);
        } else {
          this.orderForm.get('startDate').clearValidators();
          this.orderForm.get('schedule').clearValidators();
          this.orderForm.get('endDate').clearValidators();

          this.methodsList = this.$methods.methodsList;
          this.orderForm.get('methods').patchValue(this.methodsList);
        }

        this.orderForm.get('startDate').updateValueAndValidity();
        this.orderForm.get('schedule').updateValueAndValidity();
        this.orderForm.get('endDate').updateValueAndValidity();
      }
    });

    this.orderForm.get('methods').valueChanges.subscribe((res) => {
      if (res) {
        if (res.filter((methods) => methods.checked).length > 1) {
          this.orderForm.get('installments').setValue('Pagamento à vista');
        }

        // this.showInstallments = !!res.some(
        //   (method: { label: string; value: string; checked: boolean }) =>
        //     method.checked === true && method.value === 'CREDIT_CARD'
        // );

        this.setInstallments();
      }
    });
  }

  public createForm(seller: SellerModel): void {
    this.methodsList = this.$methods.getMethodsBySeller(seller);
    this.scheduleList = this.$methods.scheduleList;

    this.setInstallments();

    if (this.type === 'subscription') {
      this.methodsList = [{ label: 'Cartão', value: 'CREDIT_CARD', checked: true }];
    }

    if (this.data?.id) {
      this.title = 'Editar fatura';

      this.methodsList.map((method) => {
        if (this.data.methods.includes(method.value)) {
          method.checked = true;
        }
      });
    }

    if (this.data?.customer) {
      this.data.customer.customerId = this.data.customer.id;
    }

    this.orderForm = this.fb.group({
      expirationDate: new FormControl(this.data?.expirationDate || ''),
      active: new FormControl(false),
      methods: new FormControl(this.methodsList || [], [Validators.required, this.checkAtLeastOneChecked]),
      frequency: new FormControl(this.type || 'order', [Validators.required]),
      items: new FormControl(this.data?.items || [], [Validators.required]),
      startDate: new FormControl(this.data?.startDate || ''),
      endSubscription: new FormControl(this.data?.endDate ? true : false),
      endDate: new FormControl(this.data?.endDate || ''),
      schedule: new FormControl(this.data?.schedulePlans),
      contact: new FormControl(this.data?.customer || null, [Validators.required]),
      installments: new FormControl(
        this.data?.maxInstallments === 1 ? 'Pagamento à vista' : this.data?.maxInstallments || 'Pagamento à vista'
      ),
    });

    if (this.data?.schedulePlans) {
      this.orderForm.get('frequency').setValue('subscription');
    }

    if (this.data?.endDate) {
      this.orderForm.get('endDate').setValidators(Validators.required);
      this.orderForm.get('endDate').updateValueAndValidity();
    }

    if (this.type === 'subscription') {
      this.orderForm.get('schedule').setValidators(Validators.required);
      this.orderForm.get('startDate').setValidators(Validators.required);
      this.orderForm.get('schedule').updateValueAndValidity();
      this.orderForm.get('startDate').updateValueAndValidity();
    }

    if (this.data?.id) {
      this.orderForm.get('frequency').disable();
      this.showInstallments = !!this.data?.methods.some((payment: string) => payment === 'CREDIT_CARD');
    }

    this.setValueChanges();
    this.loading = false;
  }

  public setInstallments(): void {
    const maxInstallments: Array<any> = ['Pagamento à vista', 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    this.listOfInstallments = maxInstallments.map((installment, index: number) => ({
      value: installment,
      label: index === 0 ? installment : 'Até ' + (index + 1) + 'x',
      disabled: index === 0 ? false : this.getMinInstallment(index + 1),
    }));
  }

  public getMinInstallment(index: number): boolean {
    let disabled = false;
    const totalCents = this.orderForm
      ?.get('items')
      ?.value?.reduce(
        (acc: number, item: any) => (acc += item.unitPriceCents || item.amountCents * item.quantity || 1),
        0
      );

    if (totalCents / index < 1000) {
      disabled = true;
    }

    if (
      this.orderForm
        ?.get('methods')
        ?.value?.filter(
          (payment: { label: string; value: string; checked: boolean }) =>
            payment.checked && payment.value !== 'CREDIT_CARD'
        ).length > 0
    ) {
      disabled = true;
    }

    return disabled;
  }

  public getItems(sellerId: string): void {
    this.$items.getV2ItemList({ sellerId: sellerId })?.subscribe({
      next: (res) => {
        if (res?.data?.listV2Items) {
          const data = [...res.data.listV2Items];
          const items = this.$shared.sortDataByName(
            data
              .map((item) => ({ ...item, quantity: 1 }))
          );

          this.listOfOption = items.map((item) => ({ value: item, label: item.name }));

          if (this.data?.items) {
            this.itemsInput = { items: this.orderForm.get('items').value, newItem: false };
          }
        }
      },
      error: (error) => {
        throw new Error(error);
      },
    });
  }

  public getContacts(sellerId: string): void {
    const filter = {
      sellerId: sellerId,
    };

    this.$contacts.getContactList(filter).subscribe((res) => {
      if (res) {
        this.listOfContacts = res.data.contacts;
      }
    });
  }

  public addItem(): void {
    this.isOpenSelect = false;
    this.addItemEnable = true;
    this.newItem = true;
    this.itemsInput = { items: this.orderForm.get('items').value, newItem: true };
  }

  public listItems(data: Array<ItemModel>): void {
    this.orderForm.get('items').setValue(data);
  }

  public cancel(): void {
    this.addItemEnable = false;
    this.newItem = false;
    this.itemsInput = { items: this.orderForm.get('items').value, newItem: false };
  }

  public AddNewItem(data: ItemsV2Model): void {
    this.loadingCreateItem = true;
    this.newItem = false;
    this.addItemEnable = false;

    this.listOfOption.push({ label: data.name, value: data });
    this.orderForm.get('items').value.push(data);

    setTimeout(() => {
      this.loadingCreateItem = false;
    }, 1000);
  }

  public setHandleSubmit(): void {
    if (this.orderForm.valid) {
      this.loadingBtn = true;

      const items = this.orderForm.get('items').value.map((i: any) => {
        const item = new ItemModel();
        item.description = i.name || i.description;
        // item.itemId = i.name ? i.id : i.item.id;
        item.quantity = i.quantity;
        item.unitPriceCents = i.itemUnits[0]?.price || i.unitPriceCents;

        if (item.itemId === undefined) {
          delete item.itemId;
        }

        return item;
      });

      this.orderForm.get('items').setValue(items);

      let payload: any = {
        id: this.data?.id ?? undefined,
        methods: this.orderForm
          .get('methods')
          .value.filter((method: { label: string; value: string; checked: boolean }) => method.checked)
          .map((method: { label: string; value: string; checked: boolean }) => method.value),
        items: this.orderForm.get('items').value,
        sessionId: moment().format(),
      };

      if (this.orderForm.get('contact')?.value) {
        if (this.orderForm.get('contact')?.value.customerId) {
          payload.customerId = this.orderForm.get('contact')?.value.customerId;
        } else {
          const address = this.orderForm.get('contact')?.value.value?.address?.postalCode
            ? {
                line1: this.orderForm.get('contact')?.value?.address?.line1,
                line2: this.orderForm.get('contact')?.value?.address?.line2 || '0',
                line3: this.orderForm.get('contact')?.value?.address?.line3 || '',
                postalCode: this.orderForm.get('contact')?.value?.address?.postalCode.replace('-', '').replace('.', ''),
                neighborhood: this.orderForm.get('contact')?.value?.address?.neighborhood,
                city: this.orderForm.get('contact')?.value?.address?.city,
                state: this.orderForm.get('contact')?.value?.state,
                countryCode: 'BR',
              }
            : undefined;

          payload.customer = {
            name: this.orderForm.get('contact')?.value?.fullName || this.orderForm.get('contact')?.value?.businessName,
            email: this.orderForm.get('contact')?.value?.email,
            cpf: !this.orderForm.get('contact')?.value?.isLegalEntity
              ? this.orderForm.get('contact')?.value?.cpf
              : undefined,
            cnpj: this.orderForm.get('contact')?.value?.isLegalEntity
              ? this.orderForm.get('contact')?.value?.cnpj
              : undefined,
            phone: this.orderForm.get('contact')?.value?.phone || undefined,
            address,
          };
        }
      }

      payload.sellerId = this.sellerId;

      if (this.orderForm.get('frequency')?.value === 'order') {
        payload = Object.assign(payload, {
          contactId: this.orderForm.get('contact')?.value?.id,
          maxInstallments:
            this.orderForm.get('installments')?.value === 'Pagamento à vista'
              ? 1
              : this.orderForm.get('installments')?.value,
          expirationDate: this.orderForm.get('expirationDate').value
            ? moment(this.orderForm.get('expirationDate')?.value).format('YYYY-MM-DD')
            : '',
          type: 'INVOICE',
          channel: 'IN_STORE',
        });
        delete payload.sessionId;
      } else {
        payload = Object.assign(payload, {
          schedulePlans: this.orderForm?.get('schedule').value,
          startDate: this.orderForm.get('startDate')?.value
            ? moment(this.orderForm.get('startDate')?.value).format('YYYY-MM-DD')
            : undefined,
          endDate: this.orderForm.get('endDate')?.value
            ? moment(this.orderForm.get('endDate')?.value).format('YYYY-MM-DD')
            : undefined,
        });
      }

      delete payload.contactId;

      if (this.orderForm.get('frequency')?.value === 'order') {
        if (!this.data?.id) {
          this.$order.createOrderApollo(payload).subscribe({
            next: (result) => {
              if (result.data.createOrder) {
                const payload = {
                  message: 'Fatura criada com sucesso!',
                  status: true,
                  type: this.alertType.SUCCESS,
                  duration: this.alertDuration.SOON,
                };
                this.$notification.setSucessAlert(payload);

                setTimeout(() => {
                  const payload = {
                    status: false,
                    message: '',
                    type: this.alertType.SUCCESS,
                  };

                  this.$notification.setSucessAlert(payload);
                }, this.alertInfo.duration);

                this.route.navigate(['internal/invoices/list/order']);
              } else {
                this.showError = true;

                setTimeout(() => {
                  this.showError = false;
                }, 2000);
              }

              this.loadingBtn = false;
            },
            error: (error) => {
              this.loadingBtn = false;

              this.showError = true;

              setTimeout(() => {
                this.showError = false;
              }, 2000);
              throw new Error(error);
            },
          });
        } else {
          delete payload.sellerId;

          this.$order.updateOrderApollo(payload).subscribe({
            next: (result) => {
              if (result.data.updateOrder) {
                const payload = {
                  message: 'Fatura atualizada com sucesso!',
                  status: true,
                  type: this.alertType.SUCCESS,
                  duration: this.alertDuration.SOON,
                };
                this.$notification.setSucessAlert(payload);

                setTimeout(() => {
                  const payload = {
                    status: false,
                    message: '',
                    type: this.alertType.SUCCESS,
                  };

                  this.$notification.setSucessAlert(payload);
                }, this.alertInfo.duration);

                this.route.navigate(['internal/invoices/list/order']);
              } else {
                const payload = {
                  message: 'As informações não foram salvas',
                  status: true,
                  type: this.alertType.ERROR,
                  duration: this.alertDuration.SOON,
                };

                this.$notification.setErrorAlert(payload);

                setTimeout(() => {
                  const payload = {
                    status: false,
                    message: '',
                    type: this.alertType.ERROR,
                  };

                  this.$notification.setErrorAlert(payload);
                }, this.alertInfo.duration);
              }

              this.loadingBtn = false;
            },
            error: (error) => {
              this.loadingBtn = false;

              const payload = {
                message: 'As informações não foram salvas',
                status: true,
                type: this.alertType.ERROR,
                duration: this.alertDuration.SOON,
              };

              this.$notification.setErrorAlert(payload);

              setTimeout(() => {
                const payload = {
                  status: false,
                  message: '',
                  type: this.alertType.ERROR,
                };

                this.$notification.setErrorAlert(payload);
              }, this.alertInfo.duration);
              throw new Error(error);
            },
          });
        }
      } else {
        if (this.data?.id) {
          this.$subscription.updateSubscription(payload).subscribe({
            next: (res) => {
              if (res.data.updateSubscription) {
                const payload = {
                  message: 'Fatura atualizada com sucesso!',
                  status: true,
                  type: this.alertType.SUCCESS,
                  duration: this.alertDuration.SOON,
                };
                this.$notification.setSucessAlert(payload);

                setTimeout(() => {
                  const payload = {
                    status: false,
                    message: '',
                    type: this.alertType.SUCCESS,
                  };

                  this.$notification.setSucessAlert(payload);
                }, this.alertInfo.duration);

                this.route.navigate(['internal/invoices/list/subscription']);
              } else {
                this.showError = true;

                setTimeout(() => {
                  this.showError = false;
                }, 2000);
              }

              this.loadingBtn = false;
            },
            error: (error) => {
              const payload = {
                message: 'As informações não foram salvas',
                status: true,
                type: this.alertType.ERROR,
                duration: this.alertDuration.SOON,
              };

              this.$notification.setErrorAlert(payload);

              setTimeout(() => {
                const payload = {
                  status: false,
                  message: '',
                  type: this.alertType.ERROR,
                };

                this.$notification.setErrorAlert(payload);
              }, this.alertInfo.duration);

              throw new Error(error);
            },
          });
        } else {
          this.$subscription.createSubscription(payload).subscribe({
            next: (res) => {
              if (res.data.createSubscription) {
                const payload = {
                  message: 'Fatura criada com sucesso!',
                  status: true,
                  type: this.alertType.SUCCESS,
                  duration: this.alertDuration.SOON,
                };
                this.$notification.setSucessAlert(payload);

                setTimeout(() => {
                  const payload = {
                    status: false,
                    message: '',
                    type: this.alertType.SUCCESS,
                  };

                  this.$notification.setSucessAlert(payload);
                }, this.alertInfo.duration);

                this.route.navigate(['internal/invoices/list/subscription']);
              } else {
                const payload = {
                  message: 'As informações não foram salvas',
                  status: true,
                  type: this.alertType.ERROR,
                  duration: this.alertDuration.SOON,
                };

                this.$notification.setErrorAlert(payload);

                setTimeout(() => {
                  const payload = {
                    status: false,
                    message: '',
                    type: this.alertType.ERROR,
                  };

                  this.$notification.setErrorAlert(payload);
                }, this.alertInfo.duration);
              }

              this.loadingBtn = false;
            },
            error: (error) => {
              this.loadingBtn = false;
              this.showError = true;

              setTimeout(() => {
                this.showError = false;
              }, 2000);
              throw new Error(error);
            },
          });
        }
      }
    } else {
      Object.keys(this.orderForm.controls).forEach((key) => {
        if (this.orderForm.get(key).invalid) {
          this.orderForm.get(key).markAsDirty();
          this.orderForm.get(key).setErrors({ invalid: true });
          this.orderForm.get(key).markAsTouched();
        }
      });

      this.showError = true;

      setTimeout(() => {
        this.showError = false;
      }, 2000);
    }
  }

  public goBack(): void {
    if (this.type === 'order') {
      this.route.navigate(['internal/invoices/list/order']);
    } else {
      this.route.navigate(['internal/invoices/list/subscription']);
    }
  }

  public compareFn(o1: any, o2: any): boolean {
    if (o1 && o2) {
      if (o1.id === o2.id) {
        return true;
      }

      if (o1.description === o2.name) {
        return true;
      }

      if (o1.name === o2.name) {
        return true;
      }

      return false;
    }
    return false;
  }
}
