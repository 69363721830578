import { Component, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-drawer-create-event',
  templateUrl: './drawer-create-event.component.html',
  styleUrls: ['./drawer-create-event.component.scss'],
})
export class DrawerCreateEventComponent implements OnInit {
  public formEvent: FormGroup;

  public storeList = {
    SHOPPING_MORUMBI: 'Shopping Morumbi',
    SHOPPING_ELDORADO: 'Shopping Eldorado',
  };
  public typeCampaignList = {
    BLACK_FRIDAY: 'BLACK FRIDAY',
    CYBER_MONDAY: 'CYBER MONDAY',
  };

  public bannerBase64: string | ArrayBuffer;

  constructor(private readonly fb: FormBuilder, private readonly drawerRef: NzDrawerRef) {}

  public ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.formEvent = this.fb.group({
      title: new FormControl(null, [Validators.required]),
      storeName: new FormControl([], [Validators.required]),
      available: new FormControl([], [Validators.required]),
      description: new FormControl(null, [Validators.required]),
      datetimeBegin: new FormControl(null, [Validators.required]),
      datetimeEnd: new FormControl(null, [Validators.required]),
      rules: new FormControl(null, [Validators.required]),
      bannerUrl: new FormControl(null, [Validators.required]),
      typeCampaign: new FormControl([], [Validators.required]),
      maxQuantityPerCpf: new FormControl(null, [Validators.required]),
      maxUsageLimit: new FormControl(null, [Validators.required]),
    });
  }

  public sendForm(): void {
    this.drawerRef.close(this.formEvent.value);
  }

  public async onChangeBanner(event: any): Promise<void> {
    try {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.formEvent.patchValue({ bannerUrl: file });
        this.bannerBase64 = reader.result;
      };
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
