import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { CartModel } from '../../../models/cart/cart.model';
import { CustomerModel } from '../../../models/customer/customer.model';
import { PointModel } from '../../../models/points/point-of-sales.model';
import { StateManagementService } from '../../../state-management/state-management.service';

@Component({
  selector: 'app-select-type-order',
  templateUrl: './select-type-order.component.html',
  styleUrls: ['./select-type-order.component.scss'],
})
export class SelectTypeOrderComponent implements OnInit {
  public point: PointModel = new PointModel();
  public cart: CartModel = new CartModel();
  public customer: CustomerModel = new CustomerModel();
  public pointId: string = '';
  public cartId: string = '';
  public loading: boolean = false;

  constructor(private $notification: StateManagementService, private readonly router: Router) {}

  public ngOnInit(): void {
    this.getNotifications();
  }

  public getNotifications(): void {
    this.$notification.carts.subscribe((cart) => {
      if (cart) {
        this.cart = cart;
      }
    });
    this.$notification.loadings.subscribe((loading) => {
      this.loading = loading;
    });
    this.$notification.points.subscribe((point) => {
      if (point) {
        this.point = point;
        this.cartId = point.cartId;
      }
    });
    this.$notification.customers.subscribe((customer) => {
      if (customer) {
        this.customer = customer;
      }
    });
    this.$notification.routeParams.subscribe((ids) => {
      if (ids) {
        this.pointId = ids.pointId;
      }
    });
  }

  public goToAddress(): void {
    this.router.navigate(['/loggedCart/' + this.pointId + '/address']);
  }
}
