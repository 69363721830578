import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, Mutation, Query } from 'apollo-angular';

import { PaymentLinkModel } from '../../models/payment-link/payment-link.model';
import { GET_PAYMENT_LINK_QUERY, LIST_PAYMENT_LINK_QUERY } from '../../graphql/queries/payment-link.queries';
import { CREATE_PAYMENT_LINK_MUTATION, DELETE_PAYMENT_LINK_MUTATION, UPDATE_PAYMENT_LINK_MUTATION } from '../../graphql/mutations/payment.link.mutations';

@Injectable()
export class PaymentLinksService {
  constructor(private readonly apollo: Apollo) {}

  public getPaymentLinkList(filters: any): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_PAYMENT_LINK_QUERY,
      variables: { filters },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public getPaymentLinkDetail(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_PAYMENT_LINK_QUERY,
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }).valueChanges;
  }

  public createPaymentLink(params: Partial<PaymentLinkModel>): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_PAYMENT_LINK_MUTATION,
      variables: { params },
    });
  }

  public updatePaymentLink(id: string, params: Partial<PaymentLinkModel>): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_PAYMENT_LINK_MUTATION,
      variables: { id, params },
    });
  }

  public deletePaymentLink(id: string): Observable<any> {
    return this.apollo.mutate<Mutation>({
      mutation: DELETE_PAYMENT_LINK_MUTATION,
      variables: { id },
    });
  }
}
