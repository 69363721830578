import { gql } from "apollo-angular";

const CREATE_CAMPAIGNS_MUTATION = gql`
  mutation CreateCampaign(
    $sellerId: String!,
    $startDate: DateTime!,
    $sendDate: DateTime!,
    $hasSms: Boolean,
    $hasEmail: Boolean,
    $segmentationIds: [String!],
    $smsBodyMessage: String,
    $emailBodyMessage: String,
    $emailTitle: String,
    $emailSubject: String,
    $link: String,
    $templateId: String,
    $actionName: String) {
      createCampaign(
        sellerId: $sellerId
        startDate: $startDate
        sendDate: $sendDate
        hasSms: $hasSms
        hasEmail: $hasEmail
        segmentationIds: $segmentationIds
        smsBodyMessage: $smsBodyMessage
        emailBodyMessage: $emailBodyMessage
        emailTitle: $emailTitle
        emailSubject: $emailSubject
        link: $link
        templateId: $templateId
        actionName: $actionName
      ) {
        id
        created_at
        updated_at
        seller_id
        has_email
        has_sms
        send_date
        send_messages
        start_date
        end_date
        status
      }
    }
`;

const UPDATE_CAMPAIGNS_MUTATION = gql`
  mutation UpdateCampaign(
    $id: String!,
    $sellerId: String!,
    $startDate: DateTime!,
    $sendDate: DateTime!,
    $hasSms: Boolean,
    $hasEmail: Boolean,
    $segmentationIds: [String!],
    $smsBodyMessage: String,
    $emailBodyMessage: String,
    $emailTitle: String,
    $emailSubject: String,
    $link: String,
    $templateId: String,
    $actionName: String) {
      updateCampaign(
        id: $id,
        sellerId: $sellerId
        startDate: $startDate
        sendDate: $sendDate
        hasSms: $hasSms
        hasEmail: $hasEmail
        segmentationIds: $segmentationIds
        smsBodyMessage: $smsBodyMessage
        emailBodyMessage: $emailBodyMessage
        emailTitle: $emailTitle
        emailSubject: $emailSubject
        link: $link
        templateId: $templateId
        actionName: $actionName
      ) {
        id
        created_at
        updated_at
        seller_id
        has_email
        has_sms
        send_date
        send_messages
        start_date
        end_date
        status
      }
    }
`;

export { CREATE_CAMPAIGNS_MUTATION, UPDATE_CAMPAIGNS_MUTATION };
