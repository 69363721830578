import { RoleEnum } from '../../enums/roles';
import { UserSellersModel } from './user-sellers.model';
import { AddressModel } from './../address/address.model';
import { SellerModel } from '../sellers/sellers.model';

export class UserModel {
  public uid?: string;
  public name: string;
  public cpf: string;
  public email: string;
  public phone: string;
  public password?: string;
  public address?: AddressModel;
  public insertedAt?: string;
  public active?: boolean;
  public externalId?: string;
  public id?: string;
  public role?: RoleEnum;
  public updatedAt?: Date;
  public getUser?: UserModel;
  public listUsers?: UserModel[] = [];
  public userSellers?: Array<UserSellersModel>;
  public activeSeller?: SellerModel;
}
