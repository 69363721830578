import { Component, Input, OnInit } from '@angular/core';
import { ItemsV2Model } from 'libs/shared/src/lib/models/items/V2/ItemV2.model';

import { ItemModel } from 'libs/shared/src/lib/models/items/item.model';
import { SubscriptionsService } from 'libs/shared/src/lib/services/subscriptions/subscriptions.service';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss'],
})
export class ItemDetailsComponent implements OnInit {
  @Input() param: { sellerId: string; marketplaceId: string; item: ItemsV2Model };

  public data: ItemsV2Model = new ItemsV2Model();
  public capturedItems: ItemModel[] = [];

  constructor(private $subscription: SubscriptionsService, public $drawerRef: NzDrawerRef) {}

  public ngOnInit(): void {
    this.setData();
  }

  public setData(): void {
    if (this.param) {
      this.data = this.param.item;

      this.$subscription
        .getActiveSubscriptions(this.param.marketplaceId, this.param.sellerId)
        ?.subscribe((subscriptions) => {
          const data: ItemModel[] = [];

          subscriptions.forEach((subscription) => {
            subscription.items.forEach((item) => {
              if (item.itemId === this.param.item.id) {
                data.push(item);
              }
            });
          });

          this.capturedItems = data;
        });
    }
  }

  public getVRM(): number {
    return this.capturedItems?.reduce(
      (acc: number, item: ItemModel) => (acc += item.unitPriceCents * item.quantity),
      0
    );
  }
}
