import { mergeMap, of } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DashboardModel } from 'libs/shared/src/lib/models/onboarding/dashboard.model';
import { SellersService } from 'libs/shared/src/lib/services/sellers/sellers.service';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { shortenName } from 'libs/shared/src/lib/utils/utils';
import { EmailValidator } from 'libs/shared/src/lib/validators/emailValidator';
import { OnboardingService } from 'libs/shared/src/lib/services/onboarding/onboarding.service';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
})
export class SendEmailComponent implements OnInit {
  public formGroup!: FormGroup;

  private collection!: DashboardModel;

  constructor(
    private $seller: SellersService,
    private readonly fb: FormBuilder,
    private $onboarding: OnboardingService,
    private readonly $message: NzMessageService,
    private $notification: StateManagementService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getDashboardActivationCollection();

    this.formGroup.valueChanges.subscribe((data) => {
      this.$notification.setOnboardingBtnDisabled(this.formGroup.invalid);
    });
  }

  public saveData(): void {
    if (this.formGroup.valid) {
      this.$notification.setOnboardingBtnLoading(true);

      const name = this.collection.personalData?.data?.name || '';
      const email = this.formGroup.get('email').value.trim();

      this.$seller
        .sellerWithEmailExists(email)
        .pipe(
          mergeMap((res) => {
            if (res && res.status === 404) {
              return this.$onboarding.sendCodeValidationEmail({
                email,
                type: 'B2B_ONBOARDING_EMAIL',
                username: shortenName(name),
              });
            }

            this.$message.error('O email já está sendo utilizado.');
            return of(undefined);
          })
        )
        .subscribe((res) => {
          if (res) {
            if (res.status === 200) {
              this.$message.success('Email enviado com sucesso.');
              const data: DashboardModel = Object.assign({}, this.collection, {
                emailValidation: { data: { email } },
              } as DashboardModel);
              this.$notification.setDashboardActivationCollection(data);
            } else {
              this.$message.error(res.error);
            }
          }

          this.$notification.setOnboardingBtnLoading(false);
        });
    }
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      email: new FormControl('', [Validators.required, EmailValidator.isValid()]),
    });
  }

  private getDashboardActivationCollection(): void {
    this.$notification.dashboardActivationsCollection.subscribe((collection) => {
      if (collection) {
        this.collection = collection;
        this.formGroup.get('email').setValue(collection.emailValidation?.data?.email || '');
      }

      this.$notification.setOnboardingBtnDisabled(this.formGroup.invalid);
    });
  }
}
