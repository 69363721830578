import * as moment from 'moment';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import {
  Query,
  AngularFirestore,
  CollectionReference,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Apollo, Mutation, MutationResult, Subscription } from 'apollo-angular';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import {
  GET_PAYMENT_QUERY,
  LIST_PAYMENTS_QUERY,
  GET_SELLER_PAYMENT_QUERY,
  LIST_SELLER_PAYMENTS_QUERY,
  GET_PAYMENT_CHECKOUT_QUERY,
} from '../../graphql/queries/payment.queries';
import { environment } from '@gen/environments';
import {
  CANCEL_PAYMENT_MUTATION,
  CREATE_CUSTOMER_PAYMENT_MUTATION,
  PROCESS_CHARGEBACK_FILE_MUTATION,
  CREATE_CHECKOUT_PAYMENTS_MUTATION,
} from '../../graphql/mutations/payment.mutations';
import { OrdersService } from '../orders/orders.service';
import { SellerModel } from './../../models/sellers/sellers.model';
import { ResponseModel } from '../../models/response/response.model';
import { PaymentsModel } from '../../models/payments/payments.model';
import { PointModel } from './../../models/points/point-of-sales.model';
import { PointOfSalesService } from '../point-of-sales/point-of-sales.service';
import { ResponseFilterModel } from '../../models/filters/response-filter.model';
import { FilterPaymentsParamsModel } from '../../models/payment/filter-payments-params.model';
import { PAYMENT_EVENT_SUBSCRIPTION } from '../../graphql/subscriptions/payment.subscriptions';
import { ItemsService } from '../items/items.service';

@Injectable()
export class PaymentsService {
  public marketplaceId: string = environment.marketplaceId;

  constructor(
    private readonly router: Router,
    private readonly apollo: Apollo,
    private $message: NzMessageService,
    private readonly injector: Injector,
    private $point: PointOfSalesService,
    private fireStore: AngularFirestore,
    private $functions: AngularFireFunctions
  ) {}

  public createPayment(data: any): Observable<ResponseModel<PaymentsModel>> {
    return this.$functions.httpsCallable('createPayment')(data);
  }

  public cancelPayment(data: { id: string; cancelReason: string }): Observable<ResponseModel<PaymentsModel>> {
    return this.$functions.httpsCallable('cancelPayment')(data);
  }

  public confirmPayment(data: { id: string }): Observable<ResponseModel<PaymentsModel>> {
    return this.$functions.httpsCallable('confirmPayment')(data);
  }

  public batchCreatePayments(data: Partial<PaymentsModel>[]): Observable<ResponseModel<PaymentsModel[]>> {
    return this.$functions.httpsCallable('batchCreatePayments')(data);
  }

  public batchConfirmPayments(data: PaymentsModel[]): Observable<ResponseModel<PaymentsModel[]>> {
    return this.$functions.httpsCallable('batchConfirmPayments')(data);
  }

  public getPayments(sellerId: string, filters?: FilterPaymentsParamsModel): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_SELLER_PAYMENTS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { sellerId, filters },
    }).valueChanges;
  }

  public getPaymentListWithCustomer(customerId: string, sellerId: string): Observable<PaymentsModel[]> {
    return this.fireStore
      .collection(`marketplaces/${this.marketplaceId}/sellers/${sellerId}/payments`, (ref) =>
        ref.where('customerId', '==', customerId).orderBy('insertedAt', 'desc')
      )
      .valueChanges() as Observable<PaymentsModel[]>;
  }

  public getPaymentDetail(sellerId: string, id: string): Observable<PaymentsModel | undefined> | undefined {
    return this._collection(this.marketplaceId, sellerId).doc(id).valueChanges();
  }

  public getPaymentsWithFilters(sellerId: string, filter: ResponseFilterModel): Observable<PaymentsModel[]> {
    return this._collection(this.marketplaceId, sellerId, filter).valueChanges();
  }

  private _collection(
    marketplaceId: string,
    sellerId: string,
    filter?: ResponseFilterModel
  ): AngularFirestoreCollection<PaymentsModel> {
    if (filter && !isEmpty(filter)) {
      return this.fireStore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/payments`, (ref) => {
        let query: CollectionReference | Query = ref;

        if (filter.creationDateFilter) {
          if (filter.creationDateFilter.date) {
            const startDate = moment(filter.creationDateFilter.date).set({ hour: 0, minute: 0, second: 0 }).format();
            const endDate = moment(filter.creationDateFilter.date).set({ hour: 23, minute: 59, second: 59 }).format();

            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else if (filter.creationDateFilter.startDate) {
            const startDate = moment(filter.creationDateFilter.startDate)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();
            const endDate = moment(filter.creationDateFilter.endDate)
              .set({ hour: 23, minute: 59, second: 59 })
              .format();
            query = query.where('insertedAt', '>=', startDate).where('insertedAt', '<=', endDate);
          } else {
            const date = moment()
              .subtract(filter.creationDateFilter.dateNumber, filter.creationDateFilter.datePeriod)
              .set({ hour: 0, minute: 0, second: 0 })
              .format();

            query = query.where('insertedAt', '>=', date);
          }
        }

        if (filter.valuesFilter && !filter.creationDateFilter) {
          if (filter.valuesFilter.value) {
            switch (filter.valuesFilter.type) {
              case 0:
                query = query.where('totalCents', '==', filter.valuesFilter.value);
                break;
              case 2:
                query = query.where('totalCents', '>', filter.valuesFilter.value).orderBy('totalCents', 'asc');
                break;
              case 3:
                query = query.where('totalCents', '<', filter.valuesFilter.value).orderBy('totalCents', 'desc');
                break;
              default:
                break;
            }
          } else {
            query = query
              .where('totalCents', '>=', filter.valuesFilter.lowerValue)
              .where('totalCents', '<=', filter.valuesFilter.highestValue)
              .orderBy('totalCents', 'asc');
          }
        }

        if (filter.statusFilter) {
          query = query.where('status', 'in', filter.statusFilter);
        }

        if (filter.paymentMethodFilter && !filter.statusFilter) {
          query = query.where('method', 'in', filter.paymentMethodFilter);
        }

        return query.orderBy('insertedAt', 'desc');
      });
    }

    return this.fireStore.collection(`marketplaces/${marketplaceId}/sellers/${sellerId}/payments`, (ref) =>
      ref.orderBy('insertedAt', 'desc')
    );
  }

  public async setSucessPaymentFlow(
    sellerId: string,
    pointId: string,
    point: PointModel,
    payment: PaymentsModel,
    seller: SellerModel
  ): Promise<void> {
    const $order = this.injector.get<OrdersService>(OrdersService);
    const $item = this.injector.get<ItemsService>(ItemsService);

    this.$point.updatePoint(pointId, { paymentId: payment.id, paymentAt: payment.confirmedAt });
    $order.updateOrder({ id: point.orderId, deliveryStatus: 'CREATED' }).subscribe((response) => {
      if (response.status !== 200) {
        this.$message.error('Erro ao atualizar a order.');
      }
    });

    const url = environment.appDomain;
    const orderLinkRoute = 'external/' + sellerId + '/' + pointId + '/order-link?orderId=' + point.orderId;

    const payload = {
      phone: point.customerPhone,
      link: `${url}/${orderLinkRoute}`,
      companyName: seller?.settings?.pointOfSale?.fantasyName || seller?.nickname,
    };

    $order.sendLinkSms(payload).subscribe((res) => {
      if (res.status !== 200) {
        this.$message.error('Erro ao mandar o link de acompanhamento.');
      }
    });

    this.router.navigate(['/internal/' + sellerId + '/' + pointId + '/order-status']);
  }

  public getPaymentList(filter: FilterPaymentsParamsModel): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: LIST_PAYMENTS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { filter },
    }).valueChanges;
  }

  public getPaymentById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_PAYMENT_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { id },
    }).valueChanges;
  }

  public getPaymentByIdCheckout(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_PAYMENT_CHECKOUT_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { id },
    }).valueChanges;
  }

  public getSellerPaymentById(id: string): Observable<any> {
    return this.apollo.watchQuery<Query>({
      query: GET_SELLER_PAYMENT_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: { id },
    }).valueChanges;
  }

  public processChargebackFile(binaryFile: any): Observable<any> {
    return this.apollo.mutate({
      mutation: PROCESS_CHARGEBACK_FILE_MUTATION,
      variables: {
        binaryFile,
      },
    });
  }

  public cancelPaymentApollo(id: string): Observable<MutationResult> {
    return this.apollo.mutate<Mutation>({
      mutation: CANCEL_PAYMENT_MUTATION,
      variables: { id },
    });
  }

  public createCustomerPayment(data: any): Observable<MutationResult> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_CUSTOMER_PAYMENT_MUTATION,
      variables: { params: data },
    });
  }

  public createCheckoutPayments(data: any): Observable<MutationResult> {
    return this.apollo.mutate<Mutation>({
      mutation: CREATE_CHECKOUT_PAYMENTS_MUTATION,
      variables: { params: data },
    });
  }

  public paymentEventSubscription(id: string): Observable<any> {
    return this.apollo.use('1').subscribe<Subscription>({
      query: PAYMENT_EVENT_SUBSCRIPTION,
      variables: { id },
    });
  }
}
