import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Apollo, Mutation, MutationResult } from 'apollo-angular';

import { environment } from '@gen/environments';
import { getBigModalWidth } from '../../utils/utils';
import { DocumentModel } from '../../models/document/document.model';
import { RequestFilterModel } from '../../models/filters/request-filter.model';
import { UPDATE_DOCUMENT_STATUS } from '../../graphql/mutations/document.mutations';
import { ResponseFilterModalModel } from '../../models/filters/response-filter-modal.model';
import { ModalFilterComponent } from '../../components/modals/modal-filter/modal-filter.component';
import { LimitsActionEnum, LimitsCategoryEnum, LimitsFunctionalityEnum, LimitsPeriodEnum } from '../../enums/limits';
import { ModalRejectMessageComponent } from '../../components/modals/modal-reject-message/modal-reject-message.component';
import { ModalFilterSynapsesComponent } from '../../components/modals/modal-filter-synapses/modal-filter-synapses.component';

@Injectable()
export class SharedMethodsService {
  constructor(
    private readonly apollo: Apollo,
    private readonly $modal: NzModalService,
    private readonly $message: NzMessageService
  ) {}

  public getLink(id: string, type: string): string {
    const url = environment.appDomain;

    const links = {
      ORDER: `${url}/external/order/${id}`,
      PLAN: `${url}/external/plan/${id}`,
      SUBSCRIPTION: `${url}/external/subscription/${id}`,
      ITEM: `${url}/external/item/${id}`,
      PAYMENTLINK: `${url}/external/payment-link/${id}`,
      SELLER: `${url}/external/seller/${id}`,
    }

    return links[type];
  }

  public showFilterModal(params: RequestFilterModel, isSynapses?: boolean): Subject<any> {
    const content: any = isSynapses ? ModalFilterSynapsesComponent : ModalFilterComponent;

    return this.$modal.create({
      nzContent: content,
      nzComponentParams: params,
      nzTitle: isSynapses ? 'Filtro': null,
      nzStyle: { padding: 0 },
      nzFooter: null,
      nzClosable: false,
      nzWidth: getBigModalWidth(),
    }).afterClose;
  }

  public sortDataByName(data: Array<any>): Array<any> {
    return data.sort((x, y) => {
      const a = x.name?.toUpperCase();
      const b = y.name?.toUpperCase();

      return a === b ? 0 : a > b ? 1 : -1;
    });
  }

  public isFilterFormEmpty(filterForm: ResponseFilterModalModel): boolean {
    return (
      !filterForm.creationDate &&
      !filterForm.document &&
      !filterForm.email &&
      !filterForm.name &&
      !filterForm.storeName &&
      !filterForm.title &&
      !filterForm.sellerId &&
      !filterForm.customerId &&
      !filterForm.method &&
      !filterForm.installments &&
      !filterForm.id &&
      !filterForm.status
    );
  }

  public convertToNaiveDateTime(date: Date): string {
    if (date) {
      return date.toISOString().split('T')[0] + 'T' + date.toLocaleTimeString();
    }

    return '';
  }

  public getDataArrayFromIndexArray(idxArr: number[], arr: string[]): string[] {
    let result: string[] = [];
    idxArr?.forEach((idx: number) => {
      result.push(arr[idx]);
    });
    return result;
  }

  public getDataArrayFromEnum(idxArr: string[], arr: Object): string[] {
    let result: string[] = [];
    idxArr?.forEach((idx) => {
      result.push(arr[idx]);
    });
    return result;
  }

  public translateLimits(limit: any): any {
    if (limit.action?.name) {
      limit.action.name = LimitsActionEnum[limit.action.name];
    }

    if (limit.functionality?.name) {
      limit.functionality.name = LimitsFunctionalityEnum[limit.functionality.name];
    }

    if (limit.perPeriod?.name) {
      limit.perPeriod.name = LimitsPeriodEnum[limit.perPeriod.name];
    }

    if (limit.category?.name) {
      limit.category.name = LimitsCategoryEnum[limit.category.name];
    }

    return limit;
  }

  private updateDocument(id: string, reason: string, status: string): Observable<MutationResult> {
    return this.apollo.mutate<Mutation>({
      mutation: UPDATE_DOCUMENT_STATUS,
      variables: {
        id,
        reason,
        status,
      },
    });
  }

  public approve(document: DocumentModel): void {
    this.updateDocument(document.id, '', 'approved').subscribe({
      next: (res) => {
        if (res) {
          this.$message.success('Documento aprovado com sucesso.');
        }
      },
      error: (error) => {
        this.$message.error('Falha ao aprovar documento');
        throw new Error(error);
      },
    });
  }

  public reject(document: DocumentModel): void {
    this.$modal
      .create({
        nzContent: ModalRejectMessageComponent,
        nzTitle: 'Razão para rejeitar documento',
      })
      .afterClose.subscribe((res: any) => {
        if (res?.reason) {
          this.updateDocument(document.id, res.reason, 'rejected').subscribe({
            next: (result) => {
              if (result) {
                this.$message.success('Documento rejeitado com sucesso.');
              }
            },
            error: (error) => {
              this.$message.error('Falha ao rejeitar documento.');
              throw new Error(error);
            },
          });
        }
      });
  }

  public getDocumentUrl(document: DocumentModel): string {
    return `https://firebasestorage.googleapis.com/v0/b/${document?.bucket}/o/${encodeURIComponent(
      document?.path
    )}?alt=media&key=aGwsPOaYbsR63268ZMga3uUkHJ08tCKtFJVFV65IKOIAJQJRR5s9uAPQUULQ`;
  }

  public formatPhone(phone: string): string {
    if (phone?.startsWith('+55')) {
      return phone.substring(3);
    }
    return phone || null;
  }
}
