import { gql } from 'apollo-angular';

const LIST_USERS_QUERY = gql`
  query ListUsers($email: String, $name: String, $insertedAt: PeriodFilterParams) {
    listUsers(
      pagination: { sortingOrder: DESC, orderBy: "inserted_at", perPage: 100 }
      filters: {
        email: $email
        name: $name
        insertedAt: $insertedAt
        role: [ADMIN, COMPLIANCE, CUSTOMER_EXPERIENCE, FINANCE, MARKETING]
      }
    ) {
      id
      cpf
      role
      name
      email
      phone
      active
      externalId
      insertedAt
    }
  }
`;

const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      id
      cpf
      name
      role
      email
      phone
      externalId
      userSellers {
        seller {
          id
          cnpj
          cpf
          phone
          occupation
          store {
            displayName
          }
          person {
            name
          }
          company {
            businessName
          }
        }
      }
    }
  }
`;

const GET_USER = gql`
  query GetUser($id: UUID4!) {
    getUser(id: $id) {
      id
      cpf
      role
      name
      email
      phone
      active
      externalId
      insertedAt
    }
  }
`;

const LIST_SUBSELLERS_QUERY = gql`
  query ListSubsellers($sellerId: UUID4!, $filters: FilterSubsellerParams) {
    listSubsellers(
      sellerId: $sellerId
      filters: $filters
      pagination: { sortingOrder: DESC, orderBy: "inserted_at", perPage: 100 }
    ) {
      insertedAt
      user {
        id
        name
        cpf
      }
    }
  }
`;

const GET_SUBSELLER_QUERY = gql`
  query GetSubseller($sellerId: UUID4!, $userId: UUID4!) {
    getSubseller(sellerId: $sellerId, userId: $userId) {
      id
      user {
        name
        cpf
        email
        phone
      }
    }
  }
`;

export { LIST_USERS_QUERY, CURRENT_USER_QUERY, GET_USER, LIST_SUBSELLERS_QUERY, GET_SUBSELLER_QUERY };
