import { RouteParamsModel } from './../../models/route-params/route-params.model';
import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { PointModel } from '../../models/points/point-of-sales.model';
import { PointOfSalesService } from '../../services/point-of-sales/point-of-sales.service';
import { StateManagementService } from '../../state-management/state-management.service';

@Component({
  selector: 'app-modal-success',
  templateUrl: './modal-success.component.html',
  styleUrls: ['./modal-success.component.scss'],
})
export class ModalSuccessComponent implements OnInit {
  public point: PointModel;

  constructor(
    public $modalRef: NzModalRef,
    public $point: PointOfSalesService,
    public $notification: StateManagementService
  ) {}

  ngOnInit(): void {
    this.getNotification();
  }

  public getNotification(): void {
    this.$notification.routeParams.subscribe((ids) => {
      if (ids) {
        this.getPoint(ids);
      }
    });
  }

  public getPoint(ids: RouteParamsModel): void {
    this.$point.getPoint(ids.pointId).subscribe((point) => {
      if (point) {
        this.point = point;
      }
    });
  }
}
