import { gql } from 'apollo-angular';

const LIST_ORDERS_QUERY = gql`
  query ListOrders($filter: FilterOrdersParams) {
    listOrders(filters: $filter, pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }) {
      id
      status
      type
      customer {
        name
        cpf
        cnpj
      }
      items {
        description
      }
      insertedAt
      customerData
      totalCents
    }
  }
`;

const GET_ORDER_QUERY = gql`
  query getOrder($id: UUID4!) {
    getOrder(id: $id) {
      id
      status
      type
      customer {
        id
        name
        cpf
        cnpj
      }
      customerData
      seller {
        id
        email
        store {
          displayName
        }
        company {
          businessName
        }
        person {
          name
        }
      }
      items {
        id
        unitPriceCents
        description
        quantity
        item {
          id
          images {
            imageUrl
          }
        }
      }
      method
      expirationDate
      insertedAt
      updatedAt
      totalCents
      maxInstallments
      methods
      subtotalCents
      shippingCents
      discountCents
      additionCents
      feeAmountCents
      deliveryMethod
      deliveryStatus
      items {
        description
        quantity
        unitPriceCents
      }
      payments {
        id
        method
        confirmedAt
        totalCents
        subtotalCents
        status
        insertedAt
        updatedAt
        credit {
          installments
          publicCreditCard {
            brand
            last4
            expirationMonth
            expirationYear
          }
        }
        bnplCheckout {
          id
          numberOfInstallments
          downPaymentValue
          returnUrl
          status
          bnplTransaction {
            installments {
              paymentStatus
            }
          }
        }
        pix {
          brcode
        }
        bankSlip {
          bank
          barcode
          printLinkPdf
        }
      }
    }
  }
`;

const GET_PUBLIC_ORDER_QUERY = gql`
  query getPublicOrder($id: UUID4!) {
    getPublicOrder(id: $id) {
      id
      status
      type
      publicSeller {
        id
        cpf
        cnpj
        displayName
        company {
          businessName
        }
        person {
          name
        }
      }
      publicCustomer {
        id
        name
        cpf
        phone
        email
        address {
          postalCode
          line1
          line2
          line3
          neighborhood
          state
          city
        }
      }
      items {
        id
        unitPriceCents
        description
        quantity
        item {
          images {
            imageUrl
          }
        }
      }
      method
      expirationDate
      insertedAt
      updatedAt
      totalCents
      maxInstallments
      methods
      subtotalCents
      shippingCents
      discountCents
      additionCents
      payments {
        id
        method
        confirmedAt
        totalCents
        status
        insertedAt
        updatedAt
        credit {
          installments
          publicCreditCard {
            brand
            last4
            expirationMonth
            expirationYear
          }
        }
        pix {
          brcode
          expiration
        }
        bankSlip {
          bank
          barcode
          expirationDate
          printLinkPdf
          typefulLine
        }
      }
    }
  }
`;

const LIST_SELLER_ORDERS = gql`
  query ListSellerOrders($sellerId: UUID4!, $filter: FilterOrdersParams) {
    listSellerOrders(
      sellerId: $sellerId
      filters: $filter
      pagination: { orderBy: "inserted_at", sortingOrder: DESC, perPage: 100 }
    ) {
      id
      status
      type
      customer {
        name
        cpf
        cnpj
      }
      insertedAt
      status
      deliveryMethod
      deliveryStatus
      customerData
      totalCents
    }
  }
`;

export { LIST_ORDERS_QUERY, GET_ORDER_QUERY, LIST_SELLER_ORDERS, GET_PUBLIC_ORDER_QUERY };
