import { NzModalRef } from 'ng-zorro-antd/modal';
import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

import { StoreService } from '../../../services/store/store.service';
import { ErrorService } from '../../../services/error/error.service';

@Component({
  selector: 'app-modal-whatsapp-validation',
  templateUrl: './modal-whatsapp-validation.component.html',
  styleUrls: ['./modal-whatsapp-validation.component.scss'],
})
export class ModalWhatsappValidationComponent implements OnInit {
  @Input() public sellerId: string;
  @Input() public whatsappNumber: string;

  public token: string;
  public sendingCode: boolean = false;
  public countDown: number = 60;
  public invalidCode: boolean = false;

  constructor(
    private $store: StoreService,
    private $error: ErrorService,
    public readonly modalRef: NzModalRef,
    private readonly message: NzMessageService
  ) {}

  public ngOnInit(): void {
    this.updateWhatsappNumber();
  }

  public updateWhatsappNumber(): void {
    this.$store
      .updateStoreWhatsappNumber({ whatsappNumber: this.whatsappNumber }, this.sellerId)
      .subscribe({
        next: () => {
          this.message.success('Código enviado.');
          this.setSendingCode();
        },
        error: (error: any) => {
          this.$error.errorHandling(error, 'Não foi possível enviar o código. Por favor, tente novamente mais tarde.', 'ANT');
        },
      });
  }

  public updateWhatsappNumberConfirmation(): void {
    this.$store.updateStoreWhatsappNumberConfirmation({ token: this.token }, this.sellerId).subscribe({
      next: () => {
        this.message.success('Código validado.');
        this.modalRef.close('valid');
      },
      error: (error: any) => {
        this.invalidCode = true;
        this.$error.errorHandling(error, 'Código inválido. Tente novamente.', 'ANT');
      },
    });
  }

  public getFullCode(event: string): void {
    if (event) {
      this.token = event;
    }
  }

  public setSendingCode(): void {
    this.sendingCode = true;
    this.countDown = 60;
    const timeout = setTimeout(() => {
      this.sendingCode = false;
    }, 60000);
    const interval = setInterval(() => {
      this.countDown -= 1;

      if (this.countDown === 0) {
        clearInterval(interval);
        clearTimeout(timeout);
      }
    }, 1000);
  }
}
