import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BasketModel } from '../../models/cart/basket.model';
import { PointModel } from '../../models/points/point-of-sales.model';
import { StateManagementService } from '../../state-management/state-management.service';
import { CustomerModel } from '../../models/customer/customer.model';

@Component({
  selector: 'app-checkout-select-installments',
  templateUrl: './checkout-select-installments.component.html',
  styleUrls: ['./checkout-select-installments.component.scss'],
})
export class CheckoutSelectInstallmentsComponent {
  public point: PointModel = new PointModel();
  public maxInstallments: number[] = Array(12).fill(0);
  public activeBasket: BasketModel = new BasketModel();
  public validateBaskets: boolean = true;
  public basketMap: object = {};
  public customer: CustomerModel;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private $notification: StateManagementService
  ) {
    this.getPoint();
    this.getBasketMap();
    this.getCustomer();
  }

  public getPoint(): void {
    this.$notification.points.subscribe((res) => {
      if (res) {
        this.point = res;
      }
    });
  }

  public setActiveBasket(basket: BasketModel): void {
    this.activeBasket = basket;
  }

  public setInstallment(installment: number): void {
    this.point.cart.baskets.forEach((basket) => {
      if (basket.id === this.activeBasket.id) {
        basket.installments = installment;
        this.basketMap[basket.id].state = 'set';
        this.activeBasket = new BasketModel();
      }
    });
    this.updatePoint();
    this.validateBaskets = !this.point.cart.baskets.every((basket) => !!basket.installments);
  }

  public setInstallmentWeb(id: string, installment: number): void {
    this.point.cart.baskets.forEach((basket) => {
      if (basket.id === id) {
        basket.installments = installment;
      }
      this.basketMap[id].state = 'set';
    });
    this.updatePoint();
    this.validateBaskets = !this.point.cart.baskets.every((basket) => !!basket.installments);
  }

  public goToCheckout(): void {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public getBasketMap(): void {
    this.point.cart.baskets.map((basket) => {
      if (basket.store.storePreferences.allowInstallment) {
        let installments = [];
        for (let i = 0; i < basket.store.storePreferences.defaultMaxInstallments; i++) {
          installments.push(i + 1);
        }
        if (basket.installments) {
          this.basketMap[basket.id] = {
            state: 'set',
            installments,
          };
        } else {
          this.basketMap[basket.id] = {
            state: 'initial',
            installments,
          };
        }
      }
    });
    this.validateBaskets = !this.point.cart.baskets.every((basket) => !!basket.installments);
  }

  public setBasketState(id: string, state: string): void {
    this.basketMap[id].state = state;
  }

  public updatePoint(): void {
    this.$notification.setPoint(this.point);
  }

  private getCustomer(): void {
    this.$notification.customers.subscribe((customer) => {
      if (customer && Object.keys(customer).length > 0) {
        this.customer = customer;
      }
    });
  }
}
